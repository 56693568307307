import { useState } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import _ from "lodash";

//i18n file
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

//utils
import { ACTIONSTATE, COLOR } from "../../../../utils/enums";

//component
import QoeListComponent from "../../../QoeListComponent";

//style
import css from "../../../commonMagneticStyle.less";

const [HbrViewSwitcher, HbrRadioButton] = reactWrapper([
  "hbr-view-switcher",
  "hbr-radio-button"
]);

const PathRecommendationDetails = props => {
  const { countActionForAllSitesObj } = props;
  const [actionState, setActionState] = useState(ACTIONSTATE.READY);
  const actionStateList = !_.isEmpty(countActionForAllSitesObj)
    ? [
        { count: countActionForAllSitesObj.sumarryActionState.ready },
        { count: countActionForAllSitesObj.sumarryActionState.notReady }
      ]
    : [{ count: 0 }, { count: 0 }];

  const handleDisplaySitesByState = evt => {
    setActionState(evt.currentTarget.value);
    props.handleDisplaySitesByState(evt.currentTarget.value);
  };

  return (
    <div className={css["path-summary"]}>
      <div className="path-summary-action-states-btn">
        <HbrViewSwitcher
          value={actionState}
          name="qoeFilter"
          onHbr-change={handleDisplaySitesByState}
        >
          <HbrRadioButton value={ACTIONSTATE.READY}>
            {i18nMessageBundle.allSitesView.ready}
          </HbrRadioButton>
          <HbrRadioButton value={ACTIONSTATE.ALL}>
            {i18nMessageBundle.allSitesView.all}
          </HbrRadioButton>
        </HbrViewSwitcher>
      </div>
      <div className="path-summary-actions">
        <QoeListComponent
          iconType={[COLOR.GOOD, COLOR.GRAY]}
          qoeScore={actionStateList}
          qoeLabels={[
            i18nMessageBundle.allSitesView.ready,
            i18nMessageBundle.allSitesView.norecommend
          ]}
        />
      </div>
    </div>
  );
};

PathRecommendationDetails.propTypes = {
  handleDisplaySitesByState: PropTypes.func,
  countActionForAllSitesObj: PropTypes.object
};
export default PathRecommendationDetails;
