import React from "react";
import { PropTypes } from "prop-types";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import css from "../commonMagneticStyle.less";

/**
 * Chart Legend renders list of items along with color
 * Usage:
 *
 */

const qualityData = [
  {
    label: i18nMessageBundle.appDashboardQOEGoodLabel,
    labelValue: i18nMessageBundle.appDashboardQOEGoodValue,
    color: "var(--hbr-color-success-border,#45991f)"
  },
  {
    label: i18nMessageBundle.appDashboardQOEFairLabel,
    labelValue: i18nMessageBundle.appDashboardQOEFairValue,
    color: "var(--hbr-color-yellow-55, #bf8600)"
  },
  {
    label: i18nMessageBundle.appDashboardQOEPoorLabel,
    labelValue: i18nMessageBundle.appDashboardQOEPoorValue,
    color: "var(--hbr-color-danger, #CC2D37)"
  },
  {
    label: i18nMessageBundle.appDashboardQOEUnknownLabel,
    labelValue: i18nMessageBundle.appDashboardQOEUnknownValue,
    color: "var(--hbr-color-neutral-border, #889099)"
  }
];

const ChartLegend = props => {
  const { data, includeQuality, applicationQoe, isBarTrend } = props;
  // merging
  const legendItems = includeQuality ? [...qualityData, ...data] : data;

  const items = legendItems.map((legend, idx) => (
    <div className="legend-color-container" data-cy={legend.label} key={idx}>
      {legend.type && legend.type === "dashed" ? (
        <span className="dashed"></span>
      ) : (isBarTrend || legend.type === "bar") && legend.label && legend.label.length > 0 ? (
        <span
          className="bar-legend-common"
          style={{ backgroundColor: `${legend.color}` }}
        ></span>
      ) : legend.label && legend.label.length > 0 ? (
        <span
          className="square"
          style={{ backgroundColor: `${legend.color}` }}
        ></span>
      ) : (
        ""
      )}

      <span className="hbr-type-body3">{legend.label}</span>
      {applicationQoe ? (
        <span className="hbr-type-body4">{legend.labelValue}</span>
      ) : (
        ""
      )}
    </div>
  ));

  return (
    <div
      className={
        applicationQoe && !isBarTrend && !props.reporting
          ? css["chart-legend-application-qoe"]
          : css["chart-legend"]
      }
      style={props.style}
      data-cy="chartLegend"
    >
      {items}
    </div>
  );
};

ChartLegend.defaultProps = {
  style: {},
  data: [],
  includeQuality: false
};

ChartLegend.propTypes = {
  style: PropTypes.object,
  data: PropTypes.array.isRequired,
  includeQuality: PropTypes.bool,
  applicationQoe: PropTypes.bool,
  isBarTrend: PropTypes.bool,
  reporting: PropTypes.bool,
  legendData: PropTypes.object
};

export default ChartLegend;
