import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrTooltip] = reactWrapper(["hbr-tooltip"]);

const PathRecommendation = ({
  defaultInterfaceIds,
  recommendedInterfaceIds,
  isTrimmed = false
}) => {
  const recommendedInterfaceId = recommendedInterfaceIds
    ?.toString()
    .replaceAll(",", ", ");
  const defaultInterfaceId = defaultInterfaceIds
    ?.toString()
    .replaceAll(",", ", ");

  const comboLabel = `Switch from ${defaultInterfaceId} to ${recommendedInterfaceId}`;
  const interfaceName =
    comboLabel.length > 40 ? (
      <HbrTooltip
        id="tooltip-inteface-label"
        content={comboLabel}
        placement="top"
      >
        {comboLabel.substring(0, 39) + "..."}
      </HbrTooltip>
    ) : (
      comboLabel
    );

  if (isTrimmed) {
    return (
      <div className="hbr-type-body3">
        <span>{interfaceName}</span>
      </div>
    );
  } else {
    return (
      <div className="switch-to-path">
        <span className="card-action-view-row-1 hbr-type-body3">{` Switch from `}</span>
        <span className="card-action-view-row-2 hbr-type-body3">
          {defaultInterfaceId}
        </span>
        <span className="card-action-view-row-1 hbr-type-body3"> {` to `}</span>
        <span className="card-action-view-row-2 hbr-type-body3">
          {recommendedInterfaceId}
        </span>
      </div>
    );
  }
};

PathRecommendation.propTypes = {
  defaultInterfaceIds: PropTypes.array,
  recommendedInterfaceIds: PropTypes.array,
  date: PropTypes.string,
  isTrimmed: PropTypes.bool
};

export default PathRecommendation;
