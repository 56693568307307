export const getSiteUsage = {
  aggregation: {
    field: [
      {
        property: "site_id",
        sequence: "1"
      }
    ],
    metrics: [
      {
        property: "octets_sum",
        type: "sum"
      }
    ]
  },
  fields: ["site_id"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "site_id",
        operator: "in",
        type: "string",
        value: ["101"]
      }
    ]
  },
  size: "100"
};

export const getSitesOverviewQuery = {
  fields: [
    "site_id",
    "site_name",
    "loss",
    "latency",
    "jitter",
    "latitude",
    "longitude",
    "city",
    "site_state",
    "availability",
    "availability_status",
    "users",
    "loss_prev",
    "latency_prev",
    "jitter_prev",
    "availability_prev",
    "users_prev",
    "availability_status_prev",
    "usage",
    "usage_prev"
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "timeframe",
        operator: "equal",
        type: "string",
        value: [""]
      }
    ]
  }
};
export const aggregateApplicationsTable = {
  table_filter: { vqoe_status: ["poor", "fair", "good", "unknown"] },
  current_period: [],
  previous_period: [],
  sort: { usage: "desc" },
  size: 10000,
  offset: 0,
  time_frame: "12h"
};

export const applicationFamilySlaPayload = {
  fields: ["application_class", "application_family_long_name"],
  sort: [{ order: "asc", property: "application_family_long_name" }]
};
