import reactWrapper from "@harbor/elements/utils/react/wrapper";

import QoEComponent from "../QoEComponent";
import css from "../commonMagneticStyle.less";
const [HbrIcon, HbrTooltip] = reactWrapper(["hbr-icon", "hbr-tooltip"]);

const QoeListComponent = ({
  overviewPage,
  iconType,
  qoeScore,
  qoeLabels,
  tooltipText,
  position
}) => {
  const component = [];
  for (let qoeIndex = 0; qoeIndex < iconType.length; qoeIndex++) {
    component.push(
      <div
        className={
          overviewPage
            ? css["common-qoe-list-overview"]
            : css["common-qoe-list"]
        }
        key={qoeIndex}
      >
        <QoEComponent
          iconType={iconType[qoeIndex]}
          qoeScore={qoeScore[qoeIndex].count}
          qoeTextValue={qoeLabels[qoeIndex]}
        />
        {tooltipText && qoeIndex === position && (
          <HbrTooltip content={tooltipText} placement="top">
            <HbrIcon name="info" sentiment="neutral" />
          </HbrTooltip>
        )}
      </div>
    );
  }
  return component.map(c => c);
};

export default QoeListComponent;
