import React, { useState } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//components
import SummaryOverview from "./SummaryOverview";
import TopSitesComponent from "./TopSitesComponent";

//utils
import { SWITCH } from "../../utils/enums";
import { isSiteViewedByAvailability, getMapSummaryApp360 } from "../../utils/common";

//i18n files
import i18n from "amdi18n-loader!../nls/i18n";

//style
import css from "../commonMagneticStyle.less";

const [
  HbrCard,
  HbrRadioButton,
  HbrViewSwitcher,
  HbrBadge,
  HbrIcon
] = reactWrapper([
  "hbr-card",
  "hbr-radio-button",
  "hbr-view-switcher",
  "hbr-badge",
  "hbr-icon"
]);
const MapSummaryComponent = props => {
  // When the isEnabled flag is true, the ui will show recommendation and if the overlay is enabled for feature
  // else feature is disabled
  const { OVERVIEW, TOP_SITES } = SWITCH;
  const { handleChange, state, countActionForAllSitesObj } = props;
  const totalRecommendedSites = countActionForAllSitesObj
    ? countActionForAllSitesObj?.sitesLen
    : 0;
  const [toogle, setToggle] = useState(false);
  const [siteDashboardWidget, showDashboardWidget] = useState(true);
  const isSiteAvailabilityView = isSiteViewedByAvailability(props.sitesView);

  /**  Temporary fix . Show and hide the content of the map widget card .
   * Once harbour accordion is ready need to implement component * */

  const showHideMapSummary = () => {
    if (!toogle) {
      showDashboardWidget(false);
      setToggle(true);
    } else {
      showDashboardWidget(true);
      setToggle(false);
    }
  };

  const app360Data = getMapSummaryApp360(props);

  return (
    <div className={css["all-sites-overview-widget"]}>
      <div className="outer-div">
        <HbrCard
          id="non-interactive"
          slot="label"
          className="sites-widget-card"
        >
          <div className="map-summary-total-sites hbr-type-h3">
            {i18n.allSitesAllSitesLabel}
            <HbrBadge pill size="small">
              {isSiteAvailabilityView
                ? props.isReporting ? app360Data.total_sites : props.sites.length
                : totalRecommendedSites}
            </HbrBadge>

            <div className="site-toggle-icon" onClick={showHideMapSummary}>
              {toogle ? (
                <HbrIcon
                  className="qoeTitleIcon"
                  name="caret-down"
                  sentiment="neutral"
                ></HbrIcon>
              ) : (
                  <HbrIcon
                    className="qoeTitleIcon"
                    name="caret-up"
                    sentiment="neutral"
                  ></HbrIcon>
                )}
            </div>
          </div>
          {siteDashboardWidget ? (
            <div>
              {isSiteAvailabilityView && !props.isReporting && (
                <div>
                  <HbrViewSwitcher
                    value={state}
                    name="swc1"
                    onClick={handleChange}
                  >
                    <HbrRadioButton value={OVERVIEW.ACTION}>
                      {OVERVIEW.LABEL}
                    </HbrRadioButton>
                    <HbrRadioButton value={TOP_SITES.ACTION}>
                      {i18n.allSitesView.topSites}
                    </HbrRadioButton>
                  </HbrViewSwitcher>
                </div>
              )}
              <div>
                {state === OVERVIEW.ACTION ? (
                  <SummaryOverview
                    {...props}
                    isQoEMetric={props.isQoEMetric}
                    app360Data={app360Data}
                    countActionForAllSitesObj={countActionForAllSitesObj}
                  />
                ) : (
                    <TopSitesComponent {...props} />
                  )}
              </div>
            </div>
          ) : null}
        </HbrCard>
      </div>
    </div>
  );
};

MapSummaryComponent.propTypes = {
  state: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  sitesView: PropTypes.string.isRequired,
  countActionForAllSitesObj: PropTypes.object,
  globalFilter: PropTypes.object,
  sites: PropTypes.array.isRequired,
  mapSummaryStyle: PropTypes.object,
  isReporting: PropTypes.bool,
  match: PropTypes.object,
  isQoEMetric: PropTypes.bool
};

export default MapSummaryComponent;
