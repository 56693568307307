import React, { useEffect, useState } from "react";
import CNV from "@harbor/cnv";
import Spinner from "../../common/Spinner";
import useAggregateDataApis from "../../common/SnailTrail/viewHooks/useCommons";
import ErrorComponent from "../../common/ErrorComponent";
import NoDataAvailable from "../../common/NoDataAvailable";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import PropTypes from "prop-types";
import css from "../commonMagneticStyle.less";
import {
  getpath,
  getNode,
  getHop,
  getLinkConfig,
  getNodeAxisConfig
} from "./SnailTrailConfig";
import { colors } from "../../utils/colors";
import {
  getArrayMinValue,
  getArrayMaxValue,
  getArrayAvgValue
} from "../../utils/displayTime";

const SnailTrailPathTrace = props => {
  const { state, getPathTraceGraphData } = useAggregateDataApis();
  const [snailTrailGraphData, setSnailTrailGraphData] = useState([]);
  const [graphNodes, setGraphNodes] = useState([]);
  const [graphLinks, setGraphLinks] = useState("");
  const [customXValue, setCustomXValue] = useState(0);
  const [customYValue, setCustomYValue] = useState(0);
  let linkTunnels = [];
  let eventIds = [];
  let tunnelEventIdsList = [];
  useEffect(() => {
    if (
      props.timeFilter &&
      props.timeFilter.length > 0 &&
      props.eventsListData &&
      props.eventsListData.eventIdList &&
      props.eventsListData.eventIdList.length > 0
    ) {
      let timeRange =
        props.scrollBarTimeFilter && props.scrollBarTimeFilter.length > 0
          ? props.scrollBarTimeFilter
          : props.timeFilter;
      getPathTraceGraphData(
        props.eventsListData,
        timeRange,
        props.selectedSiteId
      );
      setSnailTrailGraphData([]);
    } else {
      setGraphNodes([]);
      setGraphLinks([]);

      setSnailTrailGraphData([]);
    }
  }, [props.eventsListData]);

  useEffect(() => {
    if (
      state.isFetchingPathTraceGraphDataDone &&
      !state.isFetchingPathTraceGraphData &&
      state.pathTraceGraphData
    ) {
      setSnailTrailGraphData(state.pathTraceGraphData);
    }
  }, [
    state.isFetchingPathTraceGraphDataDone,
    state.isFetchingPathTraceGraphData
  ]);

  const countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  /***
   * @desc  creats tooltip data on hover of a node
   */

  const hoverInfo = () => {
    function hoverNode(node) {
      const holder = document.createElement("div");
      const dataTable = document.createElement("table");
      dataTable.style.fontWeight = 200;

      dataTable.innerHTML = `<tr><td class=${
        css.snailTrailTooltipTitleBold
      }>IP address</td>
        <td class=${css.snailTrailTooltipValueBold}>
        ${
          node.additionalInfo.local_system_ip
            ? node.additionalInfo.local_system_ip
            : node.additionalInfo.ip_addr_2
        }
      </td></tr>`;

      if (
        node.additionalInfo.node_color &&
        node.additionalInfo.node_color != ""
      ) {
        dataTable.innerHTML += `<tr><td class=${css.snailTrailTooltipTitle}>Color</td><td>
      ${node.additionalInfo.node_color}
    </td></tr>`;
      }
      if (
        node.additionalInfo.hop_carrier &&
        node.additionalInfo.hop_carrier != ""
      ) {
        dataTable.innerHTML += `<tr><td class=${css.snailTrailTooltipTitle}>Carrier</td><td>
      ${node.additionalInfo.hop_carrier}
    </td></tr>`;
      }
      dataTable.innerHTML += `<tr><td class=${
        css.snailTrailTooltipTitle
      }>Location</td><td>
      ${
        node.additionalInfo.local_location
          ? node.additionalInfo.local_location
          : node.additionalInfo.hop_location
      }
    </td></tr>`;
      dataTable.innerHTML += `<tr><td class=${
        css.snailTrailTooltipTitle
      }>Latitude</td><td>
      ${
        node.additionalInfo.local_latitude
          ? node.additionalInfo.local_latitude
          : node.additionalInfo.hop_latitude
      }
    </td></tr>`;

      dataTable.innerHTML += `<tr><td class=${
        css.snailTrailTooltipTitle
      }>Longitude</td><td>
          ${
            node.additionalInfo.local_longitude
              ? node.additionalInfo.local_longitude
              : node.additionalInfo.hop_longitude
          }
        </td></tr>`;
      if (node.additionalInfo.max_loss) {
        dataTable.innerHTML += `<tr><td class=${css.snailTrailTooltipLossTitle}>Packet loss (%)</td></tr>`;
        dataTable.innerHTML += `<tr>
 
<td class=${css.snailTrailTooltipMinValue}>Min: ${
          node.additionalInfo.min_loss ? node.additionalInfo.min_loss : 0
        }</td>

<td class=${css.snailTrailTooltipMaxValue}>Max: ${
          node.additionalInfo.max_loss ? node.additionalInfo.max_loss : 0
        }</td>
<td class=${css.snailTrailTooltipAvgValue}>Avg: ${
          node.additionalInfo.avg_loss ? node.additionalInfo.avg_loss : 0
        }</td></tr>`;

        dataTable.innerHTML += `<tr><td class=${css.snailTrailTooltipTitleBold}>Latency (ms)</td></tr>`;
        dataTable.innerHTML += `<tr>
   
<td class=${css.snailTrailTooltipMinValue}>Min: ${
          node.additionalInfo.min_rtt ? node.additionalInfo.min_rtt : 0
        }</td>

<td class=${css.snailTrailTooltipMaxValue}>Max: ${
          node.additionalInfo.max_rtt ? node.additionalInfo.max_rtt : 0
        }</td>
<td  class=${css.snailTrailTooltipAvgValue}>Avg: ${
          node.additionalInfo.avg_rtt ? node.additionalInfo.avg_rtt : 0
        }</td></tr>`;
      }
      holder.appendChild(dataTable);

      return holder.innerHTML;
    }

    /***
     * @desc  creats tooltip data on hover of a node
     */
    function _getRootElement(util, aspect) {
      const rootElement = document.createElement("div");
      rootElement.setAttribute("id", "tooltipContainer");
      aspect.before("onNodeHover", nodeID => {
        if (nodeID) {
          let node = util.getNodesData()[nodeID];
          let element = document.getElementById("tooltipContainer");
          let tooltipContainerParent = element.parentNode;
          let tooltipContainerParentShow = tooltipContainerParent.parentNode;
          if (node.additionalInfo.showTooltip) {
            tooltipContainerParentShow.style.display = "block";
            let toolbarContainer = tooltipContainerParentShow.previousSibling;
            toolbarContainer.style.zIndex = 1;
            let pathTraceContainer = tooltipContainerParentShow.parentNode;
            pathTraceContainer.style.overflow = "visible";
            let content = hoverNode(node);
            if (content != undefined && content != "") {
              rootElement.innerHTML = content;
            }
          } else {
            tooltipContainerParentShow.style.display = "none";
          }
        }
      });
      if (rootElement) {
        return rootElement;
      }
    }

    return {
      getDOMNode(util, aspect) {
        return _getRootElement(util, aspect);
      },
      dispose() {}
    };
  };

  useEffect(() => {
    let highlightLink = "";
    let hasShowEvents =
      props.showEvents &&
      props.showEvents.length > 0 &&
      props.showEvents[0] != null;
    let hasHideEvents =
      props.hideEvents &&
      props.hideEvents.length > 0 &&
      props.hideEvents[0] &&
      props.hideEvents[0].length > 0;

    let addLatencyLoss = !hasShowEvents && !hasHideEvents;
    /*** 
     *  @desc This finds what eventId should be highlighted based on line chart hover, 
    picks the event ID thats drawn on path trace*/

    if (hasShowEvents) {
      props.showEvents.forEach(data => {
        if (tunnelEventIdsList.indexOf(data) < 0) {
          Object.keys(eventIds).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(eventIds, key)) {
              if (Object.values(eventIds[key]).indexOf(data) > -1) {
                highlightLink = key;
              }
            }
            return highlightLink;
          });
        }
      });
    }

    if (
      state.isFetchingPathTraceGraphDataDone &&
      !state.isFetchingPathTraceGraphData &&
      snailTrailGraphData &&
      snailTrailGraphData.data
    ) {
      let graphNodes = [];
      let graphLinks = [];
      let uniqueTunnelValue = [];
      let uniqueParentValue = [];
      let uniqueTunnelName = [];
      let uniqueNodeValue = [];
      let startNodeValue = [];
      let count = 0;
      snailTrailGraphData.data.forEach(data => {
        let eventId = data.event_id;
        let pathColor = "";
        let tunnelName = data.tunnel_name;

        /***
         * @desc stores the uniques value of tunnels from data
         * */
        if (uniqueTunnelName.indexOf(tunnelName) == -1) {
          uniqueTunnelName.push(tunnelName);
        }
        /***
         * @desc gets the color for the path to be consistent with color used for tunnel on charts
         * */
        props.colorData.forEach(colorData => {
          if (Object.prototype.hasOwnProperty.call(colorData, tunnelName)) {
            pathColor = colorData[tunnelName];
          }
        });
        if (uniqueTunnelValue.indexOf(data.local_system_ip) == -1) {
          /***
           * @desc  Increases the value of xaxis based the the count of start node
           * @param data data from api response to create nodes
           * @param yAxisValue yAxis value where to place node
           * @param pathColor color of tunnel from chart
           * @param tunnelName name of tunnel
           * @param eventId event id for the event
           *
           */

          let yAxisValue = count * 10;
          let nodeConfig = getNode(
            data,
            yAxisValue,
            pathColor,
            tunnelName,
            eventId
          );
          graphNodes.push(nodeConfig);
          /** count of start nodes */
          count = count + 1;
          /** for each data object store unique parent value */
          uniqueTunnelValue.push(data.local_system_ip);
        }
        let hopInfoData = JSON.parse(data.hop_infos);
        hopInfoData.sort(function(a, b) {
          return a.hop_idx - b.hop_idx;
        });
        hopInfoData.forEach(hopData => {
          let customX = 0;
          let customY = 0;
          let nodeExist = false;
          let showColor = true;
          let customPathConfig = "";

          /** gives count of siblings */
          let hasSiblingCount = countOccurrences(
            uniqueParentValue,
            hopData.ip_addr_1
          );

          /** checks if link exist from that node */
          let hasLinkExistCount = countOccurrences(
            startNodeValue,
            hopData.ip_addr_1
          );

          let hopParentCustomParam = {};

          /** gets x,y axis of parent node */
          graphNodes.map(node => {
            if (node.id == hopData.ip_addr_1) {
              hopParentCustomParam = node.customParam;
              return hopParentCustomParam;
            }
          });

          /** This has the list of tunnel passing through source and target */
          let linktunnelName = hopData.ip_addr_2 + ":" + hopData.ip_addr_1;
          if (linkTunnels[linktunnelName]) {
            if (
              linkTunnels[linktunnelName].indexOf(tunnelName) == -1 &&
              (!hasHideEvents ||
                (hasHideEvents &&
                  props.hideEvents[0] &&
                  props.hideEvents[0].indexOf(eventId) == -1))
            ) {
              linkTunnels[linktunnelName].push(tunnelName);
            }
          } else {
            if (!hasHideEvents || props.hideEvents[0].indexOf(eventId) == -1) {
              linkTunnels[linktunnelName] = [tunnelName];
            }
          }

          /** This has the index of tunnel passing through source and target */
          let tunnelLinkIndex = 0;

          if (linkTunnels && linkTunnels[linktunnelName]) {
            tunnelLinkIndex = linkTunnels[linktunnelName].indexOf(tunnelName);
          }
          /** This has the position of tunnel passing through source and target based on the index of tunnel*/
          let linkPosition = tunnelLinkIndex % 2 == 0 ? "+" : "-";
          /** This gives the position index based on its being drawn on top or bottom axis */
          let linkIndex =
            hasShowEvents ||
            (hasHideEvents &&
              props.hideEvents[0] &&
              props.hideEvents[0].indexOf(eventId) != -1)
              ? 0
              : Math.ceil(tunnelLinkIndex / 2);
          /** generates Xaxis position based on parent x-axis or previous x-axis value */
          let customXPosition =
            hopParentCustomParam &&
            (hopParentCustomParam.x || hopParentCustomParam.x == 0)
              ? hopParentCustomParam.x + 2
              : customXValue + 2 * 2;

          /***
           * @desc  generates path styling
           * @param linkPosition {string} it defines it needs to be on top or bottom axis (+ or -)
           * @param   linkIndex {number} index of link
           * @param hasLinkExistCount {string} count of links between source and target
           */

          customPathConfig = getpath(
            linkPosition,
            linkIndex,
            hasLinkExistCount
          );

          /***
           * @desc  Increases the value of xaxis based the the count of start node
           * @param uniqueTunnelValue {object} list of unique tunnel values
           * @param hopData {object}  hop data from api response to create nodes
           * @param customXPosition {number} position of xaxis
           * @param hopParentCustomParam {object} position of parent
           * @param hasSiblingCount {number} sibling count
           * @param customYValue {number} x cordinate
           * @param customXValue {number} y cordinate
           *
           */

          let linkStyle = getNodeAxisConfig(
            uniqueTunnelValue,
            hopData,
            customXPosition,
            hopParentCustomParam,
            hasSiblingCount,
            customYValue,
            customXValue
          );
          customX = linkStyle[0];
          customY = linkStyle[1];

          if (
            ((hopData.is_last_hop != "true" &&
              uniqueNodeValue.indexOf(hopData.ip_addr_2) == -1) ||
              (hopData.is_last_hop == "true" &&
                uniqueNodeValue.indexOf(hopData.ip_addr_2 + "lasthop") == -1 &&
                uniqueNodeValue.indexOf(hopData.ip_addr_2) == -1)) &&
            hopData.ip_addr_1 != hopData.ip_addr_2
          ) {
            setCustomXValue(customX);
            setCustomYValue(customY);
            uniqueParentValue.push(hopData.ip_addr_1);
            if (
              hopData.is_last_hop != "true" &&
              uniqueNodeValue.indexOf(hopData.ip_addr_2) == -1
            ) {
              uniqueNodeValue.push(hopData.ip_addr_2);
            }

            if (
              hopData.is_last_hop == "true" &&
              uniqueNodeValue.indexOf(hopData.ip_addr_2 + "lasthop") == -1
            ) {
              uniqueNodeValue.push(hopData.ip_addr_2 + "lasthop");
            }
            /***
             * @desc  Increases the value of xaxis based the the count of start node
             * @param data data from api response to create nodes
             * @param hopData hop data from api response to create nodes
             * @param customX xAxis value where to place node
             * @param customY yAxis value where to place node
             * @param pathColor color of tunnel from chart
             * @param tunnelName name of tunnel
             * @param eventId event id for the event
             *
             */

            let hopConfig = getHop(
              data,
              hopData,
              customX,
              customY,
              pathColor,
              tunnelName,
              eventId,
              addLatencyLoss
            );
            graphNodes.push(hopConfig);
          } else {
            if (
              uniqueNodeValue.indexOf(hopData.ip_addr_2) != -1 ||
              uniqueNodeValue.indexOf(hopData.ip_addr_2 + "lasthop") != -1
            ) {
              if (
                (hasShowEvents && props.showEvents.indexOf(eventId) != -1) ||
                (hasHideEvents &&
                  props.hideEvents[0] &&
                  props.hideEvents[0].indexOf(eventId) != -1) ||
                (!hasHideEvents && !hasShowEvents)
              ) {
                graphNodes.map(node => {
                  if (
                    node.id == hopData.ip_addr_2 ||
                    node.id == hopData.ip_addr_2 + "lasthop"
                  ) {
                    node.additionalInfo.loss_data.push(
                      parseFloat(hopData.loss)
                    );
                    const minLoss = getArrayMinValue(
                      node.additionalInfo.loss_data
                    );
                    const maxLoss = getArrayMaxValue(
                      node.additionalInfo.loss_data
                    );
                    node.additionalInfo.rtt_data.push(parseFloat(hopData.rtt));
                    const minRtt = getArrayMinValue(
                      node.additionalInfo.rtt_data
                    );
                    const maxRtt = getArrayMaxValue(
                      node.additionalInfo.rtt_data
                    );
                    node.additionalInfo.min_loss = minLoss.toFixed(2);
                    node.additionalInfo.max_loss = maxLoss.toFixed(2);
                    node.additionalInfo.avg_loss = getArrayAvgValue(
                      node.additionalInfo.loss_data
                    ).toFixed(2);
                    node.additionalInfo.min_rtt = minRtt.toFixed(2);
                    node.additionalInfo.max_rtt = maxRtt.toFixed(2);
                    node.additionalInfo.avg_rtt = getArrayAvgValue(
                      node.additionalInfo.rtt_data
                    ).toFixed(2);
                  }
                });
              }
            }
          }

          /** checks if link exist between source and target */
          graphLinks.map(link => {
            if (
              link.target == hopData.ip_addr_2 &&
              link.source == hopData.ip_addr_1
            ) {
              if (
                link.customParam.additionalInfo &&
                link.customParam.additionalInfo.tunnelName == tunnelName
              ) {
                if (
                  props.showEvents &&
                  props.showEvents.length == 0 &&
                  hopData.hop_active == "true"
                ) {
                  nodeExist = true;
                } else if (
                  props.showEvents.indexOf(eventId) == -1 &&
                  hopData.hop_active == "true"
                ) {
                  nodeExist = true;
                }
                if (
                  link.customParam.additionalInfo &&
                  link.customParam.additionalInfo.eventId != eventId
                ) {
                  if (eventIds[link.customParam.additionalInfo.eventId]) {
                    if (
                      eventIds[link.customParam.additionalInfo.eventId].indexOf(
                        eventId
                      ) == -1
                    ) {
                      eventIds[link.customParam.additionalInfo.eventId].push(
                        eventId
                      );
                    }
                  }
                }
              }
              return nodeExist;
            }
          });

          /** draws link if link between source and target doesn't exist */
          if (!nodeExist && hopData.ip_addr_1 != hopData.ip_addr_2) {
            startNodeValue.push(hopData.ip_addr_2);
            if (tunnelEventIdsList.indexOf(eventId) == -1) {
              tunnelEventIdsList.push(eventId);
            }

            eventIds[eventId] = [];
            let greyedPathNode = true;

            if (hasShowEvents) {
              /** greyedPathNode is true then get color else greyed */
              greyedPathNode =
                props.showEvents.indexOf(eventId) == -1
                  ? highlightLink && highlightLink == eventId
                    ? true
                    : false
                  : true;
              if (
                greyedPathNode == false &&
                highlightLink &&
                highlightLink == eventId
              ) {
                greyedPathNode = true;
              }
            } else {
              greyedPathNode =
                props.hideEvents &&
                props.hideEvents.length > 0 &&
                props.hideEvents[0] &&
                props.hideEvents[0].indexOf(eventId) == -1;
            }
            if (hasShowEvents || hasHideEvents) {
              showColor = greyedPathNode ? true : false;
            }
            if (showColor && hopData.hop_active == "false") {
              graphNodes.map(node => {
                if (node.id == hopData.ip_addr_1) {
                  node.customParam.color = colors.red45;
                  node.customParam.border = colors.red45;
                }
              });
            }

            let linkConfig = getLinkConfig(
              data,
              hopData,
              tunnelName,
              eventId,
              showColor,
              pathColor,
              customPathConfig
            );

            /** This sets value to show or hide tooltip */
            graphNodes.map((node, i) => {
              if (
                node.id == hopData.ip_addr_2 ||
                node.id == hopData.ip_addr_1 ||
                node.id == hopData.ip_addr_2 + "lasthop" ||
                node.id == data.local_system_ip
              ) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    graphNodes[i].additionalInfo,
                    "showTooltip"
                  )
                ) {
                  if (graphNodes[i].additionalInfo["showTooltip"] == false) {
                    graphNodes[i].additionalInfo["showTooltip"] = showColor;
                  }
                } else {
                  graphNodes[i].additionalInfo["showTooltip"] = showColor;
                }
              }
            });
            graphLinks.push(linkConfig);
          }
        });
      });

      setGraphNodes(graphNodes);
      setGraphLinks(graphLinks);
    }
  }, [snailTrailGraphData, props.hideEvents, props.showEvents]);

  /***
   * @desc  creats CNV
   */

  useEffect(() => {
    if (
      state.isFetchingPathTraceGraphDataDone &&
      !state.isFetchingPathTraceGraphData &&
      snailTrailGraphData &&
      snailTrailGraphData.data &&
      snailTrailGraphData &&
      graphNodes &&
      graphNodes.length > 0 &&
      graphLinks
    ) {
      let element = document.getElementById("snailTrail-container");
      if (element) {
        while (element.lastElementChild) {
          element.removeChild(element.lastElementChild);
        }
      }
      const TOPO_PROPS = {
        id: "snailTrail-pathTrace",
        applyTo: document.getElementById("snailTrail-container"),
        style: {
          width: "100%",
          height: "65vh",
          background: colors.gray100,
          paddingTop: "-87px"
        },
        zoomToFit: true,
        dimensions: { minX: -5, maxX: 5, minY: 5, maxY: -5 } // CNV bounds
      };

      const TOPO_CONFIG = [
        {
          target: ["snailTrail-pathTrace"],
          extensionPoint: "widget",
          location: "toolbarBottom",
          boilerplate: ["Legend", "Fullscreen", "Recenter", "ZoomTools"],
          settings: { showShortcuts: true }
        },
        {
          target: ["snailTrail-pathTrace"],
          extensionPoint: "links",
          boilerplate: ["logical-path"]
        },
        {
          target: ["snailTrail-pathTrace"],
          extensionPoint: "widget",
          location: "toolbarOverlay",
          boilerplate: ["HoverView"],
          settings: {
            links: false,
            nodes: false
          }
        },
        {
          extensionPoint: "widget",
          location: "toolbarOverlay",
          boilerplate: ["HoverView"],
          settings: {
            position: "above",
            pointerEvents: false,
            horizontal: true
          }
        },
        {
          target: ["snailTrail-pathTrace"],
          extensionPoint: "HoverView",
          boilerplate: ["hoverInfo"]
        }
      ];

      let TOPO_DATA = {};
      TOPO_DATA = {
        response: {
          nodes: graphNodes,
          links: graphLinks
        }
      };

      const cnv = new CNV();

      cnv.util.addBoilerplates("tools", { hoverInfo });
      cnv.init(TOPO_PROPS, TOPO_DATA, TOPO_CONFIG);
      cnv.util.customEventPathTraceRun = () => {};
      cnv.util.isReady().then(() => {
        let element = document.getElementById("snailTrail-container");
        if (element) {
          let childNodesCount = element.childElementCount;
          if (childNodesCount > 1) {
            element.removeChild(element.children[0]);
          }
        }
      });
    }
  }, [graphLinks]);
  if (
    state.isFetchingPathTraceGraphDataDone &&
    !state.isFetchingPathTraceGraphData &&
    !state.isFetchingPathTraceGraphDataError &&
    snailTrailGraphData
  ) {
    return (
      <React.Fragment>
        {graphNodes && graphNodes.length > 0 && (
          <div
            className={css["snailTrail-container"]}
            id={"snailTrail-container"}
          />
        )}
        {graphNodes && graphNodes.length == 0 && (
          <div className={css["snailTrail-spinner"]}>
            <NoDataAvailable
              text={i18nMessageBundle.applicationDashboardNoData}
            />
          </div>
        )}
      </React.Fragment>
    );
  } else if (
    state.isFetchingPathTraceGraphDataDone &&
    !state.isFetchingPathTraceGraphData &&
    state.isFetchingPathTraceGraphDataError
  ) {
    return (
      <div>
        <ErrorComponent
          code={"500"}
          width={"110px"}
          className={"large-dashlet-error"}
        />
      </div>
    );
  } else {
    let element = document.getElementById("snailTrail-container");
    if (element) {
      if (element.hasChildNodes()) {
        element.removeChild(element.children[0]);
      }
    }
    return (
      <div className={css["snailTrail-spinner"]}>
        <Spinner></Spinner>
      </div>
    );
  }
};

SnailTrailPathTrace.propTypes = {
  timeFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scrollBarTimeFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  eventsListData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showEvents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hideEvents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedSiteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colorData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

SnailTrailPathTrace.defaultProps = {
  eventsListData: [],
  scrollBarTimeFilter: []
};

export default SnailTrailPathTrace;
