import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import {
  getTipGanttChart,
  getTipBulletColumnChart,
  getTooltipNoData,
  getTooltipMissingData,
  timeUnits,
  getInterval,
  binarySearchChartData
} from "../../utils/common";
import {
  customizeLegend,
  customizeTooltip,
  getTooltipHTMLString,
  getTooltipDate,
  getTimeState
} from "../../utils/chart";
import { formatSizeUnitsRound } from "../../utils/format";
import {
  displayDateTime,
  displayUserExpChartTooltp,
  isDateInBetween,
  addUnitsFromDate,
  displayPathAnalyticsTime
} from "../../utils/displayTime";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { colors, chartPrimaryColors } from "../../utils/colors";
import { defaultTextValue } from "../../utils/enums";

const getDataInterval = data => {
  const newData = [];
  data.forEach(item => {
    if (newData.indexOf(item.toDate) === -1) {
      newData.push(item.toDate);
    }
  });
  newData.sort((a, b) => b - a);
  return getInterval(newData.map(item => ({ date: item })));
};

export const GanttChart = props => {
  const chartsRef = useRef(props.chartsData);
  const currentChartRef = useRef();

  const createChart = (id, data) => {
    const timeState = getTimeState();
    // create chart
    const chart = am4core.create(id, am4charts.XYChart);
    chart.id = id;
    // set chart data
    chart.data = data || [];
    // set chart style
    chart.paddingTop = 0;
    chart.paddingRight = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.width = am4core.percent(100);
    chart.minHeight = 150;
    if (props.chartHeight) chart.height = props.chartHeight;
    // customize tooltip
    customizeTooltip(chart.tooltip);
    chart.tooltipHTML = "";
    // enable tooltip
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";

    // set date axis as x axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // set time interval for plotting graph data points
    dateAxis.baseInterval = props.calcBaseIterval
      ? getDataInterval(data)
      : { count: 1, timeUnit: "minute" };
    const timeUnit = dateAxis.baseInterval.timeUnit;
    const unitCount = dateAxis.baseInterval.count;
    chart.intervalMiliSecs =
      timeUnits.find(n => n.name === timeUnit)["timestamp"] * unitCount;
    // disable tooltip of x axis data points
    dateAxis.cursorTooltipEnabled = false;
    // set grid location from middle to start of the interval
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.grid.template.disabled = true;
    // set x axis label style
    dateAxis.renderer.labels.template.location = 0.0001;
    // set start and end time of date axis
    dateAxis.strictMinMax = true;
    if (props.min) dateAxis.min = props.min;
    if (props.max) dateAxis.max = props.max;

    // set category axis as y axis
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    // set y axis label
    categoryAxis.title.text = props.label;
    categoryAxis.title.userClassName = "y-axis-title";
    // disable y axis label
    if (props.disableYAxisLabel) categoryAxis.title.disabled = true;
    // set minimum grid distance in pixels
    categoryAxis.renderer.minGridDistance = 30;
    // disable tooltip of y axis data points
    categoryAxis.cursorTooltipEnabled = false;
    // set start value of y axis
    categoryAxis.min = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    // set no data available message
    if (chart.data.length === 0) {
      const label = chart.createChild(am4core.Label);
      // set no data text
      label.text = i18n.noData;
      // set label style
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(30);
      label.horizontalCenter = "middle";
    }

    // if (props.showTipsAlways) chart.cursor.fullWidthLineX = false;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.openDateX = "fromDate";
    series.dataFields.dateX = "toDate";
    series.dataFields.categoryY = "name";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.strokeOpacity = 1;
    series.columns.template.height =
      props.seriesHeight || am4core.percent(100);
    // gap > (autoGapCount * baseInterval) => gap > (60 * 1 minute)
    series.connect = false;
    series.autoGapCount = 60;
    if (dateAxis.baseInterval.timeUnit === "minute")
      series.autoGapCount = 
        timeState.interval / (60000 * dateAxis.baseInterval.count);
    // customize tooltip
    customizeTooltip(series.tooltip);

    if (props.customized) {
      series.columns.template.propertyFields.stroke = "color";
      categoryAxis.renderer.labels.template.disabled = true;
      // add legend to chart
      chart.legend = new am4charts.Legend();
      chart.legend.data = [];
      // customize legend
      customizeLegend(chart.legend, true, true);
      chart.legend.itemContainers.template.events.on("toggled", e => {
        const targetColor = e.target.dataItem.dataContext.fill.hex;
        series.columns.each(column => {
          if (column.fill.hex === targetColor) {
            if (e.target.isActive) column.hide(0);
            else column.show(0);
          }
        })
      });

      series.events.on("ready", () => {
        const categories = [];
        const legendData = [];
        series.columns.each(column => {
          if (!categories.includes(column.dataItem.categoryY)) {
            categories.push(column.dataItem.categoryY);
            if (!props.isPathQoSdetails) legendData.push({
              name: column.dataItem.categoryY,
              fill: column.fill
            });
          }
        });
        chart.legend.data = legendData;
      });
    }
    else series.columns.template.stroke = am4core.color(colors.gray100);

    if (props.isPathQoSdetails) {
      series.columns.template.strokeWidth = 2;
      series.columns.template.stroke = am4core.color(colors.gray100);

      const topContainer = chart.chartContainer.createChild(am4core.Container);
      // Add title label
      let dateTitle = topContainer.createChild(am4core.Label);
      dateTitle.text = i18n.appGroupPerSite.loadBalanceTitle;
      dateTitle.fontWeight = 700;
      dateTitle.align = "left";
      dateTitle.fontSize = 18;
      dateTitle.paddingBottom = 15;
      topContainer.toBack();

      chart.cursor.lineX.strokeWidth = 2;
      chart.zoomOutButton.disabled = true;

      categoryAxis.renderer.inside = true;
      categoryAxis.renderer.labels.template.dy = -10;
      categoryAxis.renderer.labels.template.dx = 0;
      categoryAxis.renderer.labels.template.disabled = false;
    }

    if (props.tooltipFn) {
      series.columns.template.adapter.add("tooltipHTML", (
        text,
        target
      ) => {
        const data = target.tooltipDataItem.dataContext;
        return props.tooltipFn(data);
      });
    }

    chart.events.on("ready", () => {
      currentChartRef.current.isRendered = true;

      if (!props.customized || props.globalToolTipsFn) {
        chart.cursor.events.on(
          "cursorpositionchanged",
          e => props.hoverCallback?.(e, currentChartRef.current)
        );
        chart.events.on("out", () => {
          //deactivate all tooltips
          if (chartsRef !== undefined) {
            for (let x in chartsRef.current) {
              if (chartsRef.current[x].isRendered) {
                if (
                  chartsRef.current[x].cursor &&
                  chartsRef.current[x].cursor.tooltip
                ) {
                  chartsRef.current[x].cursor.tooltip.isActive = false;
                  chartsRef.current[x].cursor.tooltipHTML = "";
                }

                if (chartsRef.current[x].tooltip) {
                  chartsRef.current[x].tooltip.isActive = false;
                  chartsRef.current[x].tooltipHTML = "";
                }
              }
            }
          }
        });
      } else if (props.appMetricData !== null) {
        chart.cursor.events.on("cursorpositionchanged", e => {
          const xAxis = e.target.chart.xAxes.getIndex(0);
          const tooltipTimestamp = xAxis.positionToDate(
            xAxis.toAxisPosition(e.target.xPosition)
          ).valueOf();
          const accordionData = { date: 0, networkEntryDate: 0, circuits: {} };
          const networkData = props.appMetricData.network.data;
          const circuitData = props.appMetricData.circuit.usage;
          let networkEntry = null;
          let circuitEntry = null;
          if (networkData.length > 0) {
            networkEntry = binarySearchChartData(
              networkData,
              0,
              networkData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (networkEntry !== null) {
              const lastIndex = networkData.length - 1;
              if (
                networkEntry.date === networkData[lastIndex - 1].date
                && ((tooltipTimestamp - networkEntry.date)
                  > (networkData[lastIndex].date - tooltipTimestamp))
              ) networkEntry = networkData[lastIndex];
              Object.assign(accordionData, networkEntry);
              accordionData.networkEntryDate = networkEntry.date;
            }
          }
          if (circuitData.length > 0) {
            circuitEntry = binarySearchChartData(
              circuitData,
              0,
              circuitData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (circuitEntry !== null) {
              const lastIndex = circuitData.length - 1;
              if (
                circuitEntry.date === circuitData[lastIndex - 1].date
                && ((tooltipTimestamp - circuitEntry.date)
                  > (circuitData[lastIndex].date - tooltipTimestamp))
              ) circuitEntry = circuitData[lastIndex];
              Object.assign(accordionData, circuitEntry);
              for (const item of props.appMetricData.circuits)
                accordionData.circuits[item] = circuitEntry[item];
            }
          }
          if (accordionData.date === 0 && accordionData.networkEntryDate === 0) {
            props.hoverCallback(false);
            props.chartsData.forEach(item => {
              item.tooltip.isActive = false;
              item.tooltipHTML = "";
            });
          }
          else {
            const dateText = displayDateTime(accordionData.networkEntryDate);
            const usageDateText = displayDateTime(accordionData.date);
            const titles = props.appMetricData.titles;
            props.chartsData.forEach(item => {
              // item.tooltipHTML = "";
              const title = item.yAxes.values[0].title.text;
              const valueY = item.yAxes.values[0].valueY;
              const tooltipData = { ...accordionData, title }
              let tooltipHTML = "";
              if (title === titles.circuits) {
                if (circuitEntry !== null)
                  tooltipHTML = `
                    <div class="ttip-header">
                      <span class="date">${usageDateText}</span>
                    </div>
                  `;
              }
              else if (
                (title === titles.usage && circuitEntry !== null)
                || (title !== titles.usage && networkEntry !== null)
              ) {
                if (title !== titles.usage) {
                  const chartUnits = item.chartUnits || "";
                  tooltipData[valueY] = tooltipData[valueY].toFixed(1) + chartUnits;
                }
                else
                  item.series.values.forEach(
                    ({ dataFields: { valueY: circuit } }) => {
                      tooltipData[circuit] =
                        Number.isFinite(accordionData[circuit])
                          ? formatSizeUnitsRound(accordionData[circuit])
                          : defaultTextValue;
                    }
                  );
                tooltipHTML = getTooltipHTMLString(
                  [...item.series.values].reverse(),
                  tooltipData,
                  title === titles.usage ? usageDateText : dateText
                );
              }

              if (tooltipHTML !== "") {
                item.tooltipX = e.target.point.x;
                item.tooltipY = e.target.point.y;
                item.tooltip.isActive = true;
                item.tooltipHTML = tooltipHTML;
              }
              else {
                item.tooltip.isActive = false;
                item.tooltipHTML = "";
              }
            });
            if (props.topChart === true) {
              props.accordionCallbackFromParent(accordionData);
              props.hoverCallback(true);
            }
          }
        });

        chart.events.on("out", () => {
          props.hoverCallback(false);
          props.chartsData.forEach(item => {
            item.tooltip.isActive = false;
            item.tooltipHTML = "";
          });
        });
      } else {
        chart.cursor.events.on("cursorpositionchanged", e => {
          const accordionData = { circuits: {} };
          let showChartAccordion = false;
          if (props.chartsData) {
            const xAxis = e.target.chart.xAxes.getIndex(0);
            const tooltipTimestamp = xAxis.positionToDate(
              xAxis.toAxisPosition(e.target.xPosition)
            ).valueOf();

            props.chartsData.forEach(item => {
              item.tooltipHTML = undefined;
              const chartUnits = item.chartUnits || "";
              let tooltipHTML = "";
              let accordionText = "";
              let accordionDate = "";
              item.data.findIndex((data, dataIdx) => {
                const isLineChartTip = props.isPathQoSdetails
                  ? displayPathAnalyticsTime(tooltipTimestamp) ===
                  data.date
                  : false;
                const timestamp =
                  data.date ? data.date.valueOf() : 0;
                const showSyncTooltip = getTooltipDate(dataIdx, item, timestamp, tooltipTimestamp)
                  ? true
                  : false;

                if ("currentPaths" in data && data.fromDate) {
                  const displayTooltip = isDateInBetween(
                    tooltipTimestamp,
                    data.fromDate,
                    data.toDate
                  );

                  if (displayTooltip) {
                    // check if there is data for gantt chart
                    if (!data.hasGanttChartData) {
                      //dont show tooltip for gantt chart when no data
                      tooltipHTML = getTooltipNoData(data);
                    } else {
                      series.columns.template.tooltipHTML = getTipGanttChart(
                        data,
                        false,
                        true
                      );
                    }
                  }
                } else if ("defaultQuality" in data) {
                  if (
                    displayUserExpChartTooltp(tooltipTimestamp) ===
                    data.fromDateFormated
                  ) {
                    if (data.hasBulletColumnChartData) {
                      // tooltip for bullet column chart
                      tooltipHTML = getTipBulletColumnChart(data);
                    } else {
                      //dont show tooltip for bullet chart when no data
                      tooltipHTML = "";
                    }
                  }
                } else if (isLineChartTip || showSyncTooltip) {
                  const date = displayDateTime(data.date);
                  const title = item.yAxes.values[0].title.text;
                  const reverseSeries = [...item.series.values].reverse();
                  const tooltipData = { ...data, title };
                  let value = null;

                  if (title === "Usage") accordionData.circuits = {};
                  accordionDate = date;

                  if (props.isPathQoSdetails) {
                    const valueKeys = Object.keys(data);

                    valueKeys.splice(valueKeys.indexOf("date"), 1);
                    value = `
                      <table class="ttip-content">
                      ${valueKeys
                        .map((value, index) => `
                          <tr>
                            <td class="ttip-bwidth">
                              <div class="flex-items">
                                <span
                                  class="bar-legend"
                                  style="background-color:${chartPrimaryColors[index]};"
                                ></span>
                                ${title};
                              </div>
                            </td>
                            <td>
                              ${data["value" + index]}
                              ${title.includes("Loss") ? "%" : "ms"}
                            </td>
                          </tr>
                          `
                        )
                        .join("")}
                      </table>`;
                  } else {
                    const value0 = data.value ? data.value.toFixed(0) : 0;
                    const value1 = data.value ? data.value.toFixed(1) : 0;

                    value = item.formatTextChartRender
                      ? formatSizeUnitsRound(data.value)
                      : title === "User Count"
                        ? value0
                        : value1;
                  }


                  if (props.isPathQoSdetails) {
                    if (data.value0 === 0.0014) {
                      tooltipHTML = getTooltipMissingData(
                        displayUserExpChartTooltp(
                          addUnitsFromDate(data.date, 1, "h")
                        )
                      );
                    } else {
                      tooltipHTML = `
                        <div class="ttip-header">
                          <span class="date">${date}</span>
                        </div>
                        ${value}
                      `;
                    }
                  } else {
                    item.series.values.forEach(
                      ({ name, dataFields: { valueY } }) => {
                        tooltipData[valueY] = Number.isFinite(data[valueY]) ?
                          title === "Usage" ?
                            formatSizeUnitsRound(data[valueY])
                            : data[valueY].toFixed(1) + chartUnits
                          : defaultTextValue;
                        if (
                          title === "Usage" &&
                          name &&
                          (data[valueY] || data[valueY] === 0)
                        )
                          accordionData.circuits[name] = formatSizeUnitsRound(
                            data[valueY]
                          );
                      }
                    );
                    tooltipHTML = getTooltipHTMLString(reverseSeries, tooltipData, date);
                  }
                  accordionText = value;
                } else if (
                  data.fromDate &&
                  data.toDate &&
                  !props.isPathQoSdetails
                ) {
                  const displayTooltip = isDateInBetween(
                    tooltipTimestamp,
                    data.fromDate,
                    data.toDate
                  );

                  if (displayTooltip) {
                    tooltipHTML = `
                      <div class="ttip-header">
                        <span class="date">${displayDateTime(data.fromDate)}</span>
                      </div>
                    `;
                    accordionDate = data.fromDate;
                  }
                }
                if (tooltipHTML) return true;
              });

              if (tooltipHTML) {
                showChartAccordion = true;
                const text = item.yAxes.values[0].title.text;
                const accordionDataAxis = text
                  ? text.replace(/\s+/g, "")
                  : "No text";
                if (!["Circuits", "Usage"].includes(accordionDataAxis))
                  accordionData[accordionDataAxis] = accordionText;
                accordionData["date"] = accordionDate;
                item.tooltipX = e.target.point.x;
                item.tooltipY = e.target.point.y;
                item.tooltipHTML = tooltipHTML;
                item.tooltip.isActive = true;
              }
            });
          }

          if (showChartAccordion) {
            props.accordionCallbackFromParent(accordionData);
            props.hoverCallback(true);
          }
        });

        chart.events.on("out", () => {
          props.hoverCallback(false);
          if (props.showSynchronization === true && props.chartsData)
            props.chartsData.forEach(item => {
              item.tooltip.isActive = false;
              item.tooltipHTML = "";
            });
        });
      }
    });

    return chart;
  };

  useEffect(() => {
    chartsRef.current = props.chartsData;
  }, [props.chartsData]);

  useEffect(() => {
    const chart2Obj = createChart(props.id, props.data);
    currentChartRef.current = chart2Obj;
    chart2Obj.topChartRender = props.topChart;
    chart2Obj.formatTextChartRender = props.formatText;
    chart2Obj.toolTipFn = props.globalToolTipsFn;
    chart2Obj.chartType = "ganttChart";
    chart2Obj.showTipsAlways = props.showTipsAlways;
    props.callbackFromParent(chart2Obj);
    return () => chart2Obj.events.disable();
  }, [props.data]);

  return (
    <div id={props.id} className={`${css.chart} hbr-type-body4`} />
  );
};

GanttChart.propTypes = {
  id: PropTypes.string.isRequired,
  callbackFromParent: PropTypes.func,
  chartsData: PropTypes.array,
  hoverCallback: PropTypes.func,
  data: PropTypes.array,
  label: PropTypes.string,
  tooltipFn: PropTypes.func,
  disableYAxisLabel: PropTypes.bool,
  seriesHeight: PropTypes.number,
  globalToolTipsFn: PropTypes.func,
  chartHeight: PropTypes.number,
  cellStartLocation: PropTypes.number,
  cellEndLocation: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  showTipsAlways: PropTypes.bool,
  customized: PropTypes.bool,
  calcBaseIterval: PropTypes.bool,
  accordionCallbackFromParent: PropTypes.func,
  showSynchronization: PropTypes.bool,
  topChart: PropTypes.bool,
  formatText: PropTypes.bool,
  isPathQoSdetails: PropTypes.bool,
  appMetricData: PropTypes.object
};

GanttChart.defaultProps = {
  chartsData: [],
  customized: true,
  calcBaseIterval: false,
  accordionCallbackFromParent: () => null,
  callbackFromParent: () => null,
  showSynchronization: false,
  appMetricData: null
};

export default GanttChart;
