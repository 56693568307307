import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import { customizeTooltip } from "../../utils/chart";
import { generateUID } from "../../utils/common";
import { formatBytes, formatNumber } from "../../utils/format";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { colors } from "../../utils/colors";
import { defaultTextValue } from "../../utils/enums";

const defaultData = {
  total: {
    color: colors.gray90,
    size: 8
  },
  previous: {
    color: colors.rose40,
    size: 16
  },
  current: {
    color: colors.indigo60,
    size: 8
  }
};

const OverlayedBarChart = props => {
  const chartId = useRef("amchart_" + generateUID(128));

  useEffect(() => {
    // create chart
    const chart = am4core.create(chartId.current, am4charts.XYChart);
    // set chart data
    const chartData = {};
    for (const item in defaultData) chartData[item] = props.data[item]?.value;
    chart.data = [Object.assign({ yaxis: "" }, chartData)];
    // set chart style
    chart.paddingTop = 0;
    chart.paddingRight = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;

    if (props.tooltip === true) {
      // customize tooltip
      customizeTooltip(chart.tooltip);
      chart.tooltip.pointerOrientation = "right";
      chart.tooltip.dy = -25;

      // set chart tooltip html
      const { tooltipData, tooltipConfig } = props;
      const { labels, colors, type, format, decimal } = tooltipConfig;
      const getFormattedData = key => {
        const value = tooltipData[key];
        let result;
        switch (type) {
          case "bytes":
            result = `${formatBytes(value, "", decimal)
              .split(" ")
              .join(" ")}`;
            break;
          case "number":
            result = formatNumber(value, format, decimal);
            break;
          default:
            result = value;
        }
        return result
          ? result === "NaN" || result === "NaNundefined"
            ? defaultTextValue
            : result
          : defaultTextValue;
      };

      chart.tooltipHTML = `
        <div class="ttip-header">${tooltipData.name}</div>
        <table class="ttip-content">
        ${labels
          .map((item, index) => `
            <tr>
              <td>
                <div class="flex-items">
                  <span 
                    class="square-legend" 
                    style="background-color:${colors ? colors[index] : ""};"
                  ></span>
                  ${item.label}
                </div>
              </td>
              <td>${getFormattedData(item.key)}</td>
            </tr>
            `
          )
          .join("")}
        </table>
      `;
    }

    // set value axis as x axis
    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    // disable x axis labels
    xAxis.renderer.labels.template.disabled = true;
    // disable x axis grid lines
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.baseGrid.disabled = true;
    // set start and end value of x axis
    xAxis.strictMinMax = true;
    xAxis.min = 0;
    // get max from sum of all values if not provided
    xAxis.max = chartData.total ||
      ((chartData.current || 0) + (chartData.previous || 0));

    // set category axis as y axis
    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = "yaxis";
    // disable y axis title
    yAxis.title.disabled = true;
    // disable y axis labels
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.disabled = true;

    Object.keys(chartData).forEach(item => {
      if (item !== "yaxis") {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = item;
        series.dataFields.categoryY = "yaxis";
        series.columns.template.height = defaultData[item].size;
        series.columns.template.fill = am4core.color(defaultData[item].color);
        series.columns.template.strokeWidth = 0;
        series.clustered = false;
      }
    });
  }, []);

  return (
    <div
      id={chartId.current}
      className={`${css.chart} ${css["overlayed-bar-chart"]} hbr-type-body4`}
    />
  );
};

OverlayedBarChart.defaultProps = {
  data: {
    current: {
      value: 50
    },
    previous: {
      value: 60
    },
    total: {
      value: 100
    }
  },
  toolTip: false,
  tooltipData: {
    current: 50,
    previous: 60
  },
  tooltipConfig: {
    labels: [
      {
        key: "current",
        label: i18n.overlayedBarChartAvailability
      },
      { key: "previous", label: i18n.overlayedBarChartAvailable }
    ],
    type: "number",
    format: "percent"
  }
};

OverlayedBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  tooltip: PropTypes.bool,
  tooltipConfig: PropTypes.object.isRequired,
  tooltipData: PropTypes.object
};

export default OverlayedBarChart;
