import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//i18n file
import i18n from "amdi18n-loader!../nls/i18n";

//components
import reduxContext from "../../generics/AppHOC/reducer";
import AppHoc from "../../generics/AppHOC";
import SingleLegendChart from "../../common/SingleLegendChart";
import ErrorComponent from "../../common/ErrorComponent";
import Spinner from "../../common/Spinner";
import NoDataAvailable from "../../common/NoDataAvailable";
import { chartPrimaryColors } from "../../utils/colors";
//utils
import useAggregateDataApis from "./viewHooks/useCommons";
import { isDateInBetween, getRangeFourHours } from "../../utils/displayTime";

const SnailTrailChart = props => {
  const { state, getPathTraceChartData } = useAggregateDataApis();
  const [chartData, setChartData] = useState("");
  const [legendKeys, setLegendkeys] = useState([]);
  const [uniqueColor, setUniqueColor] = useState([]);
  const [snailTrailChartData, setSnailTrailChartData] = useState([]);
  const [selectedTunnelList, setSelectedTunnelList] = useState([]);
  const SNAILTRAIL_COLOR_CONFIG = chartPrimaryColors;

  useEffect(() => {
    if (props.timeFilter?.length > 0 && props.selectedTunnels?.length > 0) {
      const selectedTunnelNames = props.selectedTunnels.map(element => {
        return element.toLowerCase();
      });
      setSelectedTunnelList(selectedTunnelNames);
      getPathTraceChartData(
        selectedTunnelNames,
        props.timeFilter,
        props.selectedSiteId
      );
    }
    props.eventsListCalback("");
  }, [props.timeFilter && props.selectedTunnels]);

  useEffect(() => {
    if (
      state.isFetchingPathTraceChartDataDone &&
      !state.isFetchingPathTraceChartData
    ) {
      setSnailTrailChartData(state.pathTraceChartData);
    }
  }, [
    state.isFetchingPathTraceChartDataDone,
    state.isFetchingPathTraceChartData
  ]);

  useEffect(() => {
    setChartData([]);
    if (
      state.isFetchingPathTraceChartDataDone &&
      !state.isFetchingPathTraceChartData &&
      snailTrailChartData &&
      snailTrailChartData.data
    ) {
      let latency = [];
      let packetLoss = [];
      let date = "";
      let tunnelName = "";
      let colorData = [];
      let tunnelData = [];
      let eventIdList = [];
      let colorDataObject = [];
      snailTrailChartData.data.forEach(data => {
        let hopLatency = data._latency;
        let hopLoss = data._loss;
        tunnelName = data.tunnel_name;
        if (tunnelData.indexOf(data.tunnel_name) == -1) {
          tunnelData.push(data.tunnel_name);
        }
        let tunnelIndex = selectedTunnelList.indexOf(tunnelName);
        date = new Date(data.entry_time);
        let latencyData = { date: date };
        latencyData[tunnelName] = hopLatency;
        latencyData["eventId"] = data.event_id;
        let currentTime = props.timeFilter[1]
          ? props.timeFilter[1]
          : Date.now();
        let minTime = new Date(getRangeFourHours(currentTime));
        if (isDateInBetween(data.entry_time, minTime, currentTime)) {
          eventIdList.push(data.event_id);
        }
        let minTimestamp = minTime.getTime();
        let scrollFilterRange = [minTimestamp, currentTime];
        props.scrollBarSelectCallback(scrollFilterRange);
        latency.push(latencyData);
        let packetLossData = { date: date };
        packetLossData[tunnelName] = hopLoss;
        packetLossData["eventId"] = data.event_id;
        packetLoss.push(packetLossData);
        colorDataObject[tunnelName] = SNAILTRAIL_COLOR_CONFIG[tunnelIndex];
        colorData.push(colorDataObject);
      });

      /** * sets charts data for latency chart and packet loss chartt */
      setChartData(chartData =>
        chartData.concat({
          latency: latency,
          packetLoss: packetLoss
        })
      );
      props.colorListCalback(colorData);
      setUniqueColor(colorData);
      let pathTracequeryFields = {};
      pathTracequeryFields["eventIdList"] = eventIdList;
      props.eventsListCalback(pathTracequeryFields);

      /** @desc sets legend keys data for latency chart and packet loss chartt */
      setLegendkeys(tunnelData);
    }
  }, [snailTrailChartData]);

  const legendHideCallback = legendSelectedEvent => {
    props.legendHideCallback(legendSelectedEvent);
  };

  const legendShowCallback = legendSelectedShowEvent => {
    props.legendShowCallback(legendSelectedShowEvent);
  };

  /**
   * @desc  sets events list based on the scrollbar selection
   * @param scrollFilterRange {array}
   * @return returns events list
   */
  const scrollBarSelectCallback = scrollFilterRange => {
    props.scrollBarSelectCallback(scrollFilterRange);
    let eventIdList = [];
    snailTrailChartData.data.forEach(data => {
      if (
        isDateInBetween(
          data.entry_time,
          scrollFilterRange[0],
          scrollFilterRange[1]
        )
      ) {
        eventIdList.push(data.event_id);
      }
    });

    let pathTracequeryFields = {};
    pathTracequeryFields["eventIdList"] = eventIdList;
    props.eventsListCalback(pathTracequeryFields);
  };

  if (
    state.isFetchingPathTraceChartDataDone &&
    !state.isFetchingPathTraceChartData &&
    !state.isFetchingPathTraceChartDataError &&
    snailTrailChartData?.data?.length > 0 &&
    chartData[0]
  ) {
    return (
      <SingleLegendChart
        {...props}
        data={chartData}
        legendKeys={legendKeys}
        uniqueColor={uniqueColor}
        min={props.timeFilter[0]}
        max={props.timeFilter[1]}
        legendHideCallback={legendHideCallback}
        legendShowCallback={legendShowCallback}
        scrollBarSelectCallback={scrollBarSelectCallback}
      ></SingleLegendChart>
    );
  } else if (
    state.isFetchingPathTraceChartDataDone &&
    !state.isFetchingPathTraceChartData &&
    !state.isFetchingPathTraceChartDataError &&
    snailTrailChartData.data &&
    snailTrailChartData.data.length == 0
  ) {
    return (
      <div className="snail-trail-spinner" data-cy="snail-trail-spinner">
        <NoDataAvailable text={i18n.applicationDashboardNoData} />
      </div>
    );
  } else if (
    state.isFetchingPathTraceChartDataDone &&
    !state.isFetchingPathTraceChartData &&
    state.isFetchingPathTraceChartDataError
  ) {
    return (
      <div className="snail-trail-spinner" data-cy="snail-trail-spinner">
        <ErrorComponent
          code={"500"}
          width={"110px"}
          className={"large-dashlet-error"}
        />
      </div>
    );
  } else {
    return (
      <div className="snail-trail-spinner" data-cy="snail-trail-spinner">
        <Spinner />
      </div>
    );
  }
};

SnailTrailChart.propTypes = {
  timeFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedTunnels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCloseCallback: PropTypes.func,
  selectedSiteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  eventsListCalback: PropTypes.func,
  colorListCalback: PropTypes.func,
  legendHideCallback: PropTypes.func,
  legendShowCallback: PropTypes.func,
  scrollBarSelectCallback: PropTypes.func
};

export default reduxContext.withProvider(
  connect(null, null)(AppHoc(SnailTrailChart))
);
