import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import { binarySearchChartData } from "../../utils/common";
import { formatSizeUnitsRound } from "../../utils/format";
import {
  customizeTooltip,
  addScrollBar,
  customizeLegend,
  customizeAnomalyLegend,
  getTooltipHTMLString,
  getTimeState
} from "../../utils/chart";
import { displayDateTime, addUnitsFromDate } from "../../utils/displayTime";
import { formatBits } from "../../views/circuit360/common";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { colors, chartPrimaryColors, statusColors, anomalyColors } from "../../utils/colors";
import { charCircle, defaultTextValue } from "../../utils/enums";

let chartColors = {
  Loss: anomalyColors.loss,
  Latency: anomalyColors.latency,
  Jitter: anomalyColors.jitter
}

export const StackedMultiLineChart = props => {
  const currentChartRef = useRef();

  const createChart = (id, data) => {
    const timeState = getTimeState(props.sidebar);
    // create chart
    am4core.options.autoDispose = true;
    const chart = am4core.create(id, am4charts.XYChart);
    chart.id = id;
    // set chart data
    chart.data = data || [];
    // set chart style
    chart.paddingTop = 10;
    chart.paddingRight = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.width = am4core.percent(97);
    chart.minHeight = 150;
    // customize tooltip
    customizeTooltip(chart.tooltip);
    chart.tooltipHTML = "";

    // set single tooltip for all series at a data point
    chart.cursor = new am4charts.XYCursor();
    if (props.showSynchronization === true) chart.cursor.behavior = "none";
    chart.cursor.maxTooltipDistance = -1;

    // set date axis as x axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // set time interval for plotting graph data points
    dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
    // disable tooltip of x axis data points
    dateAxis.cursorTooltipEnabled = false;
    // set grid location from middle to start of the interval
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    // set start and end time of date axis
    dateAxis.strictMinMax = true;
    if (props.min) dateAxis.min = props.min;
    if (props.max) dateAxis.max = props.max;

    // set value axis as y axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // set y axis label
    valueAxis.title.text = props.label;
    valueAxis.title.userClassName = "y-axis-title";
    // set minimum grid distance in pixels
    valueAxis.renderer.minGridDistance = 50;
    // disable tooltip of y axis data points
    valueAxis.cursorTooltipEnabled = false;
    // set start value of y axis
    valueAxis.min = 0;

    // set no data available message
    if (chart.data.length === 0) {
      const label = chart.createChild(am4core.Label);
      // set no data text
      label.text = i18n.noData;
      // set label style
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(30);
      label.horizontalCenter = "middle";
      if (props.configurationStatus) {
        valueAxis.max = 100;
        valueAxis.renderer.minGridDistance = 200;
        // let url = ""
        const chartName =
          props.chartType || props.i18n.c360.bwUtilization;
        label.html = `
          <div class="util-no-data">
            <div>${i18n.noDataForCircuitsUtilization}</div>
            <div>${i18n.toView} ${chartName}, ${i18n.configureOnVmanage}</div>
          </div>
        `;
        // enable below when url is available
        // <a href=${url} target="_blank" rel="noopener noreferrer">${i18n.configureOnVmanage}</a></br></div>`
      }
    }

    let checkerFlag = false;

    const createSeries = (name, zIndex) => {
      // add graph line to chart
      const series = chart.series.push(new am4charts.LineSeries());
      // set graph line name
      series.name = name;
      // set x axis property name
      series.dataFields.dateX = "date";
      // set y axis property name
      series.dataFields.valueY = name;
      // customize tooltip
      customizeTooltip(series.tooltip);
      // gap > (autoGapCount * baseInterval) => gap > (60 * 1 minute)
      series.connect = false;
      series.autoGapCount = 60;
      if (dateAxis.baseInterval.timeUnit === "minute")
        series.autoGapCount = 
          timeState.interval / (60000 * dateAxis.baseInterval.count);
      // set graph line stroke style
      series.strokeWidth = 2;
      series.smoothing = "monotoneX";
      // set graph lines as stacked and set style
      if (props.stacked === true) {
        series.stacked = true;
        series.stroke = am4core.color(colors.gray100);
        series.fillOpacity = 1;
      }
      // disable tooltip transition, to display single tooltip for all series
      series.tooltip.defaultState.transitionDuration = 0;
      series.tooltip.hiddenState.transitionDuration = 0;
      series.tooltip.interpolationDuration = 0;
      // set custom property for custom sorting of legends
      if (props.order) series.zIndex = zIndex;

      if(props.id === "kpi-Usage-trend-chart") {
        series.stroke = colors["blue50"]
        series.tooltipText = `${i18n.postSpace}[bold]{date.formatDate("MMM dd, yyyy, hh:mm:ss:SSS a")}[/]\n
          [${colors["blue50"]}]${charCircle}[/] ${i18n.preSpace}${props.label}: ${i18n.preSpace}${i18n.preSpace} [bold]{valueY}[/]\n${i18n.postSpace}`;
      }

      if (props.rangeChart) {
        dateAxis.baseInterval = {
          count: 10,
          timeUnit: "second"
        };

        let units = props.label === 'Loss' ? "%" : "ms"
        series.tooltipText = `${i18n.postSpace}[bold]{date.formatDate("MMM dd, yyyy, hh:mm:ss:SSS a")}[/]\n
          [${chartColors[name]}]${charCircle}[/] ${i18n.preSpace}${props.label}: ${i18n.preSpace}${i18n.preSpace} [bold]{valueY}${units}[/]\n${i18n.postSpace}`;
        series.stroke = anomalyColors[props.label.toLowerCase()]
        const bullet = series.bullets.push(new am4charts.Bullet());
        var arrow = bullet.createChild(am4core.Triangle);
        arrow.width = 8;
        arrow.height = 8;
        arrow.horizontalCenter = "middle";
        arrow.verticalCenter = "middle";
        arrow.strokeWidth = 0;
        arrow.fill = statusColors.poor;
        arrow.rotation = 180;
        bullet.dy = -5;
        bullet.adapter.add("fillOpacity", function(fillOpacity, target) {
          var isAnomaly = target.dataItem?._dataContext?.is_anomaly
          return isAnomaly ? 1 : 0;
        });

        if (props.selectedHour) {
          let event = dateAxis.axisRanges.create();
          event.date = new Date(props.selectedHour);
          event.endDate = new Date(addUnitsFromDate(new Date(props.selectedHour), 1, "h"));
          event.grid.disabled = true;
          event.axisFill.fillOpacity = 0.1;
          event.axisFill.fill = am4core.color(colors.blue70);
        }

        const anomalyLegend = new am4charts.Legend ();
        anomalyLegend.data = [
        { name: i18n.anomalyDetected, fill: colors.gray40 }
        ];
        // customize legend
        customizeAnomalyLegend(anomalyLegend, statusColors.poor);
        anomalyLegend. parent = chart.chartContainer;
      }

      // Circuits Dashboard bandwidthChart tooltip
      if (props.isMultiLineTip) {
        if (
          props.chartType &&
          props.chartType !== props.i18n.circuit.rxUtilization &&
          props.chartType !== props.i18n.circuit.txUtilization
        ) {
          const lineSeriesArr = chart.series.values;
          checkerFlag = data.some(dataItem => {
            return lineSeriesArr.some(val => {
              if (
                Number.isFinite(dataItem[val.name]) &&
                dataItem[val.name] > 1000
              )
                return true;
            });
          });
        }
        series.stacked = false;
        series.adapter.add("tooltipHTML", (html, target) => {
          const context = target.tooltipDataItem.dataContext;
          if (context) {
            html = `
              <div class="ttip-header">
                ${props.chartType === props.i18n.circuit.rxTx
                ? props.i18n.circuit.bTitle
                : props.chartType
              }&nbsp;
                <span class="date">${displayDateTime(context.date)}</span>
              </div>
              <table class="ttip-content">
              ${chart.series.values
                .map(item =>
                  !item.isHidden
                    ? `
                    <tr>
                      <td class="ttip-bwidth">
                        <div class="flex-items">
                          <span
                            class="bar-legend"
                            style="background-color:${item.stroke.hex};"
                          ></span>
                          ${item.name}
                        </div>
                      </td>
                      <td>${props.chartType === props.i18n.circuit.rxTx
                      ? context[item.name] !== undefined
                        ? formatBits(context[item.name], undefined, 2)
                        : defaultTextValue
                      : context[item.name] !== undefined
                        ? context[item.name] + "%"
                        : defaultTextValue
                    }</td>
                    </tr>
                    `
                    : ""
                )
                .join("")}
              </table>`;
          }
          return html;
        });
      }
      if (props.order) {
        series.events.once("ready", e => {
          if (e.target.legendDataItem !== undefined)
            chart.legend.children.moveValue(
              e.target.legendDataItem.itemContainer,
              e.target.zIndex
            );
        });
      }

      return series;
    };

    if (props.colorset && Object.keys(props.colorset).length)
      Object.keys(props.colorset).forEach(key => createSeries(key));
    else if (data.length > 0) {
      let keyList = data.map(d => {
        return Object.keys(d);
      });
      // converts 2D array to 1D array and remove duplicates.
      let uniqueKeys = [...new Set(keyList.flat())];

      if (Array.isArray(props.order))
        props.order.filter(item => uniqueKeys.includes(item))
          .forEach(createSeries);
      else
        uniqueKeys.map(key => {
          if (key !== "date" && key !== "message" && key !== "is_anomaly")
            createSeries(key);
        });
    }
    // set y axis max value and label format based on maximum data value
    if (
      props.chartType &&
      props.chartType !== props.i18n.circuit.rxUtilization &&
      props.chartType !== props.i18n.circuit.txUtilization
    ) {
      if (checkerFlag === false) {
        valueAxis.max = 1000;
        valueAxis.strictMinMax = true;
        chart.yAxes.values[0].numberFormatter.numberFormat = "#.a";
      } else {
        valueAxis.min = null;
        valueAxis.logarithmic = true;
        valueAxis.treatZeroAs = 0.1;
        chart.yAxes.values[0].numberFormatter.numberFormat = "#,###.a";
      }
    }

    if(props.id === "kpi-Usage-trend-chart") {
      chart.yAxes.values[0].numberFormatter.numberFormat = "#.0b";
    }

    // add scroll bar
    if (props.scrollbar === true) addScrollBar(chart, true, props);

    // add legend to chart
    chart.legend = new am4charts.Legend();
    // customize legend
    if(props.legendPosition) {
      customizeLegend(chart.legend, props.legendPosition, false, props.stacked);
    } else {
      customizeLegend(chart.legend, true, props.stacked, props.stacked);
    }

    if (props.chartType && props.chartType !== props.i18n.circuit.rxTx) {
      valueAxis.numberFormatter.numberFormat = "#.##'%'";
      if (
        chart.series.values.length !== 0 &&
        chart.series.values.length < props.order.length
      ) {
        const noDataLegend = new am4charts.Legend();
        noDataLegend.data = [
          { name: i18n.missingCircuitsLegend, fill: colors.gray40 }
        ];
        // customize legend
        customizeLegend(noDataLegend, false, false);
        noDataLegend.parent = chart.chartContainer;
      }
    }

    chart.events.on("ready", () => {
      currentChartRef.current.isRendered = true;
      if (props.appMetricData !== null) {
        chart.cursor.events.on("cursorpositionchanged", e => {
          const xAxis = e.target.chart.xAxes.getIndex(0);
          const tooltipTimestamp = xAxis.positionToDate(
            xAxis.toAxisPosition(e.target.xPosition)
          ).valueOf();
          const accordionData = { date: 0, networkEntryDate: 0, circuits: {} };
          const networkData = props.appMetricData.network.data;
          const circuitData = props.appMetricData.circuit.usage;
          let networkEntry = null;
          let circuitEntry = null;
          if (networkData.length > 0) {
            networkEntry = binarySearchChartData(
              networkData,
              0,
              networkData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (networkEntry !== null) {
              const lastIndex = networkData.length - 1;
              if (
                networkEntry.date === networkData[lastIndex - 1].date
                && ((tooltipTimestamp - networkEntry.date)
                  > (networkData[lastIndex].date - tooltipTimestamp))
              ) networkEntry = networkData[lastIndex];
              Object.assign(accordionData, networkEntry);
              accordionData.networkEntryDate = networkEntry.date;
            }
          }
          if (circuitData.length > 0) {
            circuitEntry = binarySearchChartData(
              circuitData,
              0,
              circuitData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (circuitEntry !== null) {
              const lastIndex = circuitData.length - 1;
              if (
                circuitEntry.date === circuitData[lastIndex - 1].date
                && ((tooltipTimestamp - circuitEntry.date)
                  > (circuitData[lastIndex].date - tooltipTimestamp))
              ) circuitEntry = circuitData[lastIndex];
              Object.assign(accordionData, circuitEntry);
              for (const item of props.appMetricData.circuits)
                accordionData.circuits[item] = circuitEntry[item];
            }
          }
          if (accordionData.date === 0 && accordionData.networkEntryDate === 0)
            props.chartsData.forEach(item => {
              item.tooltip.isActive = false;
              item.tooltipHTML = "";
            });
          else {
            const dateText = displayDateTime(accordionData.networkEntryDate);
            const usageDateText = displayDateTime(accordionData.date);
            const titles = props.appMetricData.titles;
            props.chartsData.forEach(item => {
              // item.tooltipHTML = "";
              const title = item.yAxes.values[0].title.text;
              const valueY = item.yAxes.values[0].valueY;
              const tooltipData = { ...accordionData, title }
              let tooltipHTML = "";
              if (title === titles.circuits) {
                if (circuitEntry !== null)
                  tooltipHTML = `
                    <div class="ttip-header">
                      <span class="date">${usageDateText}</span>
                    </div>
                  `;
              }
              else if (
                (title === titles.usage && circuitEntry !== null)
                || (title !== titles.usage && networkEntry !== null)
              ) {
                if (title !== titles.usage) {
                  const chartUnits = item.chartUnits || "";
                  tooltipData[valueY] = tooltipData[valueY].toFixed(1) + chartUnits;
                }
                else
                  item.series.values.forEach(
                    ({ dataFields: { valueY: circuit } }) => {
                      tooltipData[circuit] =
                        Number.isFinite(accordionData[circuit])
                          ? formatSizeUnitsRound(accordionData[circuit])
                          : defaultTextValue;
                    }
                  );
                tooltipHTML = getTooltipHTMLString(
                  [...item.series.values].reverse(),
                  tooltipData,
                  title === titles.usage ? usageDateText : dateText
                );
              }

              if (tooltipHTML !== "") {
                item.tooltipX = e.target.point.x;
                item.tooltipY = e.target.point.y;
                item.tooltip.isActive = true;
                item.tooltipHTML = tooltipHTML;
              }
              else {
                item.tooltip.isActive = false;
                item.tooltipHTML = "";
              }
            });
          }
        });

        chart.events.on("out", () => {
          props.chartsData.forEach(item => {
            item.tooltip.isActive = false;
            item.tooltipHTML = "";
          });
        });
      }
    });
    return chart;
  };

  useEffect(() => {
    const getTheme = target => {
      if (target instanceof am4core.ColorSet) {
        let themeColors = chartPrimaryColors;
        if (props.colorset && Object.values(props.colorset).length > 0)
          themeColors = Object.values(props.colorset);
        target.list = themeColors.map(item => am4core.color(item));
      }
    };
    am4core.useTheme(getTheme);
    am4core.options.autoDispose = true;
    const chart2Obj = createChart(props.id, props.data);
    chart2Obj.formatTextChartRender = props.formatText;
    currentChartRef.current = chart2Obj;
    props.callbackFromParent(chart2Obj);
    return () => chart2Obj.events.disable();
  }, []);

  return (
    <div id={props.id} className={`${css.chart} hbr-type-body4`} />
  );
};

StackedMultiLineChart.propTypes = {
  id: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatText: PropTypes.bool,
  showSynchronization: PropTypes.bool,
  hoverMultiLineCharts: PropTypes.func,
  data: PropTypes.array,
  chartsData: PropTypes.array,
  label: PropTypes.string,
  showMultiLineTip: PropTypes.bool,
  callbackFromParent: PropTypes.func,
  hoverOutFromMultiLineChart: PropTypes.func,
  colorset: PropTypes.object,
  scrollbar: PropTypes.bool,
  scrollBarInitialZoom: PropTypes.bool,
  isMultiLineTip: PropTypes.bool,
  order: PropTypes.array,
  chartType: PropTypes.string,
  i18n: PropTypes.object,
  configurationStatus: PropTypes.bool,
  stacked: PropTypes.bool,
  rangeChart: PropTypes.bool,
  selectedHour: PropTypes.string,
  appMetricData: PropTypes.object,
  legendPosition: PropTypes.string,
  sidebar: PropTypes.bool
};

StackedMultiLineChart.defaultProps = {
  min: undefined,
  data: [],
  chartsData: [],
  label: "",
  showMultiLineTip: false,
  callbackFromParent: () => null,
  hoverOutFromMultiLineChart: () => null,
  colorset: null,
  scrollbar: false,
  scrollBarInitialZoom: false,
  stacked: true,
  appMetricData: null,
  sidebar: false
};

export default StackedMultiLineChart;
