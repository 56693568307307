import React from "react";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import css from "./style.less";
import PropTypes from "prop-types";
import { colors, statusColors } from "../../utils/colors";
export const SankeyChart = props => {
  // Create chart instance

  const currentChartRef = React.useRef();
  am4core.options.autoDispose = true;
  const createChart = (div, data) => {
    const nodeLinksData = props.idData;
    var chart = am4core.create(div, am4charts.SankeyDiagram);
    // Set data
    chart.data = data;
    // Configure data fields
    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";
    chart.minHeight = 200;
    chart.dataFields.color = "color";
    chart.dataFields.nameField = "name";
    chart.nodePadding = 10;
    chart.nodeMinSize = 80;
    chart.events.on("ready", () => {
      currentChartRef.current.isRendered = true;
    });
    var nodeTemplate = chart.nodes.template;
    nodeTemplate.paddingLeft = -10;
    nodeTemplate.nameLabel.locationX = 0.2;
    nodeTemplate.nameLabel.label.fill = am4core.color(colors.gray15);
    nodeTemplate.nameLabel.label.fontWeight = "bold";
    nodeTemplate.clickable = false;
    nodeTemplate.draggable = false;
    nodeTemplate.events.off("hit");
    nodeTemplate.nameLabel.label.width = 130;
    nodeTemplate.nameLabel.label.paddingRight = 20;
    nodeTemplate.nameLabel.label.truncate = true;
    nodeTemplate.propertyFields.width = "width";
    nodeTemplate.events.on("hit", function(ev) {
      /** sets the color of link once user clicks on the node */
      var dataItem = ev.target.dataItem;
      let dataContext =
        ev.target.level == 0
          ? dataItem.dataContext.from.toLowerCase()
          : dataItem.dataContext.to.toLowerCase();
      dataItem.component.links.values.forEach(data => {
        if (
          data.dataItem.dataContext.linkID &&
          nodeLinksData &&
          nodeLinksData[dataContext] &&
          nodeLinksData[dataContext].includes(data.dataItem.dataContext.linkID)
        ) {
          let color =
            dataContext.toLowerCase() == "poor"
              ? statusColors.poor
              : dataContext.toLowerCase() == "fair"
              ? statusColors.fair
              : dataContext.toLowerCase() == "good"
              ? statusColors.good
              : dataContext.toLowerCase() == "unknown"
              ? colors.gray15
              : colors.blue45;

          data.fill = am4core.color(color);
          data.fillOpacity =
            dataContext.toLowerCase() == "fair" ||
            dataContext.toLowerCase() == "poor"
              ? 0.2
              : 0.1;
        } else {
          data.fill = am4core.color(colors.gray70);
          data.fillOpacity = 0.06;
        }
      });
    });
    nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
      if (
        target &&
        target.parent &&
        target.parent.nameLabel &&
        target.parent.nameLabel.label &&
        target.parent.nameLabel.label.currentText &&
        target.parent.nameLabel.label.currentText.includes("*duplicate*")
      ) {
        let nodeLabel = target.parent.nameLabel.label.currentText.split(
          "*duplicate*"
        );
        target.parent.properties.name = target.parent.nameLabel.label.currentText.includes(
          "noLabel"
        )
          ? ""
          : nodeLabel[0];
      }

      /** sets the position of label based on level of node */
      switch (target.parent.level) {
        case 0:
          return 2;
        default:
          return -12;
      }
    });

    var linkTemplate = chart.links.template;
    linkTemplate.propertyFields.fill = "linkColor";
    linkTemplate.propertyFields.fillOpacity = "opacity";
    linkTemplate.colorMode = "solid";
    linkTemplate.propertyFields.height = 100;

    /** sets the tooltip text*/
    linkTemplate.events.on("over", function(ev) {
      let dataItem = ev.target.dataItem.dataContext;
      let nodeFromLabel = dataItem.from.split("*duplicate*")[0];
      let nodeToLabel = dataItem.to.split("*duplicate*")[0];
      if (nodeToLabel == "noLabel") {
        nodeToLabel = "SaaS";
      }
      if (nodeFromLabel == "noLabel") {
        nodeFromLabel = dataItem.from.split("*duplicate*")[1];
      }
      let tooltipText =
        nodeFromLabel + " -> " + nodeToLabel + " : " + dataItem.tooltipValue;
      let tooltipHTML = `
        <span  class=${css.tooltip}>${tooltipText}</span>
      `;
      ev.target.tooltipHTML = tooltipHTML;
    });
    var labelBullet = chart.links.template.bullets.push(
      new am4charts.LabelBullet()
    );
    labelBullet.label.propertyFields.text = "labelText";
    labelBullet.propertyFields.locationX = "0";
    labelBullet.propertyFields.rotation = "50";
    labelBullet.label.rotation = -90;
    labelBullet.propertyFields.dy = "dy";
    labelBullet.label.propertyFields.horizontalCenter = "center";
    labelBullet.label.textAlign = "top";

    return chart;
  };

  React.useEffect(() => {
    let data = props.data;
    let chart2Obj = createChart("sankeyChart", data);
    currentChartRef.current = chart2Obj;
    return () => {
      chart2Obj.events.disable();
      currentChartRef.current.dispose();
    };
  }, [props.data, window.innerWidth, window.innerHeight]);
  return (
    <React.Fragment>
      <div className={css["sankeyChart"]}>
        <div className="sankey" id={"sankeyChart"} />
      </div>
    </React.Fragment>
  );
};

SankeyChart.propTypes = {
  data: PropTypes.array,
  idData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

SankeyChart.defaultProps = {
  data: [],
  chartsData: []
};
export default SankeyChart;
