import { isPredictiveNetworksPage } from "../../utils/common";
import TimeFilter from "../../generics/TimeFilter";
import TimeStamp from "../../generics/TimeStamp";
import AppHoc from "../../generics/AppHOC";
import css from "../commonMagneticStyle.less";

const TimeStampFilterComponent = (props) => {
  return (
    <div className={css["time-filter-widget"]}>
      <div className="time-filter-widget flex-items">
        <div className="time-filter flex-items">
          {!isPredictiveNetworksPage() && <TimeFilter {...props} />}
        </div>
        {!isPredictiveNetworksPage() && <div className="header-timestamp">
          <TimeStamp {...props} />
        </div>}
      </div>
    </div>
  )
}

export default AppHoc(TimeStampFilterComponent);