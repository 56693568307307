import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import {
  customizeTooltip,
  addScrollBar,
  customizeLegend
} from "../../utils/chart";
import { getLocationRange } from "../../views/circuits/utils/circuitsTableView";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { sentimentColors, statusColors } from "../../utils/colors";

const HeatMap = props => {
  const createChart = (id, data) => {
    am4core.options.autoDispose = true;
    // create chart
    const chart = am4core.create(id, am4charts.XYChart);
    chart.maskBullets = false;
    // set chart data
    chart.data = data;
    // set chart style
    chart.width = am4core.percent(100);
    chart.minHeight = 500;
    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    chart.paddingBottom = 0;
    chart.preloader.disabled = false;
    chart.svgContainer.autoResize = true;
    chart.preloader.showOnInit = true;

    // set date axis as x axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // set time interval for plotting grid lines
    dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
    // disable x axis grid lines
    dateAxis.renderer.grid.template.disabled = true;
    // set minimum grid line distance in pixels
    dateAxis.renderer.minGridDistance = 70;
    // set grid location from middle to start of the interval
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    // set x axis label style
    dateAxis.renderer.labels.template.paddingLeft = 0;
    dateAxis.renderer.labels.template.horizontalCenter = "left";
    dateAxis.renderer.maxLabelPosition = 0.97;
    // set start and end time of date axis
    dateAxis.min = props.min;
    dateAxis.max = props.max;
    dateAxis.strictMinMax = true;

    const valueAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    // set y axis property name
    valueAxis.dataFields.category = "circuit_name";
    // disable y axis grid lines
    valueAxis.renderer.grid.template.disabled = true;
    // set minimum grid line distance in pixels
    valueAxis.renderer.minGridDistance = 20;
    // reverse the order of category in y axis
    valueAxis.renderer.inversed = true;
    // set y axis label inside the chart
    valueAxis.renderer.inside = true;
    // set y axis label style
    const label = valueAxis.renderer.labels.template;
    label.disabled = true;
    label.location = 0.0001;
    label.dy = -12;
    label.paddingLeft = 0;

    if (props.onYAxesLabelClick) {
      // set y axis label link style
      valueAxis.renderer.labels.template.fill =
        am4core.color(sentimentColors.interact);
      valueAxis.renderer.labels.template.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;
      // add click event to y axis label
      valueAxis.renderer.labels.template.events.on("hit", e => {
        const circuit = e.target.dataItem.category;
        circuit && props.onYAxesLabelClick(circuit);
      });
    }

    valueAxis.events.on("beforedatavalidated", e => {
      // get category list
      const categoryList = e.target.dataItems.values;
      categoryList.forEach(category => {
        const zIndex = props.selectedList.indexOf(category.properties.category);
        category._index = zIndex;
        // add range for the categories
        const range = valueAxis.axisRanges.create();
        range.category = category.category;
        const locationRage = getLocationRange(
          [...new Set(props.selectedList)].length
        );
        range.locations.category = locationRage[0];
        range.locations.endCategory = locationRage[1];
        // set range style
        range.axisFill.fill = am4core.color(statusColors.good);
        range.axisFill.fillOpacity = 1;
        range.grid.strokeWidth = 0;
      });
    });
    // add scroll bar
    addScrollBar(chart);

    // create legend
    const legend = new am4charts.Legend();
    legend.data = [
      { name: i18n.legendAvail, fill: am4core.color(statusColors.good) },
      { name: i18n.legendUnavail, fill: am4core.color(statusColors.poor) }
    ];
    // customize legend
    customizeLegend(legend, false);
    // add legend to chart
    legend.parent = chart.chartContainer;

    setTimeout(() => {
      // add series to chart
      const series = chart.series.push(new am4charts.ColumnSeries());
      // set series data fields
      series.dataFields.dateX = "local_entry_time";
      series.dataFields.openDateX = "local_exit_time";
      series.dataFields.value = "downtimeInMin";
      series.dataFields.categoryY = "circuit_name";
      series.sequencedInterpolation = true;
      series.showOnInit = false;
      // customize tooltip
      customizeTooltip(series.tooltip);
      series.tooltip.dy = -7;

      // set series style
      const columnTemplate = series.columns.template;
      columnTemplate.stroke = am4core.color(statusColors.poor);
      columnTemplate.fill = am4core.color(statusColors.poor);
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = 8;
      // set series range tooltip content
      columnTemplate.tooltipHTML = `
        <div>
          <div>{circuit_name}</div>
          <div>{local_entry_time}</div>
          <div>{message}</div>
        </div>
      `;
    }, 100);

    setTimeout(() => {
      chart.adapter.add("data", data => data.slice(0, 7000));
    }, 800);

    chart.invalidateData();
    return chart;
  };

  useEffect(() => {
    // create chart
    const chart2Obj = createChart(props.id, props.data);
    props.callbackFromParent(chart2Obj);
    return () => chart2Obj?.events?.disable();
  }, []);

  return <div id={props.id} className={`${css.chart} hbr-type-body4`} />;
};

HeatMap.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  selectedList: PropTypes.array.isRequired,
  onYAxesLabelClick: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  callbackFromParent: PropTypes.func
};

HeatMap.defaultProps = {
  data: [],
  onYAxesLabelClick: null
};

export default HeatMap;
