import { useState } from "react";

import * as apis from "../../../views/app360/actions";

const useAggregateDataApis = () => {
  const [pathTraceChartData, setPathTraceChartData] = useState({});
  const [
    isFetchingPathTraceChartDataDone,
    setIsFetchingPathTraceChartDataDone
  ] = useState(false);
  const [
    isFetchingPathTraceChartData,
    setIsFetchingPathTraceChartData
  ] = useState(false);
  const [
    isFetchingPathTraceChartDataError,
    setIsFetchingPathTraceChartDataError
  ] = useState(false);

  const [pathTraceGraphData, setPathTraceGraphData] = useState({});
  const [
    isFetchingPathTraceGraphDataDone,
    setIsFetchingPathTraceGraphDataDone
  ] = useState(true);
  const [
    isFetchingPathTraceGraphData,
    setIsFetchingPathTraceGraphData
  ] = useState(false);
  const [
    isFetchingPathTraceGraphDataError,
    setIsFetchingPathTraceGraphDataError
  ] = useState(false);

  const getPathTraceChartData = async (tunnels, timeFilter, selectedSiteId) => {
    setIsFetchingPathTraceChartDataDone(false);
    setIsFetchingPathTraceChartDataError(false);

    setIsFetchingPathTraceChartData(true);
    setPathTraceChartData({});
    try {
      const result = await apis.getPathTraceChartData(
        tunnels,
        timeFilter,
        selectedSiteId
      );
      const details = result.data;
      setPathTraceChartData(details);
      setIsFetchingPathTraceChartDataDone(true);
      setIsFetchingPathTraceChartData(false);
    } catch (error) {
      setIsFetchingPathTraceChartDataError(true);
      setIsFetchingPathTraceChartDataDone(true);
      setIsFetchingPathTraceChartData(false);
    }
  };

  const getPathTraceGraphData = async (
    eventsList,
    timeFilter,
    selectedSiteId
  ) => {
    setIsFetchingPathTraceGraphDataDone(false);
    setIsFetchingPathTraceGraphDataError(false);

    setIsFetchingPathTraceGraphData(true);
    setPathTraceGraphData({});
    try {
      const result = await apis.getPathTraceGraphData(
        eventsList,
        timeFilter,
        selectedSiteId
      );
      let details = result.data;
      if (typeof details == "string") {
        details = JSON.parse(details);
      }
      setPathTraceGraphData(details);
      setIsFetchingPathTraceGraphDataDone(true);
      setIsFetchingPathTraceGraphData(false);
    } catch (error) {
      setIsFetchingPathTraceGraphDataError(true);
      setIsFetchingPathTraceGraphDataDone(true);
      setIsFetchingPathTraceGraphData(false);
    }
  };

  return {
    state: {
      pathTraceChartData,
      isFetchingPathTraceChartDataDone,
      isFetchingPathTraceChartDataError,
      isFetchingPathTraceChartData,
      pathTraceGraphData,
      isFetchingPathTraceGraphDataDone,
      isFetchingPathTraceGraphData,
      isFetchingPathTraceGraphDataError
    },
    getPathTraceChartData,
    getPathTraceGraphData
  };
};
export default useAggregateDataApis;
