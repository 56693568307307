/**
* Returns a donut chart cluster based on svg
*/
export const getDonutChartCluster = (props, clusterMarkerProps) => {
  /* istanbul ignore next */
  if (!clusterMarkerProps) {
    console.error("You should provide stateColorMap for a donut chart");
  }
  const container = document.createElement("div");
  const colorMap = clusterMarkerProps.stateColorMap;
  const chart = donutChart(props, colorMap);
  container.appendChild(chart);
 
  return container;
 };
 
 const donutChart = (props, colorMap) => {
  const total = props.point_count;
  const fontSize = 16;
  const r = 30;
  const r0 = Math.round(r * 0.8);
  const w = r * 2;
 
  let html
      = `<div><svg width="${
        w
      }" height="${
        w
      }" viewbox="0 0 ${
        w
      } ${
        w
      }" text-anchor="middle" style="font: ${
        fontSize
      }px sans-serif">`;
 
  const states = Object.keys(colorMap);
 
  let isFull = false;
  states.forEach(state => {
    if (props[state] === total) {
      isFull = true;
      html += donutSegment(
        0,
        1,
        r,
        r0,
        colorMap[state]);
    }
  });
 
  if (!isFull) {
    const padding = 0.008;
    let cursor = 0;
    
    states.filter(state => props[state]).forEach(state => {
     const percent = (props[state] / total) * 100;
     const dynamicPadding = percent >= 90 ? 1.6 * padding : percent >= 70 ? 1.5 * padding : percent >= 50 ? 1.4 * padding : percent >= 25 ? 1.3 * padding : percent >= 10 ? 1.2 * padding : 0.008;
     const paddingPercent = (props[state] / total) - dynamicPadding;
     
      html += donutSegment(
        cursor,
        cursor + paddingPercent,
        r,
        r0,
        colorMap[state]);
 
      cursor += paddingPercent;
 
      html += donutSegment(
        cursor,
        cursor + padding,
        r,
        r0,
        percent > 2 ? "white" : colorMap[state]
       );
 
      cursor += padding;
    });
  }
 
  html
      += `<circle cx="${
      r
    }" cy="${
      r
    }" r="${
      r0
    }" fill="white" /><text dominant-baseline="central" transform="translate(${
      r
    }, ${
      r
    })">${
      props.point_count.toLocaleString()
    }</text></svg></div>`;
 
  const el = document.createElement("div");
  el.innerHTML = html;
  return el.firstChild;
 }
 
 const donutSegment = (start, end, r, r0, color) => {
  if (end - start === 1) {
    end -= 0.00001;
  }
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;
 
  return [
    "<path d=\"M",
    r + r0 * x0,
    r + r0 * y0,
    "L",
    r + r * x0,
    r + r * y0,
    "A",
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    "L",
    r + r0 * x1,
    r + r0 * y1,
    "A",
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    `" fill="${color}" />`
  ].join(" ");
 }

 /**
 * Returns a simple chart cluster based on svg
 */
const simpleDonutCluster = (prop, color) => {
  const fontSize = 12;
  const r = 30;
  const r0 = Math.round(r * 0.8);
  const w = r * 2;
  const countToShow = prop.threat_count > 0 ? prop.threat_count.toLocaleString() : prop.sdwan_threat_count.toLocaleString();

  let html
    = `<div><svg width="${w
    }" height="${w
    }" viewbox="0 0 ${w
    } ${w
    }" text-anchor="middle" fill="white" style="font: ${fontSize
    }px inter">`;

  html
    += `<circle cx="${r
    }" cy="${r
    }" r="${r0
    }" fill=${color} /><text dominant-baseline="central" transform="translate(${r
    }, ${r
    })">${countToShow
    }</text></svg></div>`;

  const el = document.createElement("div");
  el.innerHTML = html;
  return el.firstChild;
}

export const getSimpleCluster = (prop, color) => {
  const container = document.createElement("div");
  const chart = simpleDonutCluster(prop, color);
  container.appendChild(chart);
  return container;
};