import React from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import css from "../commonMagneticStyle.less";

const [HbrSpinner] = reactWrapper(["hbr-spinner"]);
const Spinner = () => {
  return (
    <div className={css["base-spinner"]}>
      <HbrSpinner size="large"></HbrSpinner>
    </div>
  );
};
Spinner.propTypes = {};
export default Spinner;
