import React from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import i18n from "amdi18n-loader!../nls/i18n";
import { PropTypes } from "prop-types";

const [HbrSpinner] = reactWrapper(["hbr-spinner"]);

const OverLappingSpinner = ({ extraDetailsTxt }) => {
  return (
    <div className="overlapping-spinner flex-column-full width-full">
      <HbrSpinner
        className="flex-column-full flex-items-center width-full"
        direction="column"
        size="large"
      >
        <div slot="label">{i18n.loaderText}</div>
        {extraDetailsTxt && <div slot="label">{extraDetailsTxt}</div>}
      </HbrSpinner>
    </div>
  );
};

OverLappingSpinner.propTypes = {
  extraDetailsTxt: PropTypes.string
}

export default OverLappingSpinner;
