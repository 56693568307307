import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import css from "../commonMagneticStyle.less";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import ErrorComponent from "../ErrorComponent";

function Unauthorized(props) {
  const { history } = props;

  useEffect(() => {
    if (history)
      history.replace({
        state: {
          isInValid: true
        }
      });
  }, [history]);
  return (
    <ErrorComponent code={"403"} message={i18nMessageBundle.UnauthorizedMessage} className={css["error_page_content"]}/>
  );
}

Unauthorized.propTypes = {
  history: PropTypes.object
};

export default Unauthorized;
