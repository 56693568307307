import React from "react";
import { PropTypes } from "prop-types";
import css from "../commonMagneticStyle.less";

const SectionHeading = ({ title }) => {
  return (
    <div className={css["section-heading"]}>
      <div className="hbr-type-h2">{title}</div>
    </div>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default SectionHeading;
