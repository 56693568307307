import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import css from "./style.less";
import i18n from "amdi18n-loader!../nls/i18n";
import ErrorComponent from "../ErrorComponent";
import { BASE_URL } from "../../apis/apiConstants";
import { isReportingApi } from "../../utils/common";

const PageNotFound = (props) => {
  const { history } = props;
  const message = isReportingApi() ? i18n.pageNotFound : i18n.pageNotFoundContact

  useEffect(() => {
    if (history) {
      history.replace({
        state: {
          isInValid: true
        }
      });
      
      //adding this block temporary util path /analytics will be added 
      history.push(`${BASE_URL}/notfound`)
    }
  }, [history]);

  return (
    <ErrorComponent code={"404"} message={message} className={css["error_page_content"]} />
  );
}

PageNotFound.propTypes = {
  history: PropTypes.object
};

export default PageNotFound;
