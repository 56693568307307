import { BASE_API_URL } from "../apis/apiConstants";

const devConfig = {
  baseUrl: "https://apps-idp-dev.sdwan.cisco.com",
  clientId: "0oaidaeb5h7tDSBoo1d6",
  issuer: "https://apps-idp-dev.sdwan.cisco.com/oauth2/ausj95fn5nq5grLOg1d6",
  idp: "0oauis0yi6lwAw8Z11d6"
};

const stagingConfig = {
  baseUrl: "https://apps-idp.sdwan.cisco.com",
  clientId: "0oa13o6avmpkvB3DX4x7",
  issuer: "https://apps-idp.sdwan.cisco.com/oauth2/aus13o9d9e24ejoxl4x7",
  idp: "0oa3hg4pytA8VLk7u4x7"
};

const prodConfig = {
  eu: {
    baseUrl: "https://apps-idp.sdwan.cisco.com",
    clientId: "0oa43pepbxbKQZQm94x7",
    issuer: "https://apps-idp.sdwan.cisco.com/oauth2/aus43pg34yADeZGsI4x7",
    idp: "0oa3iesofuImnUzDI4x7"
  },
  au: {
    baseUrl: "https://apps-idp.sdwan.cisco.com",
    clientId: "0oa43pjk8xuKCsaK24x7",
    issuer: "https://apps-idp.sdwan.cisco.com/oauth2/aus43psoalFR4Hzma4x7",
    idp: "0oa3iesofuImnUzDI4x7"
  },
  us_west: {
    baseUrl: "https://apps-idp.sdwan.cisco.com",
    clientId: "0oa3hglx2dTxo3MwH4x7",
    issuer: "https://apps-idp.sdwan.cisco.com/oauth2/aus3hglyk7HdqIOrb4x7",
    idp: "0oa3iesofuImnUzDI4x7"
  },
  us_east: {
    baseUrl: "https://apps-idp.sdwan.cisco.com",
    clientId: "0oa4p3hyy7vPOx1VT4x7",
    issuer: "https://apps-idp.sdwan.cisco.com/oauth2/aus4p3j003HoPY2Dv4x7",
    idp: "0oa3iesofuImnUzDI4x7"
  }
};

const customOktaConfig = {
  getLocation(windowLocation) {
    if (
      windowLocation.includes("https://us01.analytics.sdwan.cisco.com") ||
      windowLocation.includes("https://analytics-us01.sdwan.cisco.com") ||
      windowLocation.includes("https://beta.analytics.sdwan.cisco.com") ||
      windowLocation.includes("https://www.analytics.sdwan.cisco.com")
    ) {
      return "US-WEST";
    } else if (
      windowLocation.includes("https://us02.analytics.sdwan.cisco.com") ||
      windowLocation.includes("https://beta-us-east.analytics.sdwan.cisco.com")
    ) {
      return "US-EAST";
    } else if (
      windowLocation.includes("https://eu01.analytics.sdwan.cisco.com") ||
      windowLocation.includes("https://analytics-eu01.sdwan.cisco.com") ||
      windowLocation.includes("https://beta-eu.analytics.sdwan.cisco.com")
    ) {
      return "EU";
    } else if (
      windowLocation.includes("https://au01.analytics.sdwan.cisco.com") ||
      windowLocation.includes("https://beta-au.analytics.sdwan.cisco.com")
    ) {
      return "AU";
    } else if (
      windowLocation.includes("https://analytics-us-stg01.sdwan.cisco.com")
    ) {
      return "STAGING";
    } else {
      return "DEV"; // For reporting containers, Dev cluster and localhost.
    }
  },
  getOktaDynamicConfig(config) {
    const BASE = BASE_API_URL;

    const oktaConfig = {
      redirectUri: `${window.location.origin}${BASE}authorization-code/callback`,
      responseType: "code",
      pkce: false,
      display: "page",
      scopes: ["openid", "profile", "email"],
      nonce: "NONCE",
      title: {
        "primaryauth.title": "Cisco Catalyst SD-WAN Analytics",
        "primaryauth.username.placeholder":
          "My Organization ID or Existing Okta ID"
      },
      font: { "primaryauth.title": "Connectez-vous à Acme" }
    };
    oktaConfig.baseUrl = config.baseUrl;
    oktaConfig.clientId = config.clientId;
    oktaConfig.issuer = config.issuer;
    oktaConfig.idp = config.idp;
    return oktaConfig;
  },
  getOktaConfigOrBaseUrl(fullConfig, config) {
    return fullConfig
      ? this.getOktaDynamicConfig(config)
      : { baseUrl: config.baseUrl, idp: config.idp };
  },
  getRegionSpecifics(fullConfig) {
    const deploymentLocation = this.getLocation(window.location.origin);
    if (deploymentLocation === "US-WEST") {
      return this.getOktaConfigOrBaseUrl(fullConfig, prodConfig.us_west);
    } else if (deploymentLocation === "US-EAST") {
      return this.getOktaConfigOrBaseUrl(fullConfig, prodConfig.us_east);
    } else if (deploymentLocation === "EU") {
      return this.getOktaConfigOrBaseUrl(fullConfig, prodConfig.eu);
    } else if (deploymentLocation === "AU") {
      return this.getOktaConfigOrBaseUrl(fullConfig, prodConfig.au);
    } else if (deploymentLocation === "STAGING") {
      return this.getOktaConfigOrBaseUrl(fullConfig, stagingConfig);
    } else if (deploymentLocation === "DEV") {
      return this.getOktaConfigOrBaseUrl(fullConfig, devConfig);
    }
  }
};

export default customOktaConfig;
