import React from "react";
import css from "../commonMagneticStyle.less";
//import img from "../../assets/pageNotFound.svg";
import img from "../../assets/empty-state-negative.svg";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import { PropTypes } from "prop-types";

const ErrorComponent = ({
  code = 500,
  width,
  errorCode,
  message,
  imgClassName
}) => {
  return (
    <div className={css["error-page-content"]} data-cy="error-component">
      <div className="error-image">
        <img
          className={imgClassName ? imgClassName : ""}
          src={img}
          style={{ width: width }}
          alt="Failed to fetch data from API."
        />
      </div>
      <div className="dashlet-error">
        <h2>
          {i18nMessageBundle.errorComponentError}
          {errorCode ? errorCode : code}
        </h2>
        <p>
          {message && message.length < 500
            ? message
            : i18nMessageBundle.errorMessage}
        </p>
      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  code: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  errorCode: PropTypes.number,
  message: PropTypes.string,
  imgClassName: PropTypes.string
};
export default ErrorComponent;
