import React from "react";
import { PropTypes } from "prop-types";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../commonMagneticStyle.less";

const NoDataAvailable = ({ text = i18n.noData }) => {
  return (
    <div
      className={
        `${css["no-data-widget"]} flex-main-content flex-items width-full`
      }
      data-cy="no-data-widget"
    >
      <div className={"gray-box flex-items-center"}>
        <div className="no-data-text hbr-type-body2">{text}</div>
      </div>
    </div>
  );
};

NoDataAvailable.propTypes = {
  text: PropTypes.string
};

export default NoDataAvailable;
