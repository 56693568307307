import i18nMessageBundle from "amdi18n-loader!./nls/i18n";
import { BASE_URL } from "../apis/apiConstants";

const tabConfig = [
  {
    label: i18nMessageBundle.tabConfigOverview,
    url: `${BASE_URL}/overview`,
    beta: false,
    showTab: true
  },
  {
    label: i18nMessageBundle.tabConfigSites,
    url: `${BASE_URL}/sites`,
    beta: false,
    showTab: true
  },
  {
    label: i18nMessageBundle.tabConfigApplications,
    url: `${BASE_URL}/applications`,
    beta: false,
    showTab: true
  },
  {
    label: i18nMessageBundle.tabNavigationCircuits,
    url: `${BASE_URL}/circuits`,
    beta: false,
    showTab: true
  }
];
tabConfig.push({
  label: i18nMessageBundle.tabConfigPredictiveNetworks,
  url: `${BASE_URL}/predictivenetworks`,
  beta: false,
  showTab: true
});

tabConfig.push({
  label: i18nMessageBundle.tabConfigSecurity,
  url: `${BASE_URL}/threatintelligence`,
  beta: true,
  showTab: false
});

tabConfig.push({
  label: i18nMessageBundle.tabLogs,
  url: `${BASE_URL}/traffic-logs`,
  beta: true,
  showTab: true
});

export default tabConfig;
