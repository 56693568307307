import { getTimePeriodELT, shortDisplayDate24HourWithSec, getTimePeriodStartHour } from "../../utils/displayTime";


export const getTrafficPayload = ({ timePeriodSelected, devicesInput }) => {
    const timeRange = getTimePeriodELT(timePeriodSelected)
    const endDate = timeRange.current_period[1];
    const startDate = getTimePeriodStartHour(endDate, timePeriodSelected);

    return {

        "request": {
            "template_type": "traffic-logs-flows",
            "query": {
                "filters": {
                    "device_id": [...devicesInput],

                    "entry_time": {
                        "operator": "between",
                        "value": [
                            shortDisplayDate24HourWithSec(startDate),
                            shortDisplayDate24HourWithSec(endDate)
                        ]
                    }
                }
            }
        },
        "response_type": "data-frame"
    }
}
