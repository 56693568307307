import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { getDonutDefaultConfig } from "./config";

const [HbrDonutChart] = reactWrapper(["hbr-donut-chart"]);

const DonutChart = ({ height, data, centerText, modifyConfig }) => {
  const chartConfig = useMemo(() => {
    return getDonutDefaultConfig(centerText, data);
  }, [data, centerText]);

  return (
    <div className={`flex-items flex-main-content`}>
      <div className="flex-main-content">
        <HbrDonutChart
          width="calc(100% - 2px)"
          height={height}
          config={chartConfig}
          modifyHbrConfig={modifyConfig}
        />
      </div>
    </div >
  );
};

DonutChart.propTypes = {
  height: PropTypes.string,
  data: PropTypes.array,
  centerText: PropTypes.array,
  modifyConfig: PropTypes.func
};

DonutChart.defaultProps = {
  height: "250px",
  data: [],
  centerText: ["0", "Bytes"],
  modifyConfig: config => config
};

export default DonutChart;
