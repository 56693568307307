import React from "react";
import { PropTypes } from "prop-types";

//component
import Spinner from "../../Spinner";
import NoDataAvailable from "../../NoDataAvailable";
import ErrorComponent from "../../ErrorComponent";
import PathRecommendationDetails from "./PathRecommendationDetails";
import SummaryDetails from "./SummaryDetails";

//utils
import { isSiteViewedByAvailability } from "../../../utils/common";

//style
import "../../commonMagneticStyle.less";

const SummaryOverview = props => {
  const { globalFilter, countActionForAllSitesObj, app360Data } = props;
  const { siteAPIData } = globalFilter;

  if (siteAPIData?.overviewSiteError) {
    return (
      <ErrorComponent
        {...siteAPIData?.overviewSiteError}
        width={"200px"}
        className={"small-dashlet-error"}
      />
    );
  }

  if (siteAPIData?.overviewSiteLoader) {
    return (
      <div className="map-summary-loader" data-cy="spinner-componentMapView">
        <Spinner />
      </div>
    );
  } else {

    const summaryData = props.isReporting ? app360Data : siteAPIData.sitesOverviewTab;
    if (
      (summaryData &&
        summaryData.high &&
        summaryData.fair &&
        summaryData.low &&
        summaryData.gray &&
        summaryData.total_sites) ||
      (countActionForAllSitesObj && countActionForAllSitesObj.sitesLen > 0)
    ) {
      if (!isSiteViewedByAvailability(props.sitesView)) {
        return <PathRecommendationDetails {...props} />;
      } else {
        return <SummaryDetails {...props} summaryData={summaryData} isQoEMetric={props.isQoEMetric}/>;
      }
    } else {
      if (!siteAPIData.overviewSiteLoader) {
        return <NoDataAvailable />;
      }
    }
  }
};

SummaryOverview.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  summary: PropTypes.object,
  sitesView: PropTypes.string.isRequired,
  countActionForAllSitesObj: PropTypes.object,
  match: PropTypes.object,
  isReporting: PropTypes.bool,
  app360Data: PropTypes.array,
  isQoEMetric: PropTypes.bool
};

export default SummaryOverview;
