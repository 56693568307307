import React from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import NoDataAvailable from "../NoDataAvailable";
import OverlayedBarChart from "./../OverlayedBarChart";
import StackedBarChart from "./../StackedBarChart";
import ChartLegend from "./../ChartLegend";
import { getColor, getThemeColors } from "../../utils/colors";
import { isReportingApi } from "../../utils/common";
import {
  formatBytes,
  formatBits,
  formatNumber,
  formatString
} from "../../utils/format";
import timeFilterConfig from "../../generics/config/time-filter-config";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import i18n from "amdi18n-loader!../../views/nls/i18n";
import css from "../commonMagneticStyle.less";

/**
 * Dashlet Table
 * Display values and chart data together in table format
 * Config is used to format table and data
 * Data contains the values to display in table / chart
 * See defaultProps for example
 *
 * Usage:
 *      <DashletTable config={} data={}/>
 */

const [HbrTooltip, HbrLink, HbrTable, HbrIcon] = reactWrapper([
  "hbr-tooltip",
  "hbr-link",
  "hbr-table",
  "hbr-icon"
]);

const DashletTable = props => {
  const { config, data, history, timeFilterType } = props;
  const isReport = isReportingApi();

  if (!config) return null;

  const { legend, columns } = config;

  // Format value based on type
  const formatValue = (value, col) => {
    const { type, format, decimal, maxLength } = col;
    switch (type) {
      case "bits":
        return formatBits(value);
      case "bytes":
        return formatBytes(value, "", decimal);
      case "number":
        return formatNumber(value, format, decimal);
      case "string":
        return formatString(value, maxLength);
      default:
        return value;
    }
  };

  // Render value(s) using format and type
  const renderTableData = (value, row, col) => {
    const {
      format,
      delimiter,
      property,
      users,
      maxLength,
      comboUsageChange
    } = col;

    const changedValueComponent = (rowValue, formatType, type = "number") => (
      <div>
        <span>{formatValue(value, col)}</span>
        <span
          className="value-changed"
          style={{
            color: getColor(rowValue, col.theme)
          }}
        >
          {formatValue(rowValue, { type: type, format: formatType })}
        </span>
      </div>
    );

    if (comboUsageChange) {
      return changedValueComponent(
        row.usage_change_byte,
        "changeValue",
        "bytes"
      );
    }

    if (users) {
      return formatValue(value, col);
    }

    if (col.property === "vqoe_score") {
      return changedValueComponent(row.vqoe_change, "changeValue");
    }
    if (col.property === "up_percent") {
      return changedValueComponent(row.up_percent_change, "changePercent");
    }
    if (col.property === "availability") {
      let avaialbilityChange = parseInt(row.availability_change) ? row.availability_change : 0;
      return changedValueComponent(avaialbilityChange, "changePercent");
    }
    switch (format) {
      case "link":
        if (property === "application" && !isReport) {
          return (
            <div
              data-cy={value}
              onClick={() => history.push(col.path + "/" + value)}
            >
              <HbrLink target="_parent">
                {renderDeviceNameTooltip(formatValue(value, col), value)}
              </HbrLink>
            </div>
          );
        } else {
          return renderDeviceNameTooltip(
            col.onClick ? (
              <div className="dt-link" onClick={() => col.onClick(value, row)}>
                <HbrLink target="_parent">{formatValue(value, col)}</HbrLink>
              </div>
            ) : (
              formatValue(value, col)
            ),
            value
          );
        }
      case "array": {
        const appsValue = value ? value
          .map(val => formatValue(val, col))
          .join(delimiter): [];
        return value && value.length
          ? renderDeviceNameTooltip(
            !isReport ? `${appsValue.substring(0, maxLength)}...` : appsValue,
            value.toString().replaceAll(",", " | ")
          )
          : renderDeviceNameTooltip(value, value);
      }
      default:
        return formatValue(value, col);
    }
  };

  const renderDeviceNameTooltip = (baseText, tooltipText) => {
    return (
      <div>
        {!isReport && (
          <div>
            <HbrTooltip
              id="tooltip-basic"
              content={tooltipText}
              placement="top"
            >
              <div>{baseText}</div>
            </HbrTooltip>
          </div>
        )}
        {isReport && (
          <div className="hbr-type-body3">{baseText}</div>
        )}
      </div>
    );
  };

  // Render chart using format
  const renderChartData = (format, row) => {
    const { chartTooltip, chartConfig } = props;
    const { overlay, distribution, max, tooltip } = row;
    switch (format) {
      case "overlayBarChart": {
        return (
          <OverlayedBarChart
            data={overlay}
            tooltip={chartTooltip}
            tooltipData={tooltip}
            tooltipConfig={chartConfig}
          />
        );
      }
      case "stackedBarChart":
        return (
          <StackedBarChart
            data={distribution}
            max={max}
            toolTip={chartTooltip}
          />
        );
      default:
        return null;
    }
  };

  // Render chart or table data using type
  const renderData = (row, col) => {
    const { type, format, property } = col;
    const value = row[property];
    switch (type) {
      case "chart":
        return renderChartData(format, row);
      default:
        return renderTableData(value, row, col);
    }
  };

  // Render table data for each row
  const renderTableRows = (data, columns) => {
    return data.map((row, i) => {
      return (
        <tr className="table-row" key={i}>
          {renderTableCols(row, columns)}
        </tr>
      );
    });
  };

  // Render table headings (column title)
  const renderTableHeadings = columns => {
    const prevTimeLabel =
      timeFilterConfig[timeFilterType] && timeFilterConfig[timeFilterType].text;
    return (
      <tr>
        {columns.map(col => {
          const {
            title,
            colSpan,
            property,
            padding,
            width,
            tooltip,
            tooltipTitle,
            tooltipEllipse
          } = col;
          let cellPadding = padding ? padding : "0px 8px";
          let cellWidth = width ? width : null;

          if (isReport && ["Sites", "Client IP", "Availability"].includes(col.title)) {
            cellWidth = "130px";
          }

          if (isReport && ["Top used apps", "Data usage"].includes(col.title)) {
            cellWidth = "160px";
          }

          if (col.title === "Availability") {
            if (isReport) {
              cellWidth = "124px";
              cellPadding = "0 8px 0 18px";
            }
          }

          if (isReport) {
            if ([
              i18n.appViewApplication,
              i18n.clientsTopAppsColHeader,
              i18n.siteViewLocation,
              i18n.circuitViewCircuits
            ].includes(title)
            ) {
              col.maxLength = 500;
              let textCellWidth = "200px";
              if (title === i18n.clientsTopAppsColHeader) {
                textCellWidth = "450px";
              }
              cellWidth = textCellWidth;
              col.width = textCellWidth;
            }
          }

          if (colSpan) {
            if (tooltip) {
              const uniqueAnchorId = (Math.random() + 1).toString(36).substring(7);
              return (
                <th
                  className="table-header"
                  key={property}
                  colSpan={colSpan}
                  style={{ padding: cellPadding, width: cellWidth }}
                >
                  <div className="header-tip">
                    <>
                      {title}
                      <HbrIcon name="info" sentiment="neutral" id={`my-universally-unique-anchor-id${uniqueAnchorId}`}></HbrIcon>
                      <hbr-portal>
                        <HbrTooltip anchor={`#my-universally-unique-anchor-id${uniqueAnchorId}`}>
                          <div slot="content">{`${tooltipTitle} ${i18nMessageBundle.trendComparisionWith} ${prevTimeLabel}`}</div>
                        </HbrTooltip>
                      </hbr-portal>
                    </>
                  </div>
                </th>
              );
            } else {
              return (
                <th
                  className="table-header"
                  key={property}
                  colSpan={colSpan}
                  style={{ padding: cellPadding, width: cellWidth }}
                >
                  <div className="header-tip">
                    {!tooltipEllipse && title}
                    {tooltipEllipse &&
                      <HbrTooltip
                        content={i18nMessageBundle.circuitViewTotalTime}
                      >
                        {title}
                      </HbrTooltip>}
                  </div>
                </th>
              );
            }
          }
          return null;
        })}
      </tr>
    );
  };

  // Render table data for each column
  const renderTableCols = (row, columns) => {
    return columns.map(col => {
      const { property, format, theme, padding, width } = col;
      const value = row[property];
      let cellPadding = padding ? padding : "0px 8px";
      let cellWidth = width ? width : null;
      let updatedCol = col;
      let formatDiv;
      let isColorChange = true;

      if (isReport && ["Top used apps", "Data usage"].includes(col.title))
        cellWidth = "160px";

      if (col.title === "Data usage" || col.usageAutoFormat) {
        const updateFomat = formatBytes(value);
        updatedCol = {
          ...col,
          ...{ format: updateFomat.split(" ")[1], users: true }
        };
      }
      if (["Client IP"].includes(col.title)) {
        updatedCol = {
          ...col,
          ...{ format: "null" }
        };
        if (isReport)
          cellWidth = "130px";
      }

      if (col.title === "Availability" && value === "-") {
        updatedCol = {
          ...col,
          ...{ type: "" }
        };
      }

      if (col.title === "Availability") {
        if (isReport) {
          cellPadding = "0 0 0 16px";
        }
      }


      if (value === "-" && format === "changePercent") {
        formatDiv = "changePercentNone";
      } else {
        formatDiv = format;
      }

      if (
        col.property === "vqoe_score" ||
        col.property === "availability" ||
        col.property === "up_percent" ||
        col.property === "usage"
      ) {
        isColorChange = false;
      }

      return (
        <td
          key={property}
          className="table-data"
          style={{ padding: cellPadding, width: cellWidth }}
        >
          {isColorChange ? (
            <div
              className={formatDiv}
              style={{ color: getColor(value, theme) }}
            >
              {renderData(row, updatedCol)}
            </div>
          ) : (
              <div
                className={formatDiv}
              >{renderData(row, updatedCol)}</div>
            )}
        </td>
      );
    });
  };

  // Render table
  const renderTable = (data, columns) => {
    return (
      <>
        {props.isTableShown && (
          <HbrTable
            style={props.style}
            readonly
            canFocus={false}
            columns={props.columnsName}
            source={props.source}
          />
        )}
        {!props.isTableShown && (
          <table className="dash-table">
            <thead>{renderTableHeadings(columns)}</thead>
            <tbody>{renderTableRows(data, columns)}</tbody>
          </table>
        )}
      </>
    );
  };

  // Format legend data
  const formatLegendData = legend => {
    const { theme, labels } = legend;
    const colors = getThemeColors(theme);
    const timefilter = props.timeFilterType;
    return labels.map((label, index) => {
      const color = colors[index];
      const formatLabel =
        label === i18nMessageBundle.Previous && timeFilterConfig[timefilter]
          ? `${i18nMessageBundle.Previous} ${timeFilterConfig[timefilter].legendText.toLowerCase()}`
          : label;
      return { label: formatLabel, color };
    });
  };

  return (
    <div className={css["dashlet-table"]} data-cy="dashletTable">
      {data && data.length > 0 ? (
        <>
          {renderTable(data, columns)}
          {legend && (
            <div className="legend-ctr">
              <ChartLegend data={formatLegendData(legend)}></ChartLegend>
            </div>
          )}
        </>
      ) : (
          <NoDataAvailable text={i18nMessageBundle.noData} />
        )}
    </div>
  );
};

DashletTable.defaultProps = {
  config: {
    columns: [
      {
        title: i18nMessageBundle.dashletTableSites,
        colSpan: 1,
        property: "name",
        type: "string",
        format: "link"
      },
      {
        title: null,
        colSpan: 1,
        property: "chart",
        type: "chart",
        format: "overlayBarChart"
      }
    ]
  },
  data: [
    {
      name: "San Jose",
      value: 9.3,
      change: 0,
      overlay: {
        current: {
          value: 9.3
        },
        previous: {
          value: 9.3
        },
        total: {
          value: 100
        }
      }
    }
  ],
  timeFilterType: "24",
  chartTooltip: false
};

DashletTable.propTypes = {
  chartTooltip: PropTypes.bool,
  chartConfig: PropTypes.object,
  data: PropTypes.array,
  config: PropTypes.object.isRequired,
  timeFilterType: PropTypes.string,
  history: PropTypes.object,
  columnsName: PropTypes.array,
  source: PropTypes.array,
  isTableShown: PropTypes.bool,
  style: PropTypes.object
};

export default DashletTable;
