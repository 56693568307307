//Get o365 partner data exchange
export const partnerDataExchange = {
  fields: ["entry_time", "direction", "status", "message", "filename"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "last_n_hours",
        type: "date",
        value: ["24"]
      }
    ]
  },
  size: 25,
  offset: 0,
  sort: [
    {
      order: "desc",
      property: "entry_time"
    }
  ]
};

//Get o365 path score
export const pathScore = {
  fields: [
    "entry_time",
    "vdevice_id",
    "interface",
    "service_area",
    "status",
    "msft_user_qoe_score",
    "msft_latency_anomaly",
    "artcxp_cnd_anomaly",
    "artcxp_snd_anomaly",
    "artcxp_total_anomaly",
    "site_id",
    "device_host_name"
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "last_n_hours",
        type: "date",
        value: ["24"]
      }
    ]
  },
  size: 25,
  offset: 0,
  sort: [
    {
      order: "desc",
      property: "entry_time"
    },
    {
      order: "asc",
      property: "site_id"
    },
    {
      order: "asc",
      property: "vdevice_id"
    }
  ]
};

//Get o365 edge data exchange
export const edgeDataExchange = {
  fields: [
    "entry_timestamp",
    "entry_time",
    "direction",
    "status",
    "message",
    "filename"
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "last_n_hours",
        type: "date",
        value: ["24"]
      }
    ]
  },
  size: 25,
  offset: 0,
  sort: [
    {
      order: "desc",
      property: "entry_time"
    }
  ]
};

export const getTunnelConfig = {
  aggregation: {
    field: [
      { property: "tunnel_name", sequence: "1" },
      { property: "local_system_ip", sequence: "2" },
      { property: "local_color", sequence: "3" },
      { property: "remote_system_ip", sequence: "4" },
      { property: "remote_color", sequence: "5" },
      { property: "tunnel_encap", sequence: "6" },
      { property: "application", sequence: "7" },
      { property: "site_id", sequence: "8" }
    ],
    metrics: [
      { property: "vqoe_score", type: "avg" },
      { property: "octets_sum", type: "sum" },
      { property: "latency", type: "avg" },
      { property: "jitter", type: "avg" },
      { property: "loss_percentage", type: "avg" }
    ]
  },
  fields: [
    "tunnel_name",
    "local_system_ip",
    "local_color",
    "remote_system_ip",
    "remote_color",
    "tunnel_encap",
    "application",
    "site_id"
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      { field: "application", operator: "in", type: "string", value: [] },
      { field: "site_id", operator: "in", type: "string", value: [] }
    ]
  },
  size: "100",
  sort: [{ order: "desc", property: "octets_sum" }],
  type: "aggregate"
};

export const getAggregateChartData = {
  aggregation: {
    field: [{ property: "application", sequence: "1" }],
    histogram: { order: "asc", type: "hour" },
    metrics: [
      { property: "vqoe_score", type: "avg" },
      { property: "entry_time", type: "min" },
      { property: "latency", type: "avg" },
      { property: "jitter", type: "avg" },
      { property: "loss_percentage", type: "avg" }
    ]
  },
  fields: ["application"],
  size: "4000",
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "last_n_hours",
        type: "date",
        value: ["24"]
      },
      {
        field: "application",
        operator: "in",
        type: "string",
        value: []
      },
      { field: "site_id", operator: "in", type: "string", value: [] }
    ]
  },
  sort: [{ order: "asc", property: "entry_time" }]
};

export const mediaChartPayload = {
  fields: ["entry_time", "interface"],
  size: "10000",
  sort: [{ order: "desc", property: "entry_time" }],
  query: {
    condition: "AND",
    rules: [
      { field: "entry_time", operator: "between", type: "date", value: [] },
      { field: "interface", operator: "in", type: "string", value: [] },
      { field: "vdevice_id", operator: "equal", type: "string", value: [] },
      { field: "dpi_region", operator: "equal", type: "string", value: [] },
      { field: "transport_type", operator: "in", type: "string", value: [] }
    ]
  }
};

export const getPathAnalyticsMedaReqConfig = {
  fields: [
    "video_out_packet_loss",
    "video_out_frame_rate",
    "video_out_jitter",
    "video_out_latency",
    "video_out_media_bit_rate",
    "video_out_resolution_height",
    "record_time",
    "interface",
    "video_out_transport_type"
  ],
  size: "10000",
  sort: [
    {
      order: "desc",
      property: "record_time"
    }
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "record_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "interface",
        operator: "in",
        type: "string",
        value: []
      },
      {
        field: "vdevice_id",
        operator: "equal",
        type: "string",
        value: []
      },
      {
        field: "server_region",
        operator: "equal",
        type: "string",
        value: []
      },
      {
        field: "video_out_transport_type",
        operator: "in",
        type: "string",
        value: []
      }
    ]
  }
};

export const getPathAnalyticsChartReq = {
  fields: [
    "entry_time",
    "vdevice_id",
    "msft_public_ip",
    "interface",
    "service_area",
    "score",
    "status",
    "device_host_name",
    "site_id",
    "artcxp_avg_cnd",
    "artcxp_avg_cnd_baseline",
    "artcxp_avg_snd",
    "artcxp_avg_snd_baseline",
    "artcxp_avg_total",
    "artcxp_avg_total_baseline",
    "msft_user_qoe_score",
    "msft_avg_latency",
    "msft_avg_latency_baseline",
    "cxpapp_avg_loss",
    "cxpapp_avg_loss_baseline",
    "cxpapp_avg_latency",
    "cxpapp_avg_latency_baseline"
  ],
  query: {
    condition: "AND",
    rules: [
      {
        field: "site_id",
        operator: "equal",
        type: "integer",
        value: []
      },
      {
        field: "service_area",
        operator: "in",
        type: "string",
        value: [],
        name: "Service"
      },
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "device_host_name",
        operator: "in",
        value: [],
        name: "host_name"
      },
      {
        field: "interface",
        operator: "in",
        value: [],
        name: "interface"
      }
    ]
  },
  size: "10000",
  sort: [
    {
      order: "desc",
      property: "entry_time"
    }
  ]
};

export const getNetworkTelemetryReq = {
  aggregation: {
    field: [
      {
        property: "entry_datehour",
        sequence: "1"
      },
      {
        property: "ten_min_group",
        sequence: "2"
      },
      {
        property: "application_group_name",
        sequence: "3"
      },
      {
        property: "interface",
        sequence: "4"
      },
      {
        property: "local_system_ip",
        sequence: "5"
      }
    ],
    histogram: {
      order: "asc",
      type: "hour"
    },
    metrics: [
      {
        property: "loss",
        type: "avg"
      },
      {
        property: "latency",
        type: "avg"
      },
      {
        property: "entry_time",
        type: "min"
      }
    ]
  },
  fields: [
    "entry_datehour",
    "ten_min_group",
    "application_group_name",
    "interface",
    "local_system_ip"
  ],
  size: "10000",
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "application_group_name",
        operator: "in",
        type: "string",
        value: []
      },
      {
        field: "interface",
        operator: "in",
        type: "string",
        value: []
      },
      {
        field: "local_system_ip",
        operator: "equal",
        type: "string",
        value: ""
      },
      {
        field: "region",
        operator: "in",
        type: "string",
        value: []
      }
    ]
  },
  sort: [
    {
      order: "desc",
      property: "entry_time"
    }
  ]
};

export const getCircuitsChartData = {
  current_period: [],

  filter: {
    application: [],
    site_id: []
  }
};

export const getPathTraceChartRequest = {
  size: 10000,
  query: {
    condition: "AND",
    rules: [
      {
        field: "tunnel_name",
        operator: "in",
        value: ["172.20.1.1:biz-internet:172.20.2.100:biz-internet:ipsec"]
      },

      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: [1651709000000, 1651881800000]
      },
      {
        field: "local_site_id",
        operator: "in",
        value: []
      }
    ]
  },
  fields: ["entry_time", "event_id", "_loss", "_latency", "tunnel_name"],
  sort: [{ property: "entry_time", order: "desc" }]
};

export const getPathTraceGraphRequest = {
  // query = {
  size: 10000,
  query: {
    condition: "AND",
    rules: [
      {
        value: [],
        field: "event_id",
        operator: "in"
      },
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "local_site_id",
        operator: "in",
        value: []
      }
    ]
  },
  fields: [
    "local_site_id",
    "entry_time",
    "event_id",
    "topology_id",
    "tunnel_encap",
    "hop_infos",
    "local_system_ip",
    "local_color",
    "local_country",
    "local_city",
    "local_latitude",
    "local_longitude",
    "remote_system_ip",
    "remote_color",
    "remote_country",
    "remote_city",
    "remote_latitude",
    "remote_longitude",
    "_loss",
    "_latency",
    "tunnel_name",
    "remote_site_id"
  ],
  sort: [
    //{ property: "local_site_id", order: "asc" },

    { property: "entry_time", order: "desc" },
    {
      property: "event_id",
      order: "asc"
    }
  ]
};

export const getSankeyChartData = {
  current_period: [],
  filter: {
    application: [],
    site_id: []
  }
};
