import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//utils
import { statusColors } from "../../utils/colors";

const [HbrDonut] = reactWrapper(["hbr-donut"]);

const HarborDonut = ({ data, donutLabel = "label" }) => {
  const singleData = data.map((leaf, key) => ({
    id: key,
    label: leaf.title,
    value: leaf.value ? leaf.value : 0
  }));

  const config = {
    id: "label",
    data: singleData,
    margin: { top: 0, left: -10, bottom: 70 },
    cornerRadius: 1,
    activeOuterRadiusOffset: 1,
    colors: Object.values(statusColors),
    borderColor: {
      from: "color",
      modifiers: [
        ["darker", 0.8],
        ["opacity", 0.1]
      ]
    },
    arcLinkLabelsSkipAngle: 1,
    innerRadius: 0.6,
    width: 160,
    height: 200,
    startAngle: 0,
    fontSize: 16,
    text: {
      fontSize: 14
    },
    theme: {
      // background: "var(--hbr-color-success-border,#45991f)",
      // text: {
      //   fontSize: 14,
      //   fill: "var(--hbr-color-success-border,#45991f)",
      //   outlineWidth: 0,
      //   outlineColor: "transparent"
      // }
    },
    legends: []
    // legends: [
    //   {
    //     anchor: "bottom",
    //     direction: "column",
    //     justify: false,
    //     translateX: -52,
    //     translateY: 130,
    //     itemsSpacing: 24,
    //     itemWidth: 10,
    //     itemHeight: 4,
    //     itemTextColor: "var(--hbr-color-neutral-medium-text, #707070)",
    //     itemDirection: "left-to-right",
    //     itemOpacity: 1,
    //     symbolSize: 14,
    //     symbolShape: "square"
    //   }
    // ]
  };
  return (
    <HbrDonut
      config={config}
      size={"medium"}
      radialLabel={{
        text: donutLabel
      }}
    ></HbrDonut>
  );
};
HarborDonut.propTypes = {
  data: PropTypes.array,
  donutLabel: PropTypes.string
};
export default HarborDonut;
