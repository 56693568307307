import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";
import {
  addScrollBar,
  customizeTooltip,
  getTooltipHTMLString,
  getTooltipDate,
  getTimeState
} from "../../utils/chart";
import {
  getInterval,
  getLineChartDummyData,
  getTipGanttChart,
  getTipBulletColumnChart,
  getTooltipMissingData,
  getTooltipNoData,
  binarySearchChartData,
  timeUnits,
} from "../../utils/common";
import { formatSizeUnitsRound } from "../../utils/format";
import {
  subtractUnitsFromDate,
  isDateInBetween,
  displayUserExpChartTooltp,
  addUnitsFromDate,
  displayDateTime
} from "../../utils/displayTime";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { chartPrimaryColors, colors, sentimentColors } from "../../utils/colors";
import { defaultTextValue } from "../../utils/enums";

const tensionX = 0.7;

const getAxisRange = (valueAxis, qualityRange, series) => {
  const range1 = valueAxis.createSeriesRange(series);
  range1.value = qualityRange.poor.min;
  range1.endValue = qualityRange.poor.max;
  range1.contents.stroke = sentimentColors.danger;
  const range2 = valueAxis.createSeriesRange(series);
  range2.value = qualityRange.fair.min;
  range2.endValue = qualityRange.fair.max;
  range2.contents.stroke = sentimentColors.warning;
  const range3 = valueAxis.createSeriesRange(series);
  range3.value = qualityRange.good ? qualityRange.good.min : null;
  range3.endValue = qualityRange.good ? qualityRange.good.max : null;
  range3.contents.stroke = sentimentColors.success;
};

export const MultiLineChart = props => {
  const [lineChart, setLineChart] = useState();
  const currentChartRef = useRef();
  let prevValue = "";
  let scrollDisplay = false;
  let prevAboveValue = "";

  const createChart = (id, data) => {
    const timeState = getTimeState();
    let { qualityRange, min, max } = props;
    // create chart
    const chart = am4core.create(id, am4charts.XYChart);
    chart.id = id;
    // set chart data
    chart.data = data || [];
    // set chart style
    chart.paddingTop = 16;
    chart.paddingRight = 10;
    chart.paddingLeft = 0;
    chart.width = am4core.percent(100);
    chart.minHeight = 150;
    // customize tooltip
    customizeTooltip(chart.tooltip);
    chart.tooltipHTML = "";
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";

    chart.toolTipFn = props.globalToolTipsFn;
    chart.chartType = "multiLineChart";
    chart.tooltip.defaultState.transitionDuration = 100;
    chart.tooltip.hiddenState.transitionDuration = 100;

    if (props.isPathQoSdetails && props.label === "Loss (%)") {
      chart.seriesContainer.zIndex = 0;
    }

    // set date axis as x axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // set time interval for plotting graph data points
    dateAxis.baseInterval = props.showSynchronization
      ? { count: 1, timeUnit: "minute" }
      : getInterval(data);
    const timeUnit = dateAxis.baseInterval.timeUnit;
    const unitCount = dateAxis.baseInterval.count;
    chart.intervalMiliSecs =
      timeUnits.find(n => n.name === timeUnit)["timestamp"] * unitCount;
    // disable tooltip of x axis data points
    dateAxis.cursorTooltipEnabled = false;
    // set grid location from middle to start of the interval
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    // set start and end time of date axis
    dateAxis.strictMinMax = true;
    if (min) dateAxis.min = min;
    if (max) dateAxis.max = max;

    // set value axis as y axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // set y axis label
    valueAxis.title.text = props.label;
    valueAxis.title.userClassName = "y-axis-title";
    // disable tooltip of y axis data points
    valueAxis.cursorTooltipEnabled = false;
    // set start value of y axis
    valueAxis.min = 0;

    // set y axis property values
    Object.assign(valueAxis, props.valueAxisProps);

    if (props.formatText) {
      if (props.isPathQoSdetails && props.label === "Loss (%)") {
        valueAxis.numberFormatter.numberFormat = "#.00";
        valueAxis.adjustLabelPrecision = false;
      } else {
        chart.numberFormatter.numberFormat = props.numberFormat;
      }
    }

    if (chart.data.length === 0) {
      const label = chart.createChild(am4core.Label);
      // set no data text
      label.text = i18n.noData;
      // set label style
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(30);
      label.horizontalCenter = "middle";
      valueAxis.max = 100;
    }

    if (props.isPathQoSdetails && chart.data.length > 1) {
      chart.events.on("ready", () => {
        chart.zoomOutButton.disabled = true;

        props.chartsData.forEach(elem => {
          if (elem.chartType === "ganttChart") {
            //zoom in for gantt chart
            elem.xAxes._values[0].zoomToDates(
              new Date(
                subtractUnitsFromDate(data[data.length - 1].date, 1, "d")
              ),
              new Date(data[data.length - 1].date)
            );
            // zoom in for multiline charts
            dateAxis.zoomToDates(
              new Date(
                subtractUnitsFromDate(data[data.length - 1].date, 1, "d")
              ),
              new Date(data[data.length - 1].date)
            );
          }
        });
      });
    }

    const dataHasValue = props.data.some(item => {
      if (
        Object.prototype.hasOwnProperty.call(item, "date") &&
        (Object.prototype.hasOwnProperty.call(item, "value") ||
          Object.prototype.hasOwnProperty.call(item, "value0"))
      ) return true;
    });

    if (dataHasValue) {
      if (props.isPathQoSdetails) {
        let valueKeys = Object.keys(chart.data[0]);
        valueKeys.splice(valueKeys.indexOf("date"), 1);
        valueKeys.forEach((item, index) => {
          // add graph line to chart
          const series = chart.series.push(new am4charts.LineSeries());
          // set graph line name
          series.name = item;
          // set x axis property name
          series.dataFields.dateX = "date";
          // set y axis property name
          series.dataFields.valueY = item;
          // set graph line stroke style
          series.strokeWidth = 2;
          // customize tooltip
          customizeTooltip(series.tooltip);
          // set graph line color
          series.stroke = am4core.color(chartPrimaryColors[index]);
        });
      }
    }
    if ((dataHasValue && !props.isPathQoSdetails) || props.valueAxisProps.valueY) {
      // add graph line to chart
      const series = chart.series.push(new am4charts.LineSeries());
      // set graph line name
      let valueY = props.valueAxisProps.valueY || "value";
      series.name = props.seriesNames[valueY]
        ? props.seriesNames[valueY]
        : valueY;
      // set x axis property name
      series.dataFields.dateX = "date";
      // set y axis property name
      series.dataFields.valueY = valueY;
      // customize tooltip
      customizeTooltip(series.tooltip);
      // gap > (autoGapCount * baseInterval) => gap > (60 * 1 minute)
      series.connect = false;
      series.autoGapCount = 60;
      if (dateAxis.baseInterval.timeUnit === "minute")
        series.autoGapCount = 
          timeState.interval / (60000 * dateAxis.baseInterval.count);
      // set graph line stroke style
      series.strokeWidth = 2;
      // set graph line color
      series.stroke = am4core.color(colors.indigo60);
      // set series range
      if (qualityRange) getAxisRange(valueAxis, qualityRange, series);

      // set series tooltip html
      if (!props.showSynchronization) {
        series.tooltipHTML = `
          <div class="ttip-header">
            <span class="date">{dateX.format("MM/DD/YY hh:mm A")}</span>
          </div>
          <table class="ttip-content">
            <tr>
              <td>
                <div class="flex-items">
                  <span
                    class="bar-legend"
                    style="background-color:${colors.indigo60};"
                  ></span>
                  ${props.label}
                </div>
              </td>
              <td>{valueY}</td>
            </tr>
          </table>
        `;
      }
      // add scroll bar
      if (props.topChart && props.showScroll) addScrollBar(chart, true, props);
    }

    if (
      props.topChart &&
      props.showScroll &&
      !props.scrollBarInitialZoom &&
      props.axisZoom
    ) {
      chart.events.on("ready", () => {
        scrollDisplay = true;
        dateAxis.zoomToDates(
          new Date(moment(props.usageStartAxis)),
          new Date(moment(props.usageEndAxis))
        );
        dateAxis.keepSelection = true;
      });
    }

    if (
      props.formatText &&
      !props.showSynchronization &&
      chart.data.length > 0
    ) {
      const legendLength =
        props.uniqueKeysForlegend.length < 5
          ? props.uniqueKeysForlegend.length
          : 5;
      for (let index = 0; index < legendLength; index++) {
        let legendData = props.uniqueKeysForlegend[index];
        if (typeof legendData === "string")
          legendData = { field: legendData, label: legendData };
        if (!legendData.field && legendData.value) {
          legendData.field = legendData.value;
          legendData.label = legendData.value;
        }
        createSeries(legendData);
      }

      let series9 = createSeries({ field: "void", label: "Toggle All" });
      if (series9) {
        series9.events.on("hidden", function () {
          for (let i = 0; i < legendLength; i++) {
            let legendData = props.uniqueKeysForlegend[i].label;
            let series = chart.map.getKey(legendData);
            series && series.hide();
          }
        });
        series9.events.on("shown", function () {
          for (let i = 0; i < legendLength; i++) {
            let legendData = props.uniqueKeysForlegend[i].label;
            let series = chart.map.getKey(legendData);
            series && series.show();
          }
        });
      }

      chart.zoomOutButton.disabled = true;

      if (props.showScrollbarY) {
        chart.scrollbarY = new am4core.Scrollbar();

        chart.scrollbarY.minWidth = 6;

        chart.scrollbarY.startGrip.height = 15;

        chart.scrollbarY.endGrip.width = 15;

        chart.scrollbarY.endGrip.height = 15;

        chart.scrollbarY.endGrip.marginBottom = 25;
        chart.scrollbarY.startGrip.width = 15;

        chart.scrollbarY.startGrip.marginTop = -25;
        chart.scrollbarY.endGrip.background.fill = am4core.color(
          colors.indigo60
        );
        chart.scrollbarY.endGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.endGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.startGrip.background.fill = am4core.color(
          colors.indigo60
        );
        chart.scrollbarY.startGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.startGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.thumb.background.fill = am4core.color(
          colors.indigo60
        );
        chart.scrollbarY.thumb.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.thumb.background.states.getKey(
          "down"
        ).properties.fill = am4core.color(colors.indigo60);
        chart.scrollbarY.stroke = am4core.color(colors.indigo60);
      }
    }

    // Create series
    function createSeries(seriesData) {
      if (
        props.formatText &&
        !props.showSynchronization &&
        chart.data.length > 0
      ) {
        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = seriesData.field;
        series.dataFields.dateX = "date";
        series.name = seriesData.label || "";
        // customize tooltip
        customizeTooltip(series.tooltip);

        if (!props.showMultiLineTip) {
          series.tooltipHTML = `
            <div class="ttip-header">
              <span class="date">{dateX.format("MM/DD/YY hh:mm A")}</span>
            </div>
            <table class="ttip-content">
              <tr>
                <td>
                  <div class="flex-items">
                    <span
                      class="bar-legend"
                      style="background-color:${colors.indigo60};"
                    ></span>
                    ${props.label}
                  </div>
                </td>
                <td>{valueY}</td>
              </tr>
            </table>
          `;
        }

        if (seriesData.color) series.stroke = am4core.color(seriesData.color);

        series.strokeWidth = 2;
        series.tensionX = tensionX;
        series.smoothing = "monotoneX";
        series.id = seriesData.color || seriesData.label;
        return series;
      }
    }

    if (!props.isPathQoSdetails) {
      dateAxis.events.on("startchanged", dateAxisChanged);
      dateAxis.events.on("endchanged", dateAxisChanged);
    }

    function dateAxisChanged(ev) {
      if (min != ev.target.minZoomed) {
        const startAxis = ev.target.minZoomed;
        const endAxis = ev.target.maxZoomed;
        const duration =
          moment.duration(moment(endAxis).diff(moment(startAxis)));
        const hours = duration.asHours();
        props.chartsData.forEach(item => {
          if (!item.topChartRender) {
            const xAxis = item.xAxes.getIndex(0);
            xAxis.max = endAxis;
            xAxis.min = startAxis;
          }
        });

        if (scrollDisplay) {
          setTimeout(() => {
            scrollDisplay = false;
          }, 2000);
        }

        setTimeout(() => {
          if (hours <= 6 && props.usageApiCallbackFromParent) {
            if (prevValue == "") {
              props.usageApiCallbackFromParent(startAxis, endAxis, true);
              prevValue = Math.round(hours);
            }
          }
          if ((hours > 6 && !scrollDisplay) || props.scrollBarInitialZoom) {
            if (prevAboveValue == "" && props.usageApiCallbackFromParent) {
              props.usageApiCallbackFromParent(startAxis, endAxis, false);
              prevAboveValue = Math.round(hours);
            }
          }
        }, 300);
      }
    }
    chart.events.on("ready", function (ev) {
      if (props.label == "Packet loss") {
        ev.target.yAxes.logarithmic = true;
        ev.target.yAxes.values[0].min =
          ev.target.yAxes.values[0].min < 0 ? 0 : ev.target.yAxes.values[0].min;
        ev.target.yAxes.values[0].max =
          ev.target.yAxes.values[0].max + 5 < 100
            ? ev.target.yAxes.values[0].max + 5
            : ev.target.yAxes.values[0].max;
      }
      currentChartRef.current.isRendered = true;

      if (props.appMetricData !== null) {
        chart.cursor.events.on("cursorpositionchanged", e => {
          const xAxis = e.target.chart.xAxes.getIndex(0);
          const tooltipTimestamp = xAxis.positionToDate(
            xAxis.toAxisPosition(e.target.xPosition)
          ).valueOf();
          const accordionData = { date: 0, networkEntryDate: 0, circuits: {} };
          const networkData = props.appMetricData.network.data;
          const circuitData = props.appMetricData.circuit.usage;
          let networkEntry = null;
          let circuitEntry = null;
          if (networkData.length > 0) {
            networkEntry = binarySearchChartData(
              networkData,
              0,
              networkData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (networkEntry !== null) {
              const lastIndex = networkData.length - 1;
              if (
                networkEntry.date === networkData[lastIndex - 1].date
                && ((tooltipTimestamp - networkEntry.date)
                  > (networkData[lastIndex].date - tooltipTimestamp))
              ) networkEntry = networkData[lastIndex];
              Object.assign(accordionData, networkEntry);
              accordionData.networkEntryDate = networkEntry.date;
            }
          }
          if (circuitData.length > 0) {
            circuitEntry = binarySearchChartData(
              circuitData,
              0,
              circuitData.length - 1,
              tooltipTimestamp,
              timeState.interval
            );
            if (circuitEntry !== null) {
              const lastIndex = circuitData.length - 1;
              if (
                circuitEntry.date === circuitData[lastIndex - 1].date
                && ((tooltipTimestamp - circuitEntry.date)
                  > (circuitData[lastIndex].date - tooltipTimestamp))
              ) circuitEntry = circuitData[lastIndex];
              Object.assign(accordionData, circuitEntry);
              for (const item of props.appMetricData.circuits)
                accordionData.circuits[item] = circuitEntry[item];
            }
          }
          if (accordionData.date === 0 && accordionData.networkEntryDate === 0) {
            props.hoverCallback(false);
            props.chartsData.forEach(item => {
              item.tooltip.isActive = false;
              item.tooltipHTML = "";
            });
          }
          else {
            const dateText = displayDateTime(accordionData.networkEntryDate);
            const usageDateText = displayDateTime(accordionData.date);
            const titles = props.appMetricData.titles;
            props.chartsData.forEach(item => {
              // item.tooltipHTML = "";
              const title = item.yAxes.values[0].title.text;
              const valueY = item.yAxes.values[0].valueY;
              const tooltipData = { ...accordionData, title }
              let tooltipHTML = "";
              if (title === titles.circuits) {
                if (circuitEntry !== null)
                  tooltipHTML = `
                    <div class="ttip-header">
                      <span class="date">${usageDateText}</span>
                    </div>
                  `;
              }
              else if (
                (title === titles.usage && circuitEntry !== null)
                || (title !== titles.usage && networkEntry !== null)
              ) {
                if (title !== titles.usage) {
                  const chartUnits = item.chartUnits || "";
                  tooltipData[valueY] = tooltipData[valueY].toFixed(1) + chartUnits;
                }
                else
                  item.series.values.forEach(
                    ({ dataFields: { valueY: circuit } }) => {
                      tooltipData[circuit] =
                        Number.isFinite(accordionData[circuit])
                          ? formatSizeUnitsRound(accordionData[circuit])
                          : defaultTextValue;
                    }
                  );
                tooltipHTML = getTooltipHTMLString(
                  [...item.series.values].reverse(),
                  tooltipData,
                  title === titles.usage ? usageDateText : dateText
                );
              }

              if (tooltipHTML !== "") {
                item.tooltipX = e.target.point.x;
                item.tooltipY = e.target.point.y;
                item.tooltip.isActive = true;
                item.tooltipHTML = tooltipHTML;
              }
              else {
                item.tooltip.isActive = false;
                item.tooltipHTML = "";
              }
            });
            if (props.topChart === true) {
              props.accordionCallbackFromParent(accordionData);
              props.hoverCallback(true);
            }
          }
        });

        chart.events.on("out", () => {
          props.hoverCallback(false);
          props.chartsData.forEach(item => {
            item.tooltip.isActive = false;
            item.tooltipHTML = "";
          });
        });
      }
      else {
        chart.cursor.events.on("cursorpositionchanged", e => {
          if (props.showMultiLineTip)
            props.hoverMultiLineCharts?.(e, currentChartRef.current);
          if (
            props.selectedView === "tunnel" &&
            !props.showSynchronization &&
            props.formatText &&
            (props.noScroll || props.scrollBarInitialZoom)
          ) {
            const xAxis = e.target.chart.xAxes.getIndex(0);
            const duration = moment.duration(
              moment(xAxis.max).diff(moment(xAxis.min))
            );
            const hours = duration.asHours();
            if (hours <= 6 || props.scrollBarInitialZoom) {
              const tooltipDate = xAxis.positionToDate(
                xAxis.toAxisPosition(e.target.xPosition)
              );

              e.target.chart.data.forEach(data => {
                if (
                  data.date &&
                  displayDateTime(tooltipDate) ===
                  displayDateTime(data.date)
                ) {
                  const date = displayDateTime(data.date);
                  const dataKeys = Object.keys(data);
                  const value =
                    dataKeys[1] != "date"
                      ? formatSizeUnitsRound(data[dataKeys[1]])
                      : formatSizeUnitsRound(data[dataKeys[0]]);
                  e.target.chart.tooltip.isActive = true;
                  e.target.chart.tooltipHTML = `
                    <div class="ttip-header">
                      <span class="date">${date}</span>
                    </div>
                    <table class="ttip-content">
                      <tr>
                        <td>
                          <div class="flex-items">
                            <span
                              class="bar-legend"
                              style="background-color:${colors.indigo60};"
                            ></span>
                            ${props.label}
                          </div>
                        </td>
                        <td>${value}</td>
                      </tr>
                    </table>
                  `;
                  e.target.chart.tooltipX = e.target.point.x;
                  e.target.chart.tooltipY = e.target.point.y;
                }
              });
            }
          }
          if (props.showSynchronization) {
            const accordionData = { circuits: {} };
            let showChartAccordion = false;
            if (props.chartsData) {
              const xAxis = e.target.chart.xAxes.getIndex(0);
              const tooltipTimestamp = xAxis.positionToDate(
                xAxis.toAxisPosition(e.target.xPosition)
              ).valueOf();

              props.chartsData.forEach(item => {
                item.tooltipHTML = undefined;
                const chartUnits = item.chartUnits || "";
                let tooltipHTML = "";
                let accordionText = "";
                let accordionDate = "";
                if (item.data) {
                  item.data.findIndex((data, dataIdx) => {
                    let isLineChart = false;
                    let showSyncTooltip = false;
                    if (props.isPathQoSdetails) {
                      const dateStart = new Date(data.date);
                      const dateTip = new Date(tooltipTimestamp);
                      const dateEnd = new Date(data.date);

                      dateStart.setMinutes(dateStart.getMinutes() - 10);
                      dateEnd.setMinutes(dateEnd.getMinutes() + 10);
                      isLineChart =
                        data.date && dateStart < dateTip && dateTip < dateEnd;
                    } else {
                      const timestamp =
                        data.date
                          ? data.date.valueOf()
                          : 0;
                      if (getTooltipDate(dataIdx, item, timestamp, tooltipTimestamp))
                        showSyncTooltip = true;
                    }

                    if (data.fromDate) {
                      const displayTooltip = isDateInBetween(
                        tooltipTimestamp,
                        data.fromDate,
                        data.toDate
                      );

                      if (displayTooltip) {
                        if (props.isPathQoSdetails) {
                          if (data.hasBulletColumnChartData) {
                            //dont show tooltip for gantt and bullet chart when no data
                            tooltipHTML = "";
                          } else if ("currentPaths" in data) {
                            // check if there is data for gantt chart
                            if (!data.hasGanttChartData) {
                              //dont show tooltip for gantt chart when no data
                              tooltipHTML = getTooltipNoData(data);
                            } else {
                              //tooltip for gantt charts
                              tooltipHTML = getTipGanttChart(data);
                            }
                          } else {
                            // tooltip for bullet column chart
                            tooltipHTML = getTipBulletColumnChart(data);
                          }
                        } else {
                          tooltipHTML = `
                            <div class="ttip-header">
                              <span class="date">${displayDateTime(data.fromDate)}</span>
                            </div>
                          `;
                          accordionDate = data.fromDate;
                        }
                      }
                    } else if (isLineChart || showSyncTooltip) {
                      const date = displayDateTime(new Date(data.date));
                      const title = item.yAxes.values[0].title.text;
                      const reverseSeries = [...item.series.values].reverse();
                      const tooltipData = { ...data, title };
                      let value = null;

                      if (title === "Usage") accordionData.circuits = {};
                      accordionDate = date;

                      if (props.isPathQoSdetails) {
                        const valueKeys = Object.keys(chart.data[0]);

                        valueKeys.splice(valueKeys.indexOf("date"), 1);
                        value = `
                          <table class="ttip-content">
                          ${valueKeys
                            .map((key, index) => `
                              <tr>
                                <td class="ttip-bwidth">
                                  <div class="flex-items">
                                    <span
                                      class="bar-legend"
                                      style="background-color:${chartPrimaryColors[index]};"
                                    ></span>
                                    ${title}
                                  </div>
                                </td>
                                <td>${data[key] && data[key].toFixed(1)}</td>
                              </tr>
                              `
                            )
                            .join("")}
                          </table>`;
                      } else {
                        const value0 = data.value ? data.value.toFixed(0) : 0;
                        const value1 = data.value ? data.value.toFixed(1) : 0;

                        value = item.formatTextChartRender
                          ? formatSizeUnitsRound(data.value)
                          : title === "User Count"
                            ? value0
                            : value1;
                      }

                      if (props.isPathQoSdetails) {
                        if (data.value0 === 0.0014) {
                          tooltipHTML = getTooltipMissingData(
                            displayUserExpChartTooltp(
                              addUnitsFromDate(data.date, 1, "h")
                            )
                          );
                        } else {
                          tooltipHTML = `
                            <div class="ttip-header">
                              <span class="date">${date}</span>
                            </div>
                            ${value}
                          `;
                        }
                      } else {
                        item.series.values.forEach(
                          ({ name, dataFields: { valueY } }) => {
                            tooltipData[valueY] = Number.isFinite(data[valueY]) ?
                              title === "Usage" ?
                                formatSizeUnitsRound(data[valueY])
                                : data[valueY].toFixed(1) + chartUnits
                              : defaultTextValue;
                            if (
                              title === "Usage" &&
                              name &&
                              (data[valueY] || data[valueY] === 0)
                            )
                              accordionData.circuits[name] = formatSizeUnitsRound(
                                data[valueY]
                              );
                          }
                        );
                        tooltipHTML = getTooltipHTMLString(reverseSeries, tooltipData, date);
                      }
                      accordionText = value;
                    }
                    if (tooltipHTML) return true;
                  });
                }
                if (tooltipHTML) {
                  showChartAccordion = true;
                  const text = item.yAxes.values[0].title.text;
                  const accordionDataAxis = text
                    ? text.replace(/\s+/g, "")
                    : "No text";
                  if (!["Circuits", "Usage"].includes(accordionDataAxis))
                    accordionData[accordionDataAxis] = accordionText;
                  accordionData["date"] = accordionDate;
                  item.tooltipX = e.target.point.x;
                  item.tooltipY = e.target.point.y;
                  item.tooltipHTML = tooltipHTML;
                  props.hoverCallback(true);
                  if (item?.tooltip)
                    item.tooltip.isActive = true;
                }
              });
            }

            if (props.skipAccordion && showChartAccordion)
              props.accordionCallbackFromParent(accordionData);
          }
        });

        chart.events.on("out", () => {
          props.hoverCallback(false);
          if (props.showSynchronization === true && props.chartsData)
            props.chartsData.forEach(item => {
              item.tooltip.isActive = false;
              item.tooltipHTML = "";
            });
          if (props.showMultiLineTip === true)
            props.hoverOutFromMultiLineChart?.(props.chartsData);
        });
      }
    });

    return chart;
  };

  const createBaseLine = (chart, baseLineData) => {
    if (baseLineData && baseLineData.length > 0) {
      const stroke = baseLineData[0].color || "gray";
      const baseLine = chart.series.push(new am4charts.LineSeries());
      baseLine.dataFields.valueY = "value";
      baseLine.dataFields.dateX = "date";
      baseLine.strokeWidth = 2;
      baseLine.strokeDasharray = [3, 2];
      baseLine.stroke = am4core.color(stroke);
      baseLine.connect = props.seriesConnect;
      baseLine.data = baseLineData;
      return baseLine;
    }
  };

  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    am4core.options.autoDispose = true;
    const data =
      props.data && props.data.length == 1
        ? getLineChartDummyData(props.data)
        : props.data;
    const chart2Obj = createChart(props.id, data);
    chart2Obj.formatTextChartRender = props.formatText;
    chart2Obj.topChartRender = props.topChart;
    chart2Obj.noScroll = props.noScroll;
    chart2Obj.chartUnits = props.chartUnits;
    setLineChart(chart2Obj);
    currentChartRef.current = chart2Obj;
    props.callbackFromParent(chart2Obj);
    return () => chart2Obj.events.disable();
  }, [props.data]);

  useEffect(() => {
    if (lineChart) {
      if (props.baseLine && props.baseLine.length > 0)
        props.baseLine.forEach((item, index) =>
          createBaseLine(lineChart, item, index)
        );
    }
  }, [lineChart, props.baseLine]);

  useEffect(() => {
    if (lineChart) {
      if (props.enabledAmLegend) {
        lineChart.legend = new am4charts.Legend();
      } else {
        lineChart.legend = undefined;
      }
    }
  }, [props.enabledAmLegend]);

  return (
    <div
      id={props.id}
      className={`${css.chart} hbr-type-body4`}
      style={props.style}
    />
  );
};

MultiLineChart.propTypes = {
  id: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scrollBarInitialZoom: PropTypes.bool,
  qualityRange: PropTypes.object,
  formatText: PropTypes.bool,
  showSynchronization: PropTypes.bool,
  noScroll: PropTypes.bool,
  topChart: PropTypes.bool,
  showScroll: PropTypes.bool,
  axisZoom: PropTypes.bool,
  usageStartAxis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  usageEndAxis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hoverMultiLineCharts: PropTypes.func,
  selectedView: PropTypes.any,
  hoverCallback: PropTypes.func,
  accordionCallbackFromParent: PropTypes.func,
  uniqueKeysForlegend: PropTypes.any,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.array,
  chartsData: PropTypes.array,
  label: PropTypes.string,
  enabledAmLegend: PropTypes.bool,
  seriesConnect: PropTypes.bool,
  showScrollbarY: PropTypes.bool,
  baseLine: PropTypes.array,
  showMultiLineTip: PropTypes.bool,
  callbackFromParent: PropTypes.func,
  usageApiCallbackFromParent: PropTypes.func,
  globalToolTipsFn: PropTypes.func,
  hoverOutFromMultiLineChart: PropTypes.func,
  numberFormat: PropTypes.string,
  skipAccordion: PropTypes.bool,
  isPathQoSdetails: PropTypes.bool,
  valueAxisProps: PropTypes.object,
  seriesNames: PropTypes.object,
  chartUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  appMetricData: PropTypes.object
};

MultiLineChart.defaultProps = {
  min: undefined,
  scrollBarInitialZoom: false,
  data: [],
  chartsData: [],
  skipAccordion: true,
  label: "",
  enabledAmLegend: false,
  seriesConnect: true,
  showScrollbarY: false,
  baseLine: undefined,
  showMultiLineTip: false,
  callbackFromParent: () => null,
  usageApiCallbackFromParent: () => null,
  globalToolTipsFn: () => null,
  hoverOutFromMultiLineChart: () => null,
  accordionCallbackFromParent: () => null,
  hoverCallback: () => null,
  numberFormat: "#.0b",
  isPathQoSdetails: false,
  valueAxisProps: {},
  seriesNames: {},
  appMetricData: null
};

export default MultiLineChart;
