export const getTrendCircuitsAvailPayload = (
  timePeriodSelected,
  circuitsList
) => {
  const trendCircuitsChartWidget = {
    aggregation: {
      field: [
        { property: "circuit_name", sequence: "1" },
        { property: "entry_time", sequence: "2" },
        { property: "host_name", sequence: "3" }
      ],
      metrics: [{ property: "downtime", type: "sum" }]
    },
    fields: ["circuit_name", "entry_time", "host_name"],
    size: "16000",
    query: {
      condition: "AND",
      rules: [
        {
          field: "entry_time",
          operator: "between",
          type: "date",
          value: timePeriodSelected
        },
        {
          field: "circuit_name",
          operator: "in",
          type: "string",
          value: circuitsList
        }
      ]
    },
    sort: [{ order: "asc", property: "entry_time" }]
  };
  return trendCircuitsChartWidget;
};

export const getBandwidthWidgetPayloadELT = (circuitsList, globalV4Payload) => {
  const bandwidthWidget = {
    ...globalV4Payload,
    circuit_name: circuitsList,
    sort: {
      entry_ts: "asc"
    }
  }
  return bandwidthWidget;
}
