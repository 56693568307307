import i18n from "amdi18n-loader!../nls/i18n";
import { getCookie } from "../../serviceWorker";

const isUIconvergence = getCookie("cl-analytics");

export const overviewTab = [
    {
        label: i18n.vManageTabs.overview,
        url: "/overview",
        showTab: true
    }
]
export const lastTabs = [
    {
        label: i18n.vManageTabs.applications,
        url: "/applications",
        isVA: true,
        showTab: true
    },
    {
        label: i18n.vManageTabs.sites,
        url: "/sites",
        isVA: true,
        showTab: true
    },
    {
        label: i18n.vManageTabs.circuits,
        url: "/circuits",
        isVA: true,
        showTab: true
    }
]

export const devicesTab = {
    label: i18n.vManageTabs.devices,
    url: "/devices",
    showTab: true
}

export const tunnelTab = {
    label: i18n.vManageTabs.tunnels,
    url: "/tunnels",
    showTab: true
}

export const securityVmanageTab = {
    label: i18n.vManageTabs.security,
    url: "/security",
    showTab: true
}
export const multiCloudTab = {
    label: i18n.vManageTabs.multicloud,
    url: "/multicloud",
    showTab: true
}
export const logsTab = {
    label: i18n.vManageTabs.logs,
    url: isUIconvergence ? "/notfound" : "/traffic-logs",
    showTab: true,
    isVA: true
}
