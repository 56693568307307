import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import { colors } from "../../utils/colors";

/***
 * @desc  generates path styling
 * @param linkPosition {string} it defines it needs to be on top or bottom axis (+ or -)
 * @param   linkIndex {number} index of link
 * @param hasLinkExistCount {string} count of links between source and target
 */

export const getpath = (linkPosition, index, hasLinkExistCount) => {
  let linkStartIndex = index == 0 ? hasLinkExistCount * 6 : index * 6;
  linkStartIndex =
    linkPosition == "-" ? -Math.abs(linkStartIndex) : linkStartIndex;
  let bezier = {
    control: 3,
    startX:
      index > 0
        ? -10
        : -15 /* X axis start point (to the right) from source node icon */,
    startY:
      index > 0
        ? linkStartIndex
        : 0 /* Y axis start point (to the bottom) from source node icon */,
    x1: 0 /* X axis Bezier offset (to the right) from source node icon */,
    y1: 0 /* Y axis Bezier offset (to the bottom) from source node icon */,
    cp1x: 0 /* X axis offset (to the right) from first Bezier point */,
    cp1y: 0 /* Y axis offset (to the bottom) from first Bezier point */,
    cp2x: 0 /* X axis offset (to the right) from second Bezier point */,
    cp2y: 0 /* Y axis offset (to the bottom) from second Bezier point */,

    x2:
      index > 0
        ? -12 + index * 6
        : -12 /* X axis Bezier offset (to the right) from target node icon */,
    y2:
      index > 0
        ? linkStartIndex
        : 0 /* Y axis Bezier offset (to the bottom) from target node icon */,
    endX: 0,
    endY: 0,
    minDistance: 2
  };
  return bezier;
};

/***
 * @desc  Increases the value of xaxis based the the count of start node
 * @param data data from api response to create nodes
 * @param yAxisValue yAxis value where to place node
 * @param pathColor color of tunnel from chart
 * @param tunnelName name of tunnel
 * @param eventId event id for the event
 *
 */

export const getNode = (data, yAxisValue, pathColor, tunnelName, eventId) => {
  let config = {
    id: data.local_system_ip,
    label: data.local_system_ip + ":" + data.local_color,
    customParam: {
      x: 0,
      y: yAxisValue,
      height: 0,
      width: 0,
      badge: [
        {
          value: i18nMessageBundle.pathTraceLastNodeBadge + data.local_site_id,
          color: colors.gray15,
          location: [-80, 0]
        }
      ]
    },
    family: "Routers",
    additionalInfo: {
      local_city: data.local_city,
      local_country: data.local_country,
      local_latitude: data.local_latitude,
      local_longitude: data.local_longitude,
      local_system_ip: data.local_system_ip,
      local_location: data.local_city + ", " + data.local_country,
      color: pathColor,
      tunnelName: tunnelName,
      eventId: eventId,
      node_color: data.local_color
    }
  };
  return config;
};

/***
 * @desc  Increases the value of xaxis based the the count of start node
 * @param data data from api response to create nodes
 * @param hopData hop data from api response to create nodes
 * @param customX xAxis value where to place node
 * @param customY yAxis value where to place node
 * @param pathColor color of tunnel from chart
 * @param tunnelName name of tunnel
 * @param eventId event id for the event
 *
 */
export const getHop = (
  data,
  hopData,
  customX,
  customY,
  pathColor,
  tunnelName,
  eventId,
  addLatencyLoss
) => {
  let hopConfig = {
    id:
      hopData.is_last_hop == "true"
        ? hopData.ip_addr_2 + "lasthop"
        : hopData.ip_addr_2,
    label:
      hopData.is_last_hop == "true"
        ? data.remote_system_ip + ":" + data.remote_color
        : hopData.ip_addr_2,
    customParam: {
      x: customX,
      y: customY,
      height: 0,
      width: 0,
      size: 0,
      textSize: 9,
      badge:
        hopData.is_last_hop == "true"
          ? [
              {
                value:
                  i18nMessageBundle.pathTraceLastNodeBadge +
                  data.remote_site_id,
                color: colors.gray15,
                location: [80, 0]
              }
            ]
          : ""
    },
    family: "Routers",
    additionalInfo: {
      parent: hopData.ip_addr_1,
      hop_city: hopData.hop_city,
      hop_country: hopData.hop_country,
      hop_latitude: hopData.hop_latitude,
      hop_longitude: hopData.hop_longitude,
      hop_location: hopData.hop_city + ", " + hopData.hop_country,
      ip_addr_2:
        hopData.is_last_hop == "true"
          ? data.remote_system_ip
          : hopData.ip_addr_2,
      loss: hopData.loss,
      loss_data: addLatencyLoss ? [parseFloat(hopData.loss)] : [],
      min_loss: hopData.loss,
      max_loss: hopData.loss,
      avg_loss: hopData.loss,
      rtt: hopData.rtt,
      rtt_data: addLatencyLoss ? [parseFloat(hopData.rtt)] : [],
      min_rtt: hopData.rtt,
      max_rtt: hopData.rtt,
      avg_rtt: hopData.rtt,
      tunnelName: tunnelName,
      color: pathColor,
      eventId: eventId,
      lastHop: hopData.is_last_hop,
      node_color: hopData.is_last_hop == "true" ? data.remote_color : "",
      hop_carrier: hopData.is_last_hop == "true" ? "" : hopData.hop_carrier
    }
  };
  return hopConfig;
};

export const getLinkConfig = (
  data,
  hopData,
  tunnelName,
  eventId,
  showColor,
  pathColor,
  customPathConfig
) => {
  let linkStyleConfig = {
    id: hopData.ip_addr_2 + ":" + hopData.ip_addr_1 + "link" + tunnelName,
    source: hopData.ip_addr_1,
    target:
      hopData.is_last_hop == "true"
        ? hopData.ip_addr_2 + "lasthop"
        : hopData.ip_addr_2,
    customParam: {
      additionalInfo: {
        tunnelName: tunnelName,
        eventId: eventId
      },
      color: showColor ? pathColor : colors.gray70,
      thickness: showColor ? 6 : 1,
      path: {
        persistOnAdjust: true /* if false, Custom Bezier will be dropped after source/target node move */,
        left: customPathConfig,
        right: customPathConfig,
        below: customPathConfig,
        above: customPathConfig
      }
    }
  };
  return linkStyleConfig;
};

/***
 * @desc  Increases the value of xaxis based the the count of start node
 * @param uniqueTunnelValue {object} list of unique tunnel values
 * @param hopData {object}  hop data from api response to create nodes
 * @param customXPosition {number} position of xaxis
 * @param hopParentCustomParam {object} position of parent
 * @param hasSiblingCount {number} sibling count
 * @param customYValue {number} x cordinate
 * @param customXValue {number} y cordinate
 *
 */

export const getNodeAxisConfig = (
  uniqueTunnelValue,
  hopData,
  customXPosition,
  hopParentCustomParam,
  hasSiblingCount,
  customYValue,
  customXValue
) => {
  let customX = "";
  let customY = "";
  let axisArray = [];

  /* checks if parent is start node */
  if (uniqueTunnelValue.indexOf(hopData.ip_addr_1) != -1) {
    /*Checks if it has siblings*/

    /*first child of start tunnel with no siblings*/
    if (hasSiblingCount == 0) {
      customX = customXPosition;
      /*checks if it has parent axis values and creates axis values*/
      customY =
        hopParentCustomParam &&
        (hopParentCustomParam.y || hopParentCustomParam.y == 0)
          ? hopParentCustomParam.y + 0
          : customYValue * 2;
    } else {
      /* children of start tunnel with siblings*/
      /*Checks if siblings count is even or odd*/
      if (
        hasSiblingCount % 2 != 0 &&
        (hopParentCustomParam.y || hopParentCustomParam.y == 0) > 0
      ) {
        customX = customXPosition;

        /*checks if it has parent axis values and creates axis values based on sibling count*/
        customY =
          hopParentCustomParam &&
          (hopParentCustomParam.y || hopParentCustomParam.y == 0)
            ? hopParentCustomParam.y + Math.ceil(hasSiblingCount / 2) * 3
            : customYValue + hasSiblingCount * 3;
      } else {
        customX = customXPosition;

        /*checks if it has parent axis values and creates axis values based on sibling count*/
        customY =
          hopParentCustomParam &&
          (hopParentCustomParam.y || hopParentCustomParam.y == 0)
            ? hopParentCustomParam.y + Math.ceil(hasSiblingCount / 2) * -3
            : customYValue + hasSiblingCount * -3;
      }
    }
  } else {
    /*node whose parents are not start tunnel*/

    /*Checks if it has siblings*/
    if (hasSiblingCount == 0) {
      customX = customXPosition;
      customY =
        hopParentCustomParam &&
        (hopParentCustomParam.y || hopParentCustomParam.y == 0)
          ? hopParentCustomParam.y
          : 2 * customYValue;
    } else {
      // /*Checks if siblings count is even or odd*/
      let xValue = 2;
      let yValue = hasSiblingCount % 2 != 0 ? -2 : 2;
      customX =
        hopParentCustomParam &&
        (hopParentCustomParam.x || hopParentCustomParam.x == 0)
          ? hopParentCustomParam.x + xValue
          : customXValue + 2 * xValue;
      customY =
        hopParentCustomParam &&
        (hopParentCustomParam.y || hopParentCustomParam.y == 0)
          ? hopParentCustomParam.y + Math.ceil(hasSiblingCount / 2) * yValue
          : customYValue + Math.ceil(hasSiblingCount / 2) * (2 * yValue);
    }
  }
  axisArray.push(customX, customY);
  return axisArray;
};
