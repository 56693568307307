import i18nMessageBundle from "amdi18n-loader!./nls/i18n";
import _ from "lodash";

import { FEATURE_CONFIG } from "../utils/enums";
import { BASE_URL } from "../apis/apiConstants";

/* Sample navConfig with parent and child menu items

const navConfig = [
  {
    text: "Home",
    icon: "",
    path: `${appPrefix}/overview`
  },
  {
    text: "Parent Link",
    icon: "dxAccessControlPolicy",
    betaConfigName: "CIRCUITSPATH", //match the value with the enum FEATURE_CONFIG key
    submenu: [
      {
        text: "Child Link 1",
        path: "#"
      },
      {
        text: "Child Link 2",
        path: "#",
        betaConfigName: "ALTO" //match the value with the enum FEATURE_CONFIG key
      },
      {
        text: "Child Link 3",
        path: `${appPrefix}/applications`
      },
      {
        text: i18nMessageBundle.navConfigInsightsLabel,
        path: `${appPrefix}/insights`
      }
    ]
  },
]; */

/*
Proposed DNX Icons by UX
Overview: dxHome
Sites: dxSite
Applications: dxAppHosting
Circuits: dxNetwork
Predictive Networks: dxAssurance
Reports: dxReport
SD-WAN Manager: dxPageJump
*/

const navConfig = [
  {
    text: "Analytics",
    icon: "nav-insight",
    feature: "",
    path: `/`,
    submenu: [
      {
        text: i18nMessageBundle.navConfigOverview,
        icon: "home",
        feature: "",
        path: `${BASE_URL}/overview`
      },
      {
        text: i18nMessageBundle.navConfigSites,
        icon: "site",
        feature: "",
        path: `${BASE_URL}/sites`
      },
      {
        text: i18nMessageBundle.navConfigApplications,
        icon: "hosting",
        feature: "",
        path: `${BASE_URL}/applications`
      },
      {
        text: i18nMessageBundle.navConfigCircuits,
        icon: "network",
        role: "",
        feature: "circuits",
        path: `${BASE_URL}/circuits`
      },
      {
        text: i18nMessageBundle.navConfigPredictiveNetworks,
        icon: "assurance",
        feature: "wani", //feature is used to filter the object
        path: `${BASE_URL}/predictivenetworks`
      }
    ]
  },
  {
    text: i18nMessageBundle.navConfigReportsLabel,
    icon: "nav-servers",
    feature: "reports", //feature is used to filter the object
    path: `/`,
    submenu: [
      {
        text: i18nMessageBundle.reportGeneratedReports,
        icon: "nav-servers",
        path: `${BASE_URL}/reports#generated-reports`,
        feature: "reports"
      },
      {
        text: i18nMessageBundle.reportReportTemplates,
        icon: "nav-servers",
         path: `${BASE_URL}/reports#report-templates`,
         feature: "reports" 
      }
    ]
  },
  {
    text: i18nMessageBundle.navConfigCiscoSDWANCenter,
    icon: "arrow-square-out",
    feature: "",
    path: "",
    submenu: [
      {
        text: i18nMessageBundle.navConfigCiscoSDWANCenter,
        icon: "arrow-square-out"
      }
    ]
  }
];

export const getNavConfig = (isAdminUser) => {
  let navCofigLabels = [...navConfig];

  buildBetaNavConfig(navCofigLabels);

  Object.entries(navCofigLabels).forEach(([key]) => {
    if (navCofigLabels[key].text === "Predictive Networks") {
      navCofigLabels[key].path = `${BASE_URL}/predictivenetworks`;
    }
  });

  // Reports feature validation
  if (isAdminUser) {
    navCofigLabels = [...navCofigLabels];
  } else {
    navCofigLabels = _.filter(navCofigLabels, function (navItem) {
      return navItem.feature !== "reports";
    });
  }

  return navCofigLabels;
};

// Reconstruct navigation links navConfig object based on "BETA" tag applicability and validity
export const buildBetaNavConfig = defaultNavConfig => {
  Object.entries(defaultNavConfig).forEach(([key], index) => {
    // check for beta configname key and find the beta flag validity with the matching feature in FEATURE_CONFIG
    if (defaultNavConfig[key].betaConfigName !== undefined) {
      let betafeature = defaultNavConfig[key].betaConfigName;

      // Find the beta key validity in FEATURE_CONFIG and check the isBeta validity
      Object.entries(FEATURE_CONFIG).forEach(([key, value]) => {
        if (key === betafeature && value.isBeta) {
          defaultNavConfig[index].beta = true;
        }
      });
    }

    //check for any menu item submenu
    if (defaultNavConfig[key].submenu) {
      buildBetaNavConfig(defaultNavConfig[key].submenu);
    }
  });
};
