import React, { useState, useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import reduxContext from "../../generics/AppHOC/reducer";
import AppHoc from "../../generics/AppHOC";
import SnailTrailChart from "./SnailTrailChart";
import SnailTrailPathTrace from "../SnailTrailPathTrace";
import Timestamp from "../../generics/TimeStamp";

//style file
import css from "../commonMagneticStyle.less";

//i18n files
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";

const mapStateToProps = state => {
  return {
    app360: state.vanalytics.app360
  };
};

const [HbrDrawer] = reactWrapper([
  "hbr-drawer",
]);

const SnailTrail = props => {
  const [pathColorData, setColorData] = useState([]);
  const [eventsListData, setEventsListData] = useState([]);
  const [hideEvents, setHideEvents] = useState([]);
  const [showEvents, setShowEvents] = useState([]);
  const [selectedTunnelNames, setSelectedTunnelNames] = useState([]);
  const [timeFilter, setTimeFilter] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [scrollBarTimeFilter, setScrollBarTimeFilter] = useState([]);

  /** @desc returns sidebar closed value to the parent */
  const _onClose = () => {
    props.onCloseCallback(false);
  };

  useEffect(() => {
    setSelectedTunnelNames(props.selectedTunnels);
  }, [props.selectedTunnels]);

  /** @desc returns tunell name and color map to the parent */
  const colorListCalback = colorData => {
    setColorData(colorData);
  };

  /**
   * @desc  sets events list
   * @param eventsData {array}
   */
  const eventsListCalback = eventsData => {
    setEventsListData(eventsData);
  };

  /**
   * @desc  sets hide events list
   * @param legendSelectedEvent {array}
   */
  const legendHideCallback = legendSelectedEvent => {
    if (pathColorData.length > 0) {
      setHideEvents([legendSelectedEvent]);
    }
  };

  /**
   * @desc  sets show  events list
   * @param legendSelectedShowEvent {array}
   */
  const legendShowCallback = legendSelectedShowEvent => {
    if (pathColorData.length > 0 && legendSelectedShowEvent != null) {
      setShowEvents(legendSelectedShowEvent);
    }
  };

  useEffect(() => {
    if (
      props &&
      props.globalFilter &&
      props.globalFilter.sideBarTimeFilter &&
      props.globalFilter.sideBarTimeFilter.current_period
    ) {
      setSiteId(props.globalFilter.selectedSite);
      setTimeFilter(props.globalFilter.sideBarTimeFilter.current_period);
    }
  }, [props && props.globalFilter && props.globalFilter.sideBarTimeFilter]);

  /**
   * @desc sets scroll bar time range values
   * @param scrollFilterRange {array}
   */
  const scrollBarSelectCallback = scrollFilterRange => {
    setScrollBarTimeFilter(scrollFilterRange);
  };

  if (timeFilter) {
    return (
      <div className={css["snail-trail-hbr-drawer"]}>
        <HbrDrawer
          overlay={true}
          label={i18nMessageBundle.underlayDiscoverySidebarHeader}
          open
          onHbr-hide={_onClose}
        >
          <div className="timestamp-section" data-cy="snail-trail-timestamp-section">
            <Timestamp {...props} />
          </div>
          <div className="snail-trail-chart-graph-section" data-cy="snail-trail-chart-graph-section">
            <div className="snail-trail-chart" data-cy="snail-trail-chart">
              <SnailTrailChart
                {...props}
                selectedTunnels={selectedTunnelNames}
                showLatencyChart={"true"}
                showPacketLossChart={"true"}
                colorListCalback={colorListCalback}
                eventsListCalback={eventsListCalback}
                legendHideCallback={legendHideCallback}
                legendShowCallback={legendShowCallback}
                timeFilter={timeFilter}
                selectedSiteId={siteId}
                scrollBarSelectCallback={scrollBarSelectCallback}
              ></SnailTrailChart>
            </div>
            <div className="path-trace" data-cy="snail-trail-graph">
              {pathColorData.length > 0 && (
                <SnailTrailPathTrace
                  colorData={pathColorData}
                  eventsListData={eventsListData}
                  hideEvents={hideEvents}
                  showEvents={showEvents}
                  timeFilter={timeFilter}
                  selectedSiteId={siteId}
                  scrollBarTimeFilter={scrollBarTimeFilter}
                ></SnailTrailPathTrace>
              )}
            </div>
          </div>
        </HbrDrawer>
      </div>
    );
  }
};

SnailTrail.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  selectedTunnels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCloseCallback: PropTypes.func
};

export default reduxContext.withProvider(
  connect(mapStateToProps, null)(AppHoc(SnailTrail))
);
