import {
  currentDateFormat, subtractUnitsFromDate, addUnitsFromDate,
  formatDate, shortDisplayDate24HourWithSec, getStartOfHour} from "../../utils/displayTime";
import { convertedKpiName } from "../../views/insights/utils/index"

export const payloadADTable = (currentDate) => {
  let epochStartTime = currentDateFormat(currentDate)
    let payload =
    {
      entry_ts: shortDisplayDate24HourWithSec(formatDate(epochStartTime).utc()),
      fields: [
        "entry_ts",
        "loss_percentage_anomaly_cnt",
        "loss_percentage_anomaly_tunnel_cnt",
        "loss_percentage_anomaly_site_cnt",
        "loss_percentage_anomaly_percentage_record",
        "latency_anomaly_cnt",
        "latency_anomaly_tunnel_cnt",
        "latency_anomaly_site_cnt",
        "latency_anomaly_percentage_record",
        "jitter_anomaly_cnt",
        "jitter_anomaly_tunnel_cnt",
        "jitter_anomaly_site_cnt",
        "jitter_anomaly_percentage_record",
        "inference_tunnel_cnt",
        "total_tunnel_count",
      ],
    }
      return payload;
};

export const topTunnelADTable = (currentDate) => {
  let epochStartTime = currentDateFormat(currentDate)
    let payload =
    {
      entry_ts: shortDisplayDate24HourWithSec(formatDate(epochStartTime).utc()),
      fields: [
        "entry_ts",
        "topx_tunnel_usage_loss_percentage_anomaly_cnt",
        "topx_tunnel_usage_loss_percentage_anomaly_tunnel_cnt",
        "topx_tunnel_usage_loss_percentage_anomaly_site_cnt",
        "topx_tunnel_usage_loss_percentage_anomaly_percentage_record",
        "topx_tunnel_usage_latency_anomaly_cnt",
        "topx_tunnel_usage_latency_anomaly_tunnel_cnt",
        "topx_tunnel_usage_latency_anomaly_site_cnt",
        "topx_tunnel_usage_latency_anomaly_percentage_record",
        "topx_tunnel_usage_jitter_anomaly_cnt",
        "topx_tunnel_usage_jitter_anomaly_tunnel_cnt",
        "topx_tunnel_usage_jitter_anomaly_site_cnt",
        "topx_tunnel_usage_jitter_anomaly_percentage_record",
        "topx_tunnel_usage_tunnel_count",
        "total_tunnel_count"
      ],
    }
      return payload;
};

export const payloadKPITable = (currentDate) => {
  let epochStartTime = currentDateFormat(currentDate)
  let payload = {
    "entry_ts": shortDisplayDate24HourWithSec(formatDate(epochStartTime).utc()),
    "size": 30,
    "offset": 0,
    "sort": {
      "app_usage": "desc"
    }
  };

    return payload;
};

export const topUsageKPITable = (currentDate) => {
  let epochStartTime = currentDateFormat(currentDate)
  let payload = {
    "entry_ts": shortDisplayDate24HourWithSec(formatDate(epochStartTime).utc()),
    "topx_tunnel_usage_flag": true,
    "size": 30,
    "offset": 0,
    "sort": {
      "app_usage": "desc"
    }
  };

    return payload;
};

export const elt_anomaly_payload = (timePeriod) => {
  let eltPayload = {
    "entry_ts": {
      "start": timePeriod[0],
      "end": timePeriod[1]
    },
    "fields": [
      "entry_ts",
      "loss_percentage_anomaly_percentage_record",
      "latency_anomaly_percentage_record",
      "jitter_anomaly_percentage_record"
    ],
    "sort": {
      "entry_ts": "asc"
    }
  }

  return eltPayload;
}

export const top_usage_ad_payload = (timePeriod) => {
  let eltPayload = {
    "entry_ts": {
      "start": timePeriod[0],
      "end": timePeriod[1]
    },
    "fields": [
      "entry_ts",
      "topx_tunnel_usage_latency_anomaly_percentage_record",
      "topx_tunnel_usage_loss_percentage_anomaly_percentage_record",
      "topx_tunnel_usage_jitter_anomaly_percentage_record"
    ],
    "sort": {
      "entry_ts": "asc"
    }
  }

  return eltPayload;
}

export const elt_kpiTablePayload = (selectedDate, usageType) => {
  let current_period_elt = usageType === "topTunnel"
  ? topUsageKPITable(selectedDate)
  : payloadKPITable(selectedDate)
  return current_period_elt;

}

export const elt_kpiTrendPayload = (currentDate, key) => {
  let epochStartTime = subtractUnitsFromDate(currentDate, 6, "d").valueOf()
  let epochEndTime = addUnitsFromDate(currentDate, 24, "hours").valueOf()
  let currentTime = getStartOfHour()
  let endTime = epochEndTime > currentTime ? currentTime : epochEndTime
  let kpi_name = convertedKpiName[key[0]]
  if (epochStartTime & endTime) {
    let payload = {
      "fields": [
        "entry_ts",
        kpi_name,
        `${kpi_name}_anomaly`,
        "app_usage",
        "display_app_usage"
      ],
      "entry_ts": {
        "start":shortDisplayDate24HourWithSec(formatDate(epochStartTime).utc()),
        "end": shortDisplayDate24HourWithSec(formatDate(endTime).utc())
      },
      "tunnel_name": key[1],
      "sort": {
        "entry_ts": "asc"
      }
    };
    return payload;
  }
}
