import {
  mapToRange,
  percentRange,
  getOverlayRange,
  getOverlayData,
  getDistributionTotal
} from "../../utils/api-parsers";

export const parseSiteSummary = data => {
  let updatedData = {};

  if (data) {
    updatedData["summary"] = data["summary"];
    updatedData["detail"] = [];

    data["detail"] &&
      data["detail"].forEach(detailObject => {
        const { site_name, city, up_percent, up_percent_change } = detailObject;

        const previous = up_percent - up_percent_change;
        // TODO: change total based on filter?
        const total = 100;

        updatedData.detail.push({
          site_name,
          city,
          up_percent,
          up_percent_change,
          tooltip: {
            name: site_name,
            current: up_percent,
            previous: previous
          },
          overlay: getOverlayData(up_percent, previous, total)
        });
      });
  }

  return updatedData;
};

export const parseApplSummary = data => {
  let updatedData = {};

  if (data) {
    updatedData["summary"] = data["summary"];
    updatedData["detail"] = [];

    let distTotals = [];

    data["detail"] &&
      data["detail"].forEach(detailObject => {
        const { bad_count, fair_count, good_count, gray_count } = detailObject;
        const distData = [bad_count, fair_count, good_count, gray_count];
        distTotals.push(getDistributionTotal(distData));
      });

    const max = Math.max(...distTotals);
    //const max = null;

    data["detail"] &&
      data["detail"].forEach((detailObject, index) => {
        const {
          application,
          vqoe_score,
          vqoe_change,
          bad_count,
          fair_count,
          good_count,
          gray_count
        } = detailObject;

        updatedData.detail.push({
          application,
          vqoe_score,
          vqoe_change,
          max,
          distributionTotal: distTotals[index],
          distribution: {
            poor: bad_count,
            fair: fair_count,
            good: good_count,
            gray: gray_count
          }
        });
      });
  }

  return updatedData;
};

export const parseUserSummary = data => {
  data = {"usage": data['data']};
  let updatedData = {};

  if (data) {
    updatedData["usage"] = [];
    const overlayRange = getOverlayRange(data);

    data["usage"] &&
      data["usage"].forEach(detailObject => {
        const {
          site_name,
          source_ip,
          top_used_apps,
          usage,
          previous_usage
        } = detailObject;
        const curr = usage;
        const prev = previous_usage;

        const current = mapToRange(overlayRange, percentRange, curr);
        const previous = mapToRange(overlayRange, percentRange, prev);
        const total = null;
        const usage_change_byte = usage - previous_usage;

        updatedData.usage.push({
          site_name,
          source_ip,
          top_used_apps,
          usage,
          usage_change_byte,
          tooltip: {
            name: source_ip,
            current: curr,
            previous: prev
          },
          overlay: getOverlayData(current, previous, total)
        });
      });
  }

  return updatedData;
};

export const prepareEntryData = () => {};
