import React, { useRef, useEffect, useState } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import ReactDOM from "react-dom";
import { GeoMap } from "@cisco/geomaps";
import { PropTypes } from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//i18n files
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";

//utils
import {
  formatNumberNDecimalPlaces,
  formatBytes,
  formatString
} from "../../utils/format";
import { getColor, colors } from "../../utils/colors";
import {
  isRecommendationPage,
  isApplyRevertRecommendation
} from "../../utils/waniCommon";
import {
  validateSitesLocation,
  setLocalStorageFlag,
  getLocalStorageFlag
} from "../../utils/common";
import { getIcon } from "../../utils/icons";
import { BASE_URL } from "../../apis/apiConstants";
import { randomUUID } from "../../utils/common";
import { getSimpleCluster, getDonutChartCluster } from "./renderCluster";

//style
import css from "../commonMagneticStyle.less";

//components
import AppHOC from "../../generics/AppHOC";
import NoDataAvailable from "../NoDataAvailable";

//reducers
import * as actions from "../../views/applications/actions";
import reduxContext from "../../views/applications/reducer";
import SdwanSidebar from "../../views/security/SecurityComponent/SdwanComponent/SdwanSidebar";
const mapStateToProps = state => {
  return {
    applications: state.vanalytics.applications
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const [HbrDrawer, HbrBadge] = reactWrapper(["hbr-drawer", "hbr-badge"]);

const GeoMapComponent = props => {
  const { sites, options, sitesView, isAvailabilityScore } = props;
  const { appGroup, selectedSite, currentTimeStamp } = props.globalFilter;
  const appClassLabels = JSON.parse(getLocalStorageFlag("activeAppClasses"));
  const {
    customMarker,
    disableClustering,
    showNavigation: showNavigation,
    showLegend: showLegend,
    experienceColors: EXPERIENCE
  } = options;
  const { GOOD, FAIR, POOR, READY, NOCHANGE, NA, SDWANGOOD, SDWANPOOR, TALOSPOOR } = EXPERIENCE;
  const mapRef = useRef(null);
  const mapVisualRef = useRef(null);
  const isEntireMapShown = validateSitesLocation(sites);
  const pathOverview = `${BASE_URL}/overview`;
  const [loading, setLoading] = useState(true);
  const [drawerTitle, setDrawerTitle] = useState(i18nMessageBundle.selectedSite);
  const [showSideBar, setShowSideBar] = useState(false);
  const [clusterTable, setClusterTable] = useState(false);
  const clusterSiteId = useRef(null);
  const [siteID, setSiteID] = useState(null);
  const [siteName, setSiteName] = useState();
  const refDrawer = useRef(null);
  const drawerID = "geomap-drawer";
  const isRecommendationColor = sitesView === "recommendation";

  const POINT_STATE = {
    OK: "ok",
    WAITING: "waiting",
    DOWN: "down",
    READY: "ready",
    NOCHANGE: "notReady",
    NA: "unknown",
    OK_SDWAN: "ok_sdwan",
    DOWN_SDWAN: "down_sdwan",
    THREAT_COUNT: "threat_count",
    SDWAN_THREAT_COUNT: "sdwan_threat_count"
  };

  const getDefaultMarkerOptions = experience => {
    let color = colors.cyan40;

    switch (experience) {
      case GOOD:
        color = GOOD;
        break;
      case FAIR:
        color = FAIR;
        break;
      case POOR:
        color = POOR;
        break;
      case NOCHANGE:
        color = NOCHANGE;
        break;
      case READY:
        color = READY;
        break;
      case SDWANGOOD:
        color = SDWANGOOD;
        break;
      case SDWANPOOR:
        color = SDWANPOOR;
        break;
      case TALOSPOOR:
        color = TALOSPOOR;
        break;
      case NA:
        color = NA;
        break;
    }

    return {
      color: color
    };
  };

  const getClusterOptions = (clusterOption, site) => {
    const defaultClusterOption = {
      ok: 0,
      waiting: 0,
      down: 0,
      ready: 0,
      notReady: 0,
      unknown: 0,
      ok_sdwan: 0,
      down_sdwan: 0,
      threat_count: 0,
      sdwan_threat_count: 0
    };
    switch (true) {
      case GOOD === clusterOption && !isRecommendationColor:
        return { ...defaultClusterOption, ok: 1 };
      case SDWANGOOD === clusterOption && !isRecommendationColor:
        return { ...defaultClusterOption, ok_sdwan: 1 };
      case FAIR === clusterOption:
        return { ...defaultClusterOption, waiting: 1 };

      case POOR === clusterOption:
        return { ...defaultClusterOption, down: 1 };
      case SDWANPOOR === clusterOption:
        return { ...defaultClusterOption, down_sdwan: 1 };
      case NA === clusterOption && !isRecommendationColor:
        return { ...defaultClusterOption, unknown: 1 };

      case NOCHANGE === clusterOption:
        return { ...defaultClusterOption, notReady: 1 };

      case READY === clusterOption:
        return { ...defaultClusterOption, ready: 1 };

      case TALOSPOOR === clusterOption && sitesView === "Talos" && site.sdwan_threat_count === undefined:
        return { ...defaultClusterOption, threat_count: site.threat_count };
      case TALOSPOOR === clusterOption  && sitesView === "Talos" && site.sdwan_threat_count !== undefined:
        return { ...defaultClusterOption, sdwan_threat_count: site.sdwan_threat_count};
      default:
        return { ...defaultClusterOption, ok: 1 };
    }
  };

  const getExperienceColorOnAvailabilityScore = (availability, actionColor = GOOD, sitesView) => {
    let expColor = "";

    const { GOOD, FAIR, POOR, NA, SDWANGOOD, SDWANPOOR } = EXPERIENCE;

    if (availability >= 95 && availability <= 100) {
      expColor = sitesView === 'Sdwan' ? SDWANGOOD : GOOD;
    } else if (availability >= 80 && availability < 95) {
      expColor = FAIR;
    } else if (availability >= 0 && availability < 80) {
      expColor = sitesView === 'Sdwan' ? SDWANPOOR : POOR;
    } else if (availability < 0 || availability === "-") {
      expColor = NA;
    } else {
      expColor = actionColor;
    }

    return expColor;
  };

  const getExperienceColorOnQoEScore = qoeStatus => {
    let expColor = "";
    const { GOOD, FAIR, POOR, UNKNOWN } = EXPERIENCE;

    if (qoeStatus ==='good') {
      expColor = GOOD;
    } else if (qoeStatus ==='fair') {
      expColor = FAIR;
    } else if (qoeStatus ==='poor') {
      expColor = POOR;
    } else if (qoeStatus ==='unknown') {
      expColor = UNKNOWN;
    } else {
      expColor = GOOD;
    }

    return expColor;
  };

  const getDefaultMarker = (snoToShow, experience) => {
    const palette = getDefaultMarkerOptions(experience);
    const el = document.createElement("div");

    let svgToRender = ``;

    if(sitesView === "Talos" && customMarker === "CIRCLE") {
      svgToRender = `<svg width="60" height="60" text-anchor="middle" fill="white" style="font:12px inter">
            <circle cx='30' cy='30' r='24' fill='${TALOSPOOR}' />
            <text x='30' y='34' font-size='12' fill="white">${snoToShow}</text>
          </svg>`;
    } else if (customMarker === "CIRCLE") {
      svgToRender = `<svg width="20" height="20">
            <circle cx='10' cy='10' r='10' fill='${palette.color}' />
          </svg>`;
    } else {
      svgToRender = `<svg width="50" height="20">
            <rect x="15" y="0" width="45" height="18" style="fill:${palette.color};stroke-width:3;stroke:${palette.color}" />
            <circle cx="10" cy="10" r="10" stroke="${colors.gray100}" stroke-width="3" fill="${palette.color}" />
            <circle cx="10" cy="10" r="7" fill="${colors.gray100}" />
            <text x='25' y='15' font-size='12' fill="${colors.gray100}" stroke='${colors.gray100}' stroke-width='1'>#  ${snoToShow}</text>
          </svg>`;
    }

    el.innerHTML = svgToRender;
    return el;
  }

  const pointsToShowWithAvailability = () => {
    return sites && sites.map((site, index) => {
      const {
        siteId,
        city,
        site_name,
        latitude,
        longitude,
        availability,
        usage,
        metrics,
        actionColor,
        actionState
      } = site;
      const experience = sitesView === "Talos" ? TALOSPOOR : getExperienceColorOnAvailabilityScore(availability, actionColor, props?.sitesView);
      const talosCountToShow = sitesView === "Talos" && site.sdwan_threat_count === undefined ? site.threat_count : site.sdwan_threat_count; //Getting count to show on cluster or site
      const snoToShow = props?.sitesView === "Talos" ? talosCountToShow : index + 1;
      const siteID = siteId ? siteId : site.site_id;

      let defaultPointingOptions = {
        id: `${randomUUID()}${siteID !== undefined ? siteID : ""}`, //Appending siteId with UUID to access it inside features properties
        siteId: siteID,
        city: city,
        location: [longitude, latitude],
        availability: availability,
        experience: experience,
        usage: usage,
        defaultMarkerOptions: getDefaultMarkerOptions(experience),
        getMarker: () => getDefaultMarker(snoToShow, experience),
        name: site_name,
        sitesView: sitesView,
        metrics: metrics,
        actionState: actionState
      };

      if (!disableClustering) {
        defaultPointingOptions = {
          ...defaultPointingOptions,
          clusterOptions: getClusterOptions(experience, site)
        };
      }
      return defaultPointingOptions;
    })
  };

  const pointsToShowWithQoe = () => {
    return sites && sites.map(
      ({ site_id, latitude, longitude, vqoe_score, vqoe_status, usage }, index) => {

        const experience = getExperienceColorOnQoEScore(vqoe_status);
        const snoToShow = index + 1;

        let defaultPointingOptions = {
          id: `${randomUUID()}${site_id}`,
          siteId: site_id,
          location: [longitude, latitude],
          qoeScore: vqoe_score,
          usage: usage,
          clusterOptions: getClusterOptions(experience),
          getMarker: () => getDefaultMarker(snoToShow, experience),
        };
        return defaultPointingOptions;
      })
  };

  const getClusterMarkerProps = () => {
    return {
      stateColorMap: {
        ok: GOOD,
        waiting: FAIR,
        down: POOR,
        ready: READY,
        notReady: NOCHANGE,
        unknown: NA,
        ok_sdwan: SDWANGOOD,
        down_sdwan: SDWANPOOR,
        threat_count: TALOSPOOR,
        sdwan_threat_count: TALOSPOOR
      }
    };
  };

  const getClusterProperties = () => {
    return {
      ok: ["+", ["get", POINT_STATE.OK]],
      waiting: ["+", ["get", POINT_STATE.WAITING]],
      down: ["+", ["get", POINT_STATE.DOWN]],
      ready: ["+", ["get", POINT_STATE.READY]],
      notReady: ["+", ["get", POINT_STATE.NOCHANGE]],
      unknown: ["+", ["get", POINT_STATE.NA]],
      ok_sdwan: ["+", ["get", POINT_STATE.OK_SDWAN]],
      down_sdwan: ["+", ["get", POINT_STATE.DOWN_SDWAN]],
      threat_count: ["+", ["get", POINT_STATE.THREAT_COUNT]],
      sdwan_threat_count: ["+", ["get", POINT_STATE.SDWAN_THREAT_COUNT]],
    };
  };

  const getMapSettings = () => {
    return {
      zoom: 1,
      center: [0, 0],
      preserveDrawingBuffer: true
    };
  };

  const getSiteDetails = e => {
    if (e && e.target) {
      setLocalStorageFlag("actionCardSite", e.target.innerText);
      props.setSelectedSite(e.target.innerText);
      props.history.push(pathOverview);
    }
  };

  const createClusterElements = (cluster, qoe, label) => {
    return (
      <div className={`details-${qoe}`}>
        <div className={`details-${qoe}-label hbr-type-body3`}>{label}</div>
        <div className={`details-${qoe}-value`}>
          <div className={`details-${qoe}-value-icon`}>
            &nbsp;
          </div>
          <div className={`details-${qoe}-value-score`}>
            {cluster}
          </div>
        </div>
      </div>
    );
  };

  const createClusterElementsSdwan = (cluster, qoe, label) => {
    return (
      <div className={`details-${qoe}`}>
        <div className={`details-${qoe}-value`}>
          <div className={`details-${qoe}-value-icon`}>
            &nbsp;
          </div>
        </div>
        <div onClick={() => qoe === 'unsecure' && cluster > 0 ? getSelectedSideBar() : undefined} className={qoe === 'unsecure' && cluster > 0 ? `details-${qoe}-label hbr-type-body3 unsecure-link` : `details-${qoe}-label hbr-type-body3`}>{label}</div>
        <div className={`details-${qoe}-value`}>
          <div onClick={() => qoe === 'unsecure' && cluster > 0 ? getSelectedSideBar() : undefined} className={qoe === 'unsecure' && cluster > 0 ? `details-${qoe}-value-score unsecure-link` : `details-${qoe}-value-score`}>
            {cluster}
          </div>
        </div>
      </div>
    );
  };

  const drawerHeading = (title) => {
    setDrawerTitle(title);
  }

  const getBodyContent = (metrics, siteId) => {
    //i.e. metrics is {google-workspace: {defaultQuality: '99.91', recommendationQuality: '99.91', actionState: 'READY'},office365: {defaultQuality: '99.82', recommendationQuality: '99.82', actionState: 'READY'}
    return Object.keys(metrics).map((elem, key) => {
      if (!metrics[elem].actionState) return <></>;
      return (
        <tr key={key} id={`${elem},${siteId}`}>
          <td>
            <div>{getIcon(elem, "2.0em")}</div>
            <div>
              {appClassLabels[elem]}
            </div>
          </td>
          <td>{metrics[elem].defaultQuality}</td>
          <td>{metrics[elem].recommendationQuality}</td>
          <td>
            <span className="tooltip-content-state-action-btn">
              {metrics[elem].actionState}
            </span>
          </td>
        </tr>
      );
    });
  };

  const getSideBar = (siteId, name) => {
    setShowSideBar(true);
    setClusterTable(false);
    clusterSiteId.current = null;
    setSiteID(siteId);
    setSiteName(name);
    setDrawerTitle(i18nMessageBundle.selectedSite);
  }

  const getSelectedSideBar = () => {
    setShowSideBar(true);
    setClusterTable(true);
    setSiteID();
    setDrawerTitle(i18nMessageBundle.selectedCluster);
  }

  const getTooltipContent = (id, data) => {
    const el = document.createElement("div");
    el.className = "tooltip-content";
    let toolTip = undefined;
    if (props.sitesView === 'Sdwan' && data?.props?.cluster) {
      const clusterId = data?.props?.cluster_id;
      const point_count = data?.props?.point_count;
      mapVisualRef.current?.map?.getSource('pointsLocations').getClusterLeaves(clusterId, point_count, 0, (error, features) => {
        let clustersArray = [];
        features.map((site) => {
          if (site.properties.down_sdwan > 0) {
            const siteId = parseInt(site.properties.id.substring(36)); //Extracting siteID from 36 char unique UUID
            clustersArray.push(siteId);
          }
        });
        clusterSiteId.current = clustersArray;
      });
    }
    if (data?.props?.cluster) {
      const poorQoeWithinCluster = data.props.down;
      const fairQoeWithinCluster = data.props.waiting;
      const goodQoeWithinCluster = data.props.ok;
      const goodQoeWithinCluster_sdwan = data.props.ok_sdwan;
      const poorQoeWithinCluster_sdwan = data.props.down_sdwan;
      const grayQoeWithinCluster = data.props.unknown;
      const ready = data.props.ready;
      const notReady = data.props.notReady;

      let highLabelValue = i18nMessageBundle.allSiteHighLabel;
      let lowLabelValue = i18nMessageBundle.allSiteLowLabel;
      if (props.sitesView === 'Sdwan') {
        highLabelValue = i18nMessageBundle.noSecurityIncidents;
        lowLabelValue = i18nMessageBundle.securityIncidents;
      }
      toolTip = (
        <div className={props.sitesView === 'Sdwan' ? 'common-map-sites-tooltip-cluster-talos' : 'common-map-sites-tooltip-cluster'}>
          <div className="heading">
            {props.sitesView === 'Sdwan' ? (
              <>
                <div className="hbr-type-body2 security-map-cluster-tooltip-title">{i18nMessageBundle.tabNavigationSites}
                  <HbrBadge pill size="small" className="security-map-cluster-tooltip-badge">
                    {data.props.point_count} {i18nMessageBundle.allSitesView.recoSitesLabel}
                  </HbrBadge>   </div>
              </>
            ) : (
                <>
                  {`${data.props.point_count} ${i18nMessageBundle.allSitesView.recoSitesLabel}`}
                </>
              )}
          </div>
          {props.sitesView !== 'recommendation' ?
            <div className="details">
              {props.sitesView !== 'Sdwan' && createClusterElements(
                goodQoeWithinCluster,
                "high",
                highLabelValue
              )}
              {props.sitesView !== 'Sdwan' && createClusterElements(
                fairQoeWithinCluster,
                "fair",
                i18nMessageBundle.allSiteFairLabel
              )}
              {props.sitesView !== 'Sdwan' && createClusterElements(
                grayQoeWithinCluster,
                "unknown",
                i18nMessageBundle.allSiteUnknownLabel
              )}

              {props.sitesView !== 'Sdwan' && createClusterElements(
                poorQoeWithinCluster,
                "poor",
                lowLabelValue
              )}
              {props.sitesView === 'Sdwan' && createClusterElementsSdwan(
                poorQoeWithinCluster_sdwan,
                "unsecure",
                lowLabelValue
              )}
              {props.sitesView === 'Sdwan' && createClusterElementsSdwan(
                goodQoeWithinCluster_sdwan,
                "secure",
                highLabelValue
              )}
            </div> :
            <div className="details">
              {
                createClusterElements(
                  ready,
                  "ready",
                  i18nMessageBundle.allSiteReadyLabel
                )
              }
              {
                createClusterElements(
                  notReady,
                  "notReady",
                  i18nMessageBundle.allSiteNotReadyLabel
                )
              }
            </div>
          }
        </div>
      );
    } else if (data?.node && sitesView === "recommendation"
    ) {
      const { metrics, siteId, id, actionState } = data.node;
      let hasAppGroupActionState = false;
      // if actionState  isActive this means the site has deleted state for all app group
      // so we show no recommendation message in the tooltip and we ignore the metrics details
      const isActive = actionState && isApplyRevertRecommendation(actionState);

      if (!isActive) {
        // if metrics is empty we show no recommendation message in the tooltip
        if (metrics) {
          // we loop tru metric objec and check if at leat one app group has some state so we can show
          // action state in the tooltip if no app group has action then show no recommendation
          Object.keys(metrics).forEach(elem => {
            if ("actionState" in metrics[elem]) hasAppGroupActionState = true;
          });
        }
      }

      toolTip = (
        <table className="tooltip-content">
          <caption>
            <div>
              <span className="tooltip-content-site-id-tooltip">
                {i18nMessageBundle.allSitesView.siteIDLabel}
                {siteId ? siteId : id}
              </span>
            </div>
          </caption>
          {!_.isEmpty(metrics) && hasAppGroupActionState && (
            <thead>
              <tr>
                <th scope="col">
                  {i18nMessageBundle.allSitesView.application}
                </th>
                <th scope="col">
                  {i18nMessageBundle.defaultPathQualtyMapLabel}
                </th>
                <th scope="col">{i18nMessageBundle.recoPathQualityMapLabel}</th>
                <th scope="col">{i18nMessageBundle.allSitesView.recoState}</th>
              </tr>
            </thead>
          )}
          {!_.isEmpty(metrics) && hasAppGroupActionState && (
            <tbody>{getBodyContent(metrics, siteId)}</tbody>
          )}
          {!hasAppGroupActionState && (
            <>
              <div className="tooltip-content-no-recom-text">
                {i18nMessageBundle.allSitesView.noRecom}
              </div>
            </>
          )}
        </table>
      );
    } else if (data?.node?.sitesView === "availability") { 
      const { name, city, usage, availability } = data.node;
      const iconWithColorStyle = {
        backgroundColor: getColor(
          formatNumberNDecimalPlaces(availability, 1),
          "availability"
        )
      }; 
      const siteNameDisplay = formatString(name,21);
      toolTip = (
        <div className="common-map-sites-tooltip-single-site">
          <div className="site-details">
            <div className="site-details-qoe">
              <div className="site-details-qoe-label hbr-type-body3">
                {i18nMessageBundle.siteHoverId}
              </div>
              <div className="site-details-qoe-value">
                <div className="hbr-type-body3">
                  <a data-cy={name} onClick={getSiteDetails}>
                    {siteNameDisplay}
                  </a>
                </div>
              </div>
            </div>
            <div className="site-details-usage">
              <div className="site-details-usage-label hbr-type-body3">
                {i18nMessageBundle.geoMapLocation}
              </div>
              <div className="hbr-type-body3">{city}</div>
            </div>
            <div className="site-details-qoe">
              <div className="site-details-qoe-label hbr-type-body3">
                {i18nMessageBundle.geoMapAvailability}
              </div>
              <div className="site-details-qoe-value">
                <div
                  className="site-details-qoe-value-icon"
                  style={iconWithColorStyle}
                >
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {formatNumberNDecimalPlaces(availability, 1)}
                </div>
              </div>
            </div>
            <div className="site-details-usage">
              <div className="site-details-usage-label hbr-type-body3">
                {i18nMessageBundle.geoMapUsage}
              </div>
              <div className="hbr-type-body3">
                {formatBytes(usage)}
              </div>
            </div>
          </div>
        </div>
      );
    }
    else {
      if (props.sitesView === "Sdwan") {
        const {siteId, name, availability} = data?.node; 
         const iconWithColorStyle = {
          backgroundColor: getColor(availability,"taloSdwan")
        };
        const siteNameDisplay = formatString(name,15);
        toolTip = (
          <div className="common-map-sites-tooltip-single-site-talos">
            <div className="site-details">
              <div className="site-details-qoe">
                <div className="site-details-qoe-label hbr-type-body3">
                  {i18nMessageBundle.siteHoverId}
                </div>
                <div className="site-details-qoe-value">
                <div
                  className="site-details-qoe-value-icon"
                  style={iconWithColorStyle}
                >
                  &nbsp;
                </div>
                  <div className="hbr-type-body3">
                    {data?.node?.availability === 0 ? (
                      <a className="hbr-type-body3 sdwan-map-view-tooltip-siteid" data-cy={siteId} target="_blank" onClick={() => getSideBar(siteId, name)}>
                        {siteNameDisplay ? siteNameDisplay : ''}
                      </a>
                    ) : (<div>{siteNameDisplay ? siteNameDisplay : ''} </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    if(toolTip !== undefined) {
      ReactDOM.render(toolTip, el);
      return el;
    }
  };



  const onClusterClicked = siteId => {
    const path = location.pathname.split("/")[
      location.pathname.split("/").length - 1
    ];

    if (selectedSite === -1 && isRecommendationPage(path)) {
      let path;
      if (location.pathname.includes("Recommendations")) {
        path = `${BASE_URL}/Dashboard/Recommendations`;
      } else {
        path = `${BASE_URL}/predictivenetworks/applicationinformation/site`;
      }
      props.setSelectedSite(siteId);
      props.setAltoProps({ appGroup: appGroup });
      props.history.push(path);
    }
  };

  const getToolTipSettings = () => {
    return {
      showTooltipOnClusterClick: false,
      tooltipOnHover: true
    };
  };

  const getLegend = () => {
    const content = document.createElement("div");
    let cnt;
    // Legends for Talos sdwan view 
    if (props.sitesView === 'Sdwan') {
      cnt = <div>
        <div className='legends-details-container'>
          <div className='legendsvalue unsecure'>&nbsp;</div>
          <div className='hbr-type-body3'>{i18nMessageBundle.noSecurityIncidents}</div>
        </div>
        <div className='legends-details-container'>
          <div className='legendsvalue secure'>&nbsp;</div>
          <div className='hbr-type-body3'>{i18nMessageBundle.securityIncidents}</div>
        </div>
      </div>;
    } else {
      cnt = <div>
      <div className='legends-details-container'>
        <div className='legendsvalue high'>&nbsp;</div> 
        <div className='hbr-type-body3'>{i18nMessageBundle.sitesHigh}</div>
      </div>
      <div className='legends-details-container'>
        <div className='legendsvalue fair'>&nbsp;</div>
        <div className='hbr-type-body3'>{i18nMessageBundle.sitesFair}</div>
      </div>
      <div className='legends-details-container'>
        <div className='legendsvalue low'>&nbsp;</div>
        <div className='hbr-type-body3'>{i18nMessageBundle.sitesLow}</div>
      </div>
      <div className='legends-details-container'>
        <div className='legendsvalue unknown'>&nbsp;</div>
        <div className='hbr-type-body3'>{i18nMessageBundle.sitesUnknown}</div>
      </div>
    </div>;
    }
    ReactDOM.render(cnt, content);

    return content;
  };

  const onDrawerClose = (event) => {
    if (event.target.id === drawerID) { 
      setShowSideBar(false);
      setSiteID(null);
    }
  }

  const createMapViual = () => {
    setLoading(true);
    let defaultInitProperties = {
      domHolder: mapRef.current,
      preserveDrawingBuffer: true,
      trackResize: true,
      enableNav: showNavigation !== undefined ? showNavigation : true,
      enableLegend: showLegend !== undefined ? showLegend : true,
      points: isAvailabilityScore ? pointsToShowWithAvailability() : pointsToShowWithQoe(),
      /** Map background is blue color,temporary code commented */
      // stylesUrl: "mapbox://styles/mapbox/streets-v12", // style URL
      disableClustering: options?.disableClustering ? options?.disableClustering : false,
      mapSettings: options?.mapSettings ? options?.mapSettings : getMapSettings(),
      tooltipSettings: options?.tooltipSettings ? options?.tooltipSettings : getToolTipSettings(),
      getLegendContent: getLegend,
      getTooltipContent: getTooltipContent
    };

    if (!disableClustering) {
      defaultInitProperties = {
        ...defaultInitProperties,
        clusterProperties: getClusterProperties(),
        clusterMarkerProps: getClusterMarkerProps(),
        onClusterClick: onClusterClicked,
        getClusterMarker: sitesView === "Talos" ? (e) => getSimpleCluster(e, TALOSPOOR) : (e) => getDonutChartCluster(e, getClusterMarkerProps())
      };
    }

    mapVisualRef.current = new GeoMap(defaultInitProperties);
    mapVisualRef.current?.init().then(() => {
      mapVisualRef.current?.map?.resize();
      if (isEntireMapShown && sitesView !== "Talos") mapVisualRef.current?.fitBounds(100); //Fitbound checking, if it is true, indicates that we do not need to establish fitbound for that map. Hence for Talos view, we are not setting fitbound since we need to display the entire map view
      mapVisualRef.current?.map?.triggerRepaint();

      // Set loading to false when the map is fully rendered and idle
      mapVisualRef.current?.map?.on('idle', () => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    if (sites && sites.length > 0) createMapViual();
    return () => { // Function will be called on component unmount 
      mapVisualRef.current = null;  
    }
  }, []);

  useEffect(() => {
    if (mapRef.current && mapVisualRef.current !== null && mapVisualRef.current?.mapLoaded) {
      createMapViual();
    }
  }, [sites, sitesView, currentTimeStamp]);

  if (sites.length === 0)
    return (<NoDataAvailable text={"No sites found"} />)
  else
    return (
      <>
        <div
          className={css["geo-map"]}
          ref={mapRef}
          name="map-visual"
          id={!loading ? "isLoaded_chart" : ""}
        >
          {showSideBar && (<HbrDrawer size="large" id={drawerID} ref={refDrawer} overlay={true} label={drawerTitle} onHbr-hide={onDrawerClose} open>
          <SdwanSidebar
            {...props}
            siteId={siteID}
            siteNameTable={siteName}
            clusterTable={clusterTable}
            clustersSiteId={clusterSiteId.current}
            drawerHeading={drawerHeading}
          />
        </HbrDrawer>)}
        </div>
      </>
    );
};

GeoMapComponent.propTypes = {
  sites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.object.isRequired,
  childCount: PropTypes.object,
  children: PropTypes.object,
  node: PropTypes.object,
  setSelectedSite: PropTypes.func.isRequired,
  globalFilter: PropTypes.object.isRequired,
  history: PropTypes.object,
  sitesView: PropTypes.string.isRequired,
  setAltoProps: PropTypes.func.isRequired,
  actions: PropTypes.object,
  applications: PropTypes.object,
  props: PropTypes.object,
  isAvailabilityScore: PropTypes.bool
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(GeoMapComponent))
);
