import React, { useState } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { ListBullets, SquaresFour } from "phosphor-react";

//style
import css from "../commonMagneticStyle.less";

//enums
import { TOGGLER } from "../../utils/enums";

//components
import AppHoc from "../../generics/AppHOC";

const [HbrViewSwitcher, HbrRadioButton] = reactWrapper([
    "hbr-view-switcher",
    "hbr-radio-button"
]);

const PageToggle = ({ setPageToggle }) => {
    const [toggle, setToggle] = useState(TOGGLER.CARD);

    const handleView = () => {
        if (toggle === TOGGLER.LIST) {
            setToggle(TOGGLER.CARD);
            setPageToggle(TOGGLER.CARD);
        } else {
            setToggle(TOGGLER.LIST);
            setPageToggle(TOGGLER.LIST);
        }
    }

    return (
        <div className={css["page-toggle"]}>
            <HbrViewSwitcher
                value={toggle}
                name="selected_view"
                onHbr-change={handleView}
                className="toggle-switch"
            >
                <HbrRadioButton value={TOGGLER.CARD}>
                    <SquaresFour size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
                </HbrRadioButton>
                <HbrRadioButton value={TOGGLER.LIST}>
                    <ListBullets size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
                </HbrRadioButton>
            </HbrViewSwitcher>
        </div>
    )
}

PageToggle.propTypes = {
    setPageToggle: PropTypes.func
}

export default AppHoc(PageToggle);