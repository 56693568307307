const getItemTooltip = data => `
  <div
    style="
      font-family: var(--hbr-font-body);
      font-weight: 600;
      fontSize: 12px;
      lineHeight: 16px;
      color: var(--hbr-color-neutral-text, #23282E);
    "
  >
    <div
      style="
        width: 16px;
        height: 16px;
        background-color: ${data.color};
        display: inline flow-root;
        vertical-align: middle;
      "
    ></div>
    <div
      style="
        height: 16px;
        padding: 0 4px;
        display: inline flow-root;
        vertical-align: middle;
      "
    >
      ${data.id}${Array.isArray(data.others) === false ? ":" : ""}
    </div>
    ${Array.isArray(data.others) ? data.others.map(item => `
      <div style="padding-top: 8px;padding-left: 12px;">
        <div
          style="
            width: 16px;
            height: 16px;
            background-color: ${item.color};
            display: inline flow-root;
            vertical-align: middle;
          "
        ></div>
        <div
          style="
            height: 16px;
            padding: 0 4px;
            display: inline flow-root;
            vertical-align: middle;
          "
        >
          ${item.id}:
        </div>
        <div
          style="
            height: 16px;
            font-weight: 400;
            display: inline flow-root;
            vertical-align: middle;
          "
        >
          ${item.str} (${item.percent}%)
        </div>
      </div>
    `).join("") : `
      <div
        style="
          height: 16px;
          font-weight: 400;
          display: inline flow-root;
          vertical-align: middle;
        "
      >
        ${data.str} (${data.percent}%)
      </div >
    `}
  </div >
`;

export const getDonutDefaultConfig = (centerText, data) => ({
  isFocusable: false,
  enableArcLinkLabels: false,
  enableArcLabels: false,
  innerRadius: 0.7,
  animate: false,
  motionConfig: "default",
  transitionMode: "centerRadius",
  colors: ({ data }) => data.color,
  margin: { top: 5, bottom: 5, left: 5, right: 135 },
  legends: [
    {
      itemWidth: 120,
      itemHeight: 18,
      itemsSpacing: 2,
      translateX: 130,
      translateY: 0,
      anchor: "right",
      direction: "column",
      itemTextColor: "var(--hbr-color-gray-40, #596069)",
      itemOpacity: 1,
      effects: [{ on: "hover", style: { itemTextColor: "#000" } }]
    }
  ],
  theme: {
    text: {
      fontFamily: "var(--hbr-font-body)",
      fontSize: 12,
      fill: "var(--hbr-color-neutral-text, #23282E)"
    }
  },
  itemTooltip: event => getItemTooltip(event.data),
  legendTooltip: event => getItemTooltip(event),
  centerContent: () => `
    <div
      style="
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          font-family: var(--hbr-font-heading);
          font-size: 32px;
          line-height: 43px;
          font-weight: 700;
        "
      >${centerText[0]}</div>
      <div>${centerText[1]}</div>
    </div>
  `,
  data
});
