import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//style
import css from "../commonMagneticStyle.less";

const [HbrTag] = reactWrapper(["hbr-tag"]);

const BetaTag = ({ msg }) => {
  return (
    <div className={css["beta-tag-content"]} data-cy="beta-tag-component" title={msg}>     
        <HbrTag sentiment="danger" id="negative">
          Beta
        </HbrTag>
    </div>
  );
};

BetaTag.propTypes = {
  msg: PropTypes.string
};

export default BetaTag;
