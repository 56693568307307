import React from "react";
import { PropTypes } from "prop-types";

import "../commonMagneticStyle.less";

const QoEComponent = props => {
  const { iconType, qoeScore, qoeTextValue } = props;

  return (
    <div className="metric">
      <div className="metric-top">
        <div className={`metric-top-icon-${iconType}`}>&nbsp;</div>
        <div className="metric-top-calculation">{qoeScore}</div>
        <div className="metric-bottom"> {qoeTextValue}</div>
      </div>
    </div>
  );
};

QoEComponent.propTypes = {
  iconType: PropTypes.string,
  qoeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qoeTextKey: PropTypes.string,
  qoeTextValue: PropTypes.string,
  qoePercentage: PropTypes.string,
  pathAction: PropTypes.string
};

export default QoEComponent;
