import React from "react";
import { PropTypes } from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import moment from "moment";
import _ from "lodash";
import {
  customizeLegend,
  customizeTooltip,
  getTimeState
} from "../../utils/chart";
import { getLineChartDummyData } from "../../utils/common";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { chartPrimaryColors, statusColors } from "../../utils/colors";
import { displayDateTime } from "../../utils/displayTime";
import { defaultTextValue } from "../../utils/enums";
import { formatBytes } from "../../utils/format";

/**
 * Overlayed Line Charts
 * passing minimal value in "data" field as below in order to display
 * custom sizes and offset of the bar and color can also be passed via "lineConfig" field as line 69
 * See defaultProps for example usage
 *
 * data: [
    {
      date: new Date(2018, 3, 20),
      value: 2,
      color: "#d4371c"
    },
    {
      date: new Date(2018, 3, 21),
      value: 4,
      color: "#00a0d1"
    }]
 */

class OverlayedLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartDiv = Math.random().toString();
  }

  static defaultProps = {
    data: [],
    lineConfig: {},
    qualityRange: false,
    range: {
      xAxes: {
        min: moment().subtract(7, "d"),
        max: moment()
      }
    },
    yAxisConfig: {
      valueY: "value",
      disableYAxisLabel: false,
      yAxisTitle: undefined
    },
    chartPadding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  };

  componentDidMount() {
    const {
      data,
      lineConfig,
      qualityRange,
      range,
      order,
      seriesFill,
      qoeRangeFill,
      hideLegend
    } = this.props;
    const { dateX } = this.props.xAxisConfig;
    const {
      valueY,
      disableYAxisLabel,
      yAxisTitle,
      yAxisminGridDistance
    } = this.props.yAxisConfig;
    const timeState = getTimeState(this.props.sidebar);
    const chart = am4core.create(this.chartDiv, am4charts.XYChart);
    chart.width = am4core.percent(97);
    chart.cursor = new am4charts.XYCursor();

    // Add data
    const chartData = data.length == 1 ? getLineChartDummyData(data) : data;
    chart.data = chartData || [];

    if (chart.data.length === 0) {
      const label = chart.createChild(am4core.Label);
      // set no data text
      label.text = i18n.noData;
      // set label style
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.y = am4core.percent(30);
      label.horizontalCenter = "middle";
    }

    // set date axis as x axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // set time interval for plotting graph data points
    dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
    // disable tooltip of x axis data points
    dateAxis.cursorTooltipEnabled = false;
    // set grid location from middle to start of the interval
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    // set start and end time of date axis
    dateAxis.strictMinMax = true;
    if (this.props.min) dateAxis.min = this.props.min;
    if (this.props.max) {
      dateAxis.max = this.props.max ;
    }

    // set value axis as y axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // set y axis label
    valueAxis.title.text = yAxisTitle;
    valueAxis.title.userClassName = "y-axis-title";
    // set minimum grid distance in pixels
    valueAxis.renderer.minGridDistance = 50;
    // disable tooltip of y axis data points
    valueAxis.cursorTooltipEnabled = false;
    // set start value of y axis
    valueAxis.min = 0;
    if (seriesFill) {
      valueAxis.numberFormatter.numberFormat = "#.b";
    }
    valueAxis.renderer.labels.template.disabled = disableYAxisLabel;
    if (yAxisminGridDistance)
      valueAxis.renderer.minGridDistance = yAxisminGridDistance;

    const createSeries = (name, index) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.name = this.props.seriesNames[name]
        ? this.props.seriesNames[name]
        : name;
      // set x axis property name
      series.dataFields.dateX = "date";
      // set y axis property name
      series.dataFields.valueY = name;
      series.config = {
        strokeWidth: 2,
        dataFields: {
          dateX: data.length === 1 ? "date" : dateX,
          valueY: data.length === 1 ? "value" : name
        },
        ...lineConfig
      };
      // gap > (autoGapCount * baseInterval) => gap > (60 * 1 minute)
      series.connect = false;
      series.autoGapCount = 60;
      if (dateAxis.baseInterval.timeUnit === "minute")
        series.autoGapCount = 
          timeState.interval / (60000 * dateAxis.baseInterval.count);

      if (!_.isEmpty(qualityRange)) {
        const range1 = valueAxis.createSeriesRange(series);
        range1.value = qualityRange.poor.min;
         range1.endValue = qualityRange.poor.max;
        range1.contents.stroke = statusColors.poor;
        if (qoeRangeFill) {
          range1.contents.fill = statusColors.poor;
          range1.contents.fillOpacity = 0.06;
        }

        const range2 = valueAxis.createSeriesRange(series);
        range2.value = qualityRange.fair.min;
        range2.endValue = qualityRange.fair.max;
        range2.contents.stroke = statusColors.fair;

        const range3 = valueAxis.createSeriesRange(series);
        range3.value = qualityRange.good ? qualityRange.good.min : null;
        range3.endValue = qualityRange.good ? qualityRange.good.max : null;
        range3.contents.stroke = statusColors.good;

      }

      series.dataFields.valueY = data.length == 1 ? "value" : name;
      series.dataFields.valueX = data.length == 1 ? "date" : dateX;
      if (seriesFill) {
        series.stroke = am4core.color(chartPrimaryColors[0]);
        series.fill = am4core.color(chartPrimaryColors[0]);
        series.fillOpacity = 0.9;
      }
      if (index === 0) {
          series.adapter.add("tooltipHTML", (html, target) => {
          const context = target.tooltipDataItem.dataContext;
            if (context) {
              const lineSeries = [...chart.series.values].reverse();
              let date = context.date ? context.date : context.entry_time;
            html = `
              <div class="ttip-header">
              &nbsp;
                <span class="date">${displayDateTime(date)}</span>
              </div>
              <table class="ttip-content">
              ${lineSeries
                .map(item =>
                  !item.isHidden
                    ? `
                    <tr>
                      <td class="ttip-bwidth">
                        <div class="flex-items">
                          <span
                            class="bar-legend"
                            style="background-color:${item.stroke.hex};"
                          ></span>
                          ${item.name}
                        </div>
                      </td>
                      <td>${(
                        context[item.dataFields.valueY] !== undefined
                        && context[item.dataFields.valueY] !== null
                      ) ? seriesFill
                          ? formatBytes(context[item.dataFields.valueY])
                          : context[item.dataFields.valueY]
                        : defaultTextValue
                      }</td>
                    </tr>
                    `
                    : ""
                )
                .join("")}
              </table>`;
          }
          return html;
        });
        customizeTooltip(series.tooltip);
      }
  //     // set X and Y axis max
      if (data && !range?.yAxes) {
        // for override
        valueAxis.max = Math.max(
          ...data.map(item => item[lineConfig?.dataFields?.valueY || "y"])
        );
      } else if (data && range?.yAxes) {
        valueAxis.min = range.yAxes.min;
        valueAxis.max = range.yAxes.max;
      }

      this.chart = chart;

           return series;
    }
       if (data.length > 0) {
      let keyList = data.map(d => {
        return Object.keys(d);
      });
      // converts 2D array to 1D array and remove duplicates.
      let uniqueKeys = [...new Set(keyList.flat())];
      if (Array.isArray(order))
        order.filter(item => uniqueKeys.includes(item))
          .forEach(createSeries);
      else
        valueY.map((key,index) => {
          if (key !== "date" && key !== "message" && key !== "is_anomaly")
            createSeries(key,index);
        });
       }

    if (!hideLegend) {
      chart.legend = new am4charts.Legend();
      // customize legend
      customizeLegend(chart.legend, true, false, false);
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
      setTimeout(
        chart => {
          chart.dispose();
        },
        1,
        this.chart
      );
    }
  }

  render() {
    return (
      <div id={this.chartDiv} className={`${css.chart} hbr-type-body4`} />
    );
  }
}

OverlayedLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  lineConfig: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  range: PropTypes.object.isRequired,
  xAxisConfig: PropTypes.object,
  yAxisConfig: PropTypes.object.isRequired,
  seriesNames: PropTypes.object,
  qualityRange: PropTypes.object,
  usageConvert: PropTypes.bool,
  chartPadding: PropTypes.object.isRequired,
  order: PropTypes.array,
  seriesFill: PropTypes.bool,
  qoeRangeFill: PropTypes.bool,
  hideLegend: PropTypes.bool,
  sidebar: PropTypes.bool
};

OverlayedLineChart.defaultProps = {
  xAxisConfig: { dateX: "date" },
  hideLegend: false,
  qoeRangeFill: false,
  seriesFill: false,
  seriesNames: {},
  sidebar: false
};
export default OverlayedLineChart;

