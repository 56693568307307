import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getLineChartDummyData } from "../../utils/common";
import {
  shortDisplayDateTimeHour,
  isDateInBetween,
  getRangeFourHours,
  shortDisplayDateTime
} from "../../utils/displayTime";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../commonMagneticStyle.less";
import { colors } from "../../utils/colors";

const customizeGrip = (grip, am4core) => {
  let line = grip.createChild(am4core.Rectangle);
  line.height = 50;
  line.width = 5;
  line.fillOpacity = 0.4;
  line.align = "center";
  line.valign = "middle";
};

const SingleLegendChart = props => {
  let charts = [];
  let scrollDisplay = false;
  let scrollProcessing = false;
  let firstLoad = true;
  let scrollTimeFilter = [];
  let currentTime = "";
  am4core.useTheme(am4themes_animated);
  am4core.options.autoDispose = true;
  let eventsList = [];
  const createChart = (div, data, label) => {
    let { min, max } = props;
    let chart = am4core.create(div, am4charts.XYChart);
    props.qoeData.sort(function (a, b) {
      return a.date - b.date;
    });
    let reverseArray = [...data].reverse();
    let qoeData =
      props.qoeData && props.qoeData.length == 1
        ? getLineChartDummyData(props.qoeData)
        : props.qoeData;
    chart.data = div == "scrollchartdiv" ? qoeData : reverseArray;
    chart.paddingRight = 30;
    chart.fontWeight = 100;
    chart.tooltip.getFillFromObject = false;
    currentTime = max ? max : Date.now();
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      count: 1,
      timeUnit: "minute"
    };
    chart.events.on("ready", function () {
      dateAxis.zoomToDates(
        new Date(getRangeFourHours(currentTime)),
        new Date(shortDisplayDateTimeHour(currentTime))
      );
    });

    if (min) {
      dateAxis.min = min;
    }
    if (max) {
      dateAxis.max = max;
    }
    if (div == "scrollchartdiv") {
      chart.paddingLeft = 20;
      chart.fontWeight = 900;
      let valueAxisScroll = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxisScroll.marginLeft = -40;
      let series = chart.series.push(new am4charts.LineSeries());

      series.dataFields.valueY = "value";
      series.dataFields.dateX = "date";
      series.smoothing = "monotoneX";

      // create scroll bar style
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.series.push(series);
      // set scroll bar style
      chart.scrollbarX.minHeight = 50;
      chart.scrollbarX.thumb.minWidth = 50;
      chart.scrollbarX.thumb.minHeight = 50;

      chart.scrollbarX.unselectedOverlay.fill = am4core.color(colors.gray85);
      chart.scrollbarX.unselectedOverlay.fillOpacity = 0.2;
      chart.scrollbarX.thumb.background.fill = am4core.color(colors.blue70);
      chart.scrollbarX.thumb.background.fillOpacity = 0.4;

      // enable and customize scroll bar grips
      chart.scrollbarX.startGrip.visible = true;
      chart.scrollbarX.endGrip.visible = true;

      chart.scrollbarX.startGrip.icon.disabled = true;
      chart.scrollbarX.startGrip.background.disabled = true;
      chart.scrollbarX.endGrip.icon.disabled = true;
      chart.scrollbarX.endGrip.background.disabled = true;

      customizeGrip(chart.scrollbarX.startGrip, am4core);
      customizeGrip(chart.scrollbarX.endGrip, am4core);

      // /* Create a cursor */
      chart.cursor = new am4charts.XYCursor();
      //disable the x axis cursorTooltip
      chart.cursor.xAxis = dateAxis;
      chart.cursor.xAxis.cursorTooltipEnabled = false;
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.strokeWidth = 2;

      chart.plotContainer.visible = false;
      chart.leftAxesContainer.visible = false;
      chart.rightAxesContainer.visible = false;
      chart.bottomAxesContainer.visible = false;
      chart.scrollbarX.updateWhileMoving = false;
      dateAxis.events.on("startchanged", function syncZoom(ev) {
        if (ev && ev.target && ev.target.chart && ev.target.chart.xAxes) {
          if (!scrollDisplay) {
            setTimeout(() => {
              scrollDisplay = true;
            }, 2000);
          }
          if (scrollProcessing) {
            setTimeout(() => {
              scrollProcessing = false;
            }, 500);
          }
          if (scrollDisplay && !firstLoad && !scrollProcessing) {
            scrollTimeFilter = [];
            scrollDisplay = false;
            setTimeout(() => {
              let maxZoomed = new Date(
                shortDisplayDateTimeHour(ev.target.maxZoomed)
              );
              let minZoomed = new Date(
                shortDisplayDateTimeHour(ev.target.minZoomed)
              );
              let maxZoomedTimestamp = maxZoomed.getTime();
              let minZoomedTimestamp = minZoomed.getTime();
              scrollTimeFilter = [minZoomedTimestamp, maxZoomedTimestamp];
              charts.forEach(item => {
                if (item) {
                  let zoomMinValue =
                    item.chartName == "scrollchart" ? maxZoomed : minZoomed;
                  let zoomMaxValue =
                    item.chartName == "scrollchart" ? minZoomed : maxZoomed;

                  item.xAxes._values[0].zoomToDates(zoomMinValue, zoomMaxValue);
                  if (item.chartName != "scrollchart") {
                    for (let i = 0; i < item.series.length; i++) {
                      item.series.getIndex(i).show();
                    }
                  } else {
                    item.xAxes._values[0].min = min;
                    item.xAxes._values[0].max = max;
                  }
                }
                props.legendShowCallback([]);
                props.legendHideCallback([]);
              });
              scrollProcessing = true;
              props.scrollBarSelectCallback(scrollTimeFilter);
            }, 500);
          }
          if (firstLoad) {
            firstLoad = false;
          }
        }
      });
    }
    else chart.paddingTop = 50;
    chart.zoomOutButton.disabled = true;
    chart.background.fill = colors.gray100;
    chart.tooltip.defaultState.transitionDuration = 100;
    chart.tooltip.hiddenState.transitionDuration = 100;
    chart.layout = "absolute";
    chart.width = am4core.percent(97);
    if (data && data.length === 0) {
      let label = chart.createChild(am4core.Label);
      label.text = i18n.noData;
      label.fontSize = 14;
      label.align = "center";
      label.isMeasured = false;
      label.x = am4core.percent(50);
      label.horizontalCenter = "middle";
      label.y = 20;
    }
    // Create axes
    dateAxis.renderer.baseGrid.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.location = 0.0001;
    dateAxis.strictMinMax = true;

    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = label;
    chart.cursor = new am4charts.XYCursor();
    valueAxis.title.rotation = 0;
    valueAxis.title.align = "center";
    valueAxis.min = 0;
    valueAxis.title.valign = "top";
    valueAxis.title.dy = -30;
    valueAxis.marginLeft = div == "latencyChart" ? -70 : -100;
    valueAxis.title.dx = div == "latencyChart" ? 85 : 118;
    valueAxis.title.fontWeight = "bolder";
    valueAxis.cursorTooltipEnabled = false;

    function createSeries(field, name, color) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.tooltip.defaultState.transitionDuration = 100;
      series.tooltip.hiddenState.transitionDuration = 100;
      series.tooltip.getFillFromObject = false;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.name = name;
      let segment = series.segments.template;
      segment.interactionsEnabled = true;
      series.strokeWidth = 2;
      series.smoothing = "monotoneX";
      series.stroke = am4core.color(color);
      series.fill = am4core.color(color);
      series.smoothing = "monotoneX";
      let hs = segment.states.create("hover");
      hs.properties.strokeWidth = 10;

      series.segments.template.events.on("over", function (ev) {
        let seriesTunnelName = "";
        let eventIds = "";
        let tooltipDate = "";
        if (
          ev.target.series &&
          ev.target.series.tooltipDataItem &&
          ev.target.series.tooltipDataItem.dateX
        ) {
          tooltipDate = shortDisplayDateTimeHour(
            ev.target.series.tooltipDataItem.dateX
          );
        }
        if (
          ev.target &&
          ev.target._children &&
          ev.target._children.values[0] &&
          ev.target._children.values[0]._dataItem &&
          ev.target._children.values[0]._dataItem.component
        ) {
          seriesTunnelName =
            ev.target._children.values[0]._dataItem.component.dataFields.valueY;
        }
        let showEventId = [];
        ev.target.series.dataProvider._data.forEach(data => {
          let dataKeys = Object.keys(data);
          let currentTime =
            scrollTimeFilter && scrollTimeFilter.length > 0
              ? scrollTimeFilter[1]
              : props.max
              ? props.max
              : Date.now();
          let minTime =
            scrollTimeFilter && scrollTimeFilter.length > 0
              ? scrollTimeFilter[0]
              : getRangeFourHours(currentTime);
          if (
            shortDisplayDateTime(tooltipDate) ===
              shortDisplayDateTime(data.date) &&
            seriesTunnelName == dataKeys[1] &&
            isDateInBetween(data.date, minTime, currentTime)
          ) {
            showEventId.push(data.eventId);
          }
        });
        eventIds = String(showEventId);
        if (eventIds && tooltipDate) {
          let tooltipEvents = eventIds.replaceAll(",", " ");
          ev.target.series.tooltip.width = 100;
          ev.target.series.tooltip.background.fill = am4core.color(
            colors.gray100
          );
          let tooltipHTML = `<div class=${css.tooltip}><p class="tooltipDate hbr-type-body4">${tooltipDate}</p>
          <p class="eventIds hbr-type-body4">Events:</p> <p class="eventValue hbr-type-body4">${tooltipEvents} </p> </div>`;
          ev.target.series.tooltip.isActive = true;
          ev.target.series.tooltipHTML = tooltipHTML;
          props.legendShowCallback(showEventId);
        }
      });

      chart.events.on("out", function (ev) {
        ev.target.series.values.forEach(item => {
          if (item && item.tooltip && item.tooltip.isActive) {
            item.tooltip.isActive = false;
            item.tooltipHTML = "";
          }
        });
      });
    }

    for (let i = 0; i < props.legendKeys.length; i++) {
      let legendData = props.legendKeys[i];

      if (legendData) {
        createSeries(legendData, legendData, props.uniqueColor[i][legendData]);
      }
    }
    return chart;
  };

  useEffect(() => {
    let packeLossChart, latencyChart;
    if (props.showLatencyChart) {
      latencyChart = createChart(
        "latencyChart",
        props.data[0].latency,
        i18n.snailTrailLatencyChartTitle
      );

      latencyChart.chartName = "latencyChart";
      charts.push(latencyChart);
    }
    if (props.showPacketLossChart) {
      packeLossChart = createChart(
        "packeLossChart",
        props.data[0].packetLoss,
        i18n.snailTrailPacketLossChartTitle
      );
      packeLossChart.chartName = "packetLoss";
      charts.push(packeLossChart);
      let qoeData =
        props.qoeData && props.qoeData.length == 1
          ? getLineChartDummyData(props.qoeData)
          : props.qoeData;
      let scrollchart = createChart("scrollchartdiv", qoeData);
      scrollchart.chartName = "scrollchart";
      charts.push(scrollchart);
    }

    if (packeLossChart) {
      packeLossChart.legend = new am4charts.Legend();
      packeLossChart.legend.useDefaultMarker = true;
      let marker = packeLossChart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 1;
      marker.stroke = am4core.color(colors.gray80);
      let legendContainer = am4core.create("legenddiv", am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      packeLossChart.legend.parent = legendContainer;
      let markerTemplate = packeLossChart.legend.markers.template;
      markerTemplate.width = 17;
      markerTemplate.height = 3;

      if (latencyChart) {
        for (let i = 0; i < packeLossChart.series.length; i++) {
          let series = packeLossChart.series.getIndex(i);
          series.events.on("hidden", function (ev) {
            let index = packeLossChart.series.indexOf(ev.target);
            let tunnelName = latencyChart.series.getIndex(index).dataFields
              .valueY;

            let currentTime =
              scrollTimeFilter && scrollTimeFilter.length > 0
                ? scrollTimeFilter[1]
                : props.max
                ? props.max
                : Date.now();
            let minTime =
              scrollTimeFilter && scrollTimeFilter.length > 0
                ? scrollTimeFilter[0]
                : getRangeFourHours(currentTime);
            ev.target.chart.data.forEach(data => {
              let dataKeys = Object.keys(data);
              if (
                tunnelName == dataKeys[1] &&
                isDateInBetween(data.date, minTime, currentTime)
              ) {
                eventsList.push(data.eventId);
              }
            });
            props.legendShowCallback([]);
            props.legendHideCallback(eventsList);
            latencyChart.series.getIndex(index).hide();
          });

          series.events.on("shown", function (ev) {
            let index = packeLossChart.series.indexOf(ev.target);
            let tunnelName = latencyChart.series.getIndex(index).dataFields
              .valueY;
            let currentTime =
              scrollTimeFilter && scrollTimeFilter.length > 0
                ? scrollTimeFilter[1]
                : props.max
                ? props.max
                : Date.now();
            let minTime =
              scrollTimeFilter && scrollTimeFilter.length > 0
                ? scrollTimeFilter[0]
                : getRangeFourHours(currentTime);
            ev.target.chart.data.forEach(data => {
              let dataKeys = Object.keys(data);
              if (
                tunnelName == dataKeys[1] &&
                isDateInBetween(data.date, minTime, currentTime)
              ) {
                let eventIndex = eventsList.indexOf(data.eventId);
                if (index > -1) {
                  eventsList.splice(eventIndex, 1);
                }
              }
            });
            props.legendShowCallback([]);
            props.legendHideCallback(eventsList);
            latencyChart.series.getIndex(index).show();
          });
        }
      }
    } else {
      if (latencyChart) {
        latencyChart.legend = new am4charts.Legend();
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div
        id="legenddiv"
        className={
          props.legendKeys && props.legendKeys.length > 3
            ? css.legendLarge
            : css.legend
        }
      ></div>
      <div className={`${css["single-legend-chart-default"]} hbr-type-body4`}>
        <div id="packeLossChart"></div>
        <div id="latencyChart"></div>
      </div>
      <div className={`${css["single-legend-chart-scroll"]} hbr-type-body4`}>
        <div className="hbr-type-body4 single-legend-chart-sroll-label">
          {i18n.snailTrailQoEChartTitle}
        </div>
        <div
          id="scrollchartdiv"
          className="scroll-chart-div-style hbr-type-body4"
        ></div>
      </div>
    </React.Fragment>
  );
};

SingleLegendChart.propTypes = {
  callbackFromParent: PropTypes.func,
  usageApiCallbackFromParent: PropTypes.func,
  scrollTimeFilter: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  qoeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scrollBarSelectCallback: PropTypes.func,
  legendShowCallback: PropTypes.func,
  legendHideCallback: PropTypes.func,
  showPacketLossChart: PropTypes.string,
  showLatencyChart: PropTypes.string,
  legendKeys: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  uniqueColor: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

SingleLegendChart.defaultProps = {
  min: undefined,
  showPacketLossChart: true,
  showLatencyChart: true,
  data: []
};

export default SingleLegendChart;
