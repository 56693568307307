import { getTimePeriodELT, formatDate, shortDisplayDate24HourWithSec } from "../../utils/displayTime";

//Get top 5 applications by bandwidth
export const topApplicationsByBandwidth = {
  aggregation: {
    field: [{ property: "application", sequence: "1" }],
    metrics: [{ property: "octets_sum", type: "sum" }]
  },
  fields: ["application"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      { field: "site_id", operator: "in", value: [] }
    ]
  },
  size: 5,
  sort: [{ order: "desc", property: "octets_sum" }]
};

//Get Qoe and badwidth for top 5 apps
export const topApplicationMetrics = {
  aggregation: {
    field: [{ property: "application", sequence: "1" }],
    histogram: { order: "asc", type: "hour" },
    metrics: [
      { property: "entry_time", type: "min" },
      { property: "octets_sum", type: "sum" },
    ]
  },
  fields: ["application"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "application",
        operator: "in",
        value: []
      },
      { field: "site_id", operator: "in", value: [] }
    ]
  },
  size: 4000,
  sort: [{ order: "asc", property: "entry_time" }]
};

export const topApplicationQoeMetrics = {
  aggregation: {
    field: [{ property: "application", sequence: "1" }],
    histogram: { order: "asc", type: "hour" },
    metrics: [
      { property: "entry_time", type: "min" },
      { property: "vqoe_score", type: "avg" }
    ]
  },
  fields: ["application"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: []
      },
      {
        field: "application",
        operator: "in",
        value: []
      },
      { field: "site_id", operator: "in", value: [] }
    ]
  },
  size: 4000,
  sort: [{ order: "asc", property: "entry_time" }]
};
export const customListRequestObject = timeFilter => {
  return { ...timeFilter };
};

const timePeriodArray = getTimePeriodELT('720');

export const appsFilter = {
  time_frame: "30d",
  entry_ts: {
    start: shortDisplayDate24HourWithSec(formatDate(timePeriodArray.current_period[0]).utc()),
    end: shortDisplayDate24HourWithSec(formatDate(timePeriodArray.current_period[1]).utc())
  },
  sort: {"application": "asc"},
  fields: ["application"]
};
