import Accounts from "../views/accounts";
import Overview from "../views/overview";
import Sites from "../views/sites";
import Applications from "../views/applications";
import App360 from "../views/app360";
import Circuits from "../views/circuits";
import Circuit360 from "../views/circuit360";
import PredictiveNetworks from "../views/insights";
import PNApplicationsInfo from "../views/insightsApplicationInfo";
import PNApplicationInfoAtSite
  from "../views/insightsApplicationInfo/applicationInfoPerSingleSiteView";
import PNApplicationInfoPathMetric
  from "../views/insightsApplicationInfo/pathMetricView";
import AnomalyDetection
  from "../views/insights/PredictiveWidgetTop/AnomalyDetection";
import AnomalyDetectionKpi
  from "../views/insights/PredictiveWidgetTop/AnomalyDetection/KpiView/kpiRoute";
import AnomalyDetectionKpiTrend
  from "../views/insights/PredictiveWidgetTop/AnomalyDetection/KpiTrendView";
import ThreatIntelligence from "../views/security";
import Reports from "../views/reports/reportsTabListView";
import ReportCreateScope from "../views/reports/generateReportWorkflow/scope";
import ReportCreateType from "../views/reports/generateReportWorkflow/filetype";
import ReportCreateSchedule from "../views/reports/generateReportWorkflow/schedule";
import ReportCreateEmail from "../views/reports/generateReportWorkflow/delivery";
import ReportCreateSummary from "../views/reports/generateReportWorkflow/summary";
import ReportEditScope from "../views/reports/editReportWorkflow/scope";
import ReportEditType from "../views/reports/editReportWorkflow/filetype";
import ReportEditSchedule from "../views/reports/editReportWorkflow/schedule";
import ReportEditEmail from "../views/reports/editReportWorkflow/delivery";
import ReportEditSummary from "../views/reports/editReportWorkflow/summary";
import ReportOverview from "../views/reports/reportTemplates/executiveSummary/reportConfig";
import ReportSites from "../views/reports/reportTemplates/siteSummary/reportConfig";
import ReportApps from "../views/reports/reportTemplates/applicationSummary/reportConfig";
import ReportApp360 from "../views/reports/reportTemplates/app360Summary/reportConfig";
import ReportCSV from "../views/reports/reportTemplates/csvReport/csvReportsConfig";
import { BASE_URL, BASE_REPORT_URL } from "../apis/apiConstants";
import Logs from "../views/logs";

/* routes object with route path, module and access
 * access - authorization - default value true
 * access - admin - default value false
 * access - sidebar - default value true
*/
export const routes = {
  // landing page
  login: {
    path: `${BASE_URL}/login`,
    module: null,
    access: { authorization: false, sidebar: false }
  },
  // landing page
  accounts: {
    path: `${BASE_URL}/accounts`,
    module: Accounts,
    access: { authorization: false, sidebar: false }
  },
  // home dashboard
  overview: { path: `${BASE_URL}/overview`, module: Overview },
  // sites dashboard
  sites: { path: `${BASE_URL}/sites`, module: Sites },
  // applications dashboard
  applications: { path: `${BASE_URL}/applications`, module: Applications },
  app360: { path: `${BASE_URL}/Dashboards/:appName`, module: App360 },
  // circuit
  circuits: { path: `${BASE_URL}/circuits`, module: Circuits },
  circuit360: {
    path: `${BASE_URL}/circuits/:circuitName`,
    module: Circuit360
  },
  // predictive networks
  pn: { path: `${BASE_URL}/predictivenetworks`, module: PredictiveNetworks },
  appInfo: {
    path: `${BASE_URL}/predictivenetworks/applicationinformation`,
    module: PNApplicationsInfo
  },
  appInfoAtSite: {
    path: `${BASE_URL}/predictivenetworks/applicationinformation/site`,
    module: PNApplicationInfoAtSite
  },
  appInfoPathMetric: {
    path: `${BASE_URL}/predictivenetworks/applicationinformation/device`,
    module: PNApplicationInfoPathMetric
  },
  ad: {
    path: `${BASE_URL}/predictivenetworks/anomalydetection`,
    module: AnomalyDetection
  },
  adKPI: {
    path: `${BASE_URL}/predictivenetworks/anomalyDetection/:kpiType`,
    module: AnomalyDetectionKpi
  },
  adKPITrend: {
    path: `${BASE_URL}/predictivenetworks/anomalyDetection/:kpiType/:tunnelName`,
    module: AnomalyDetectionKpiTrend
  },
  // threat intelligence aka security
  threatIntelligence: {
    path: `${BASE_URL}/threatintelligence`,
    module: ThreatIntelligence
  },
  //traffic logs
  trafficLogs: {
    path: `${BASE_URL}/traffic-logs`,
    module: Logs
  },
  // reports
  reports: { path: `${BASE_URL}/reports`, module: Reports, access: { admin: true } },
  // report creation
  reportCreateScope: {
    path: `${BASE_URL}/report/setup-report-scope`,
    module: ReportCreateScope,
    access: { admin: true, sidebar: false }
  },
  reportCreateType: {
    path: `${BASE_URL}/report/setup-filetype`,
    module: ReportCreateType,
    access: { admin: true, sidebar: false }
  },
  reportCreateSchedule: {
    path: `${BASE_URL}/report/setup-report-scheduler`,
    module: ReportCreateSchedule,
    access: { admin: true, sidebar: false }
  },
  reportCreateEmail: {
    path: `${BASE_URL}/report/setup-report-email-delivery`,
    module: ReportCreateEmail,
    access: { admin: true, sidebar: false }
  },
  reportCreateSummary: {
    path: `${BASE_URL}/report/setup-report-summary`,
    module: ReportCreateSummary,
    access: { admin: true, sidebar: false }
  },
  // report edit
  reportEditScope: {
    path: `${BASE_URL}/report/:id/setup-report-scope`,
    module: ReportEditScope,
    access: { admin: true, sidebar: false }
  },
  reportEditType: {
    path: `${BASE_URL}/report/:id/setup-filetype`,
    module: ReportEditType,
    access: { admin: true, sidebar: false }
  },
  reportEditSchedule: {
    path: `${BASE_URL}/report/:id/setup-report-scheduler`,
    module: ReportEditSchedule,
    access: { admin: true, sidebar: false }
  },
  reportEditEmail: {
    path: `${BASE_URL}/report/:id/setup-report-email-delivery`,
    module: ReportEditEmail,
    access: { admin: true, sidebar: false }
  },
  reportEditSummary: {
    path: `${BASE_URL}/report/:id/setup-report-summary`,
    module: ReportEditSummary,
    access: { admin: true, sidebar: false }
  },
  // pdf reports
  reportOverview: {
    path: `${BASE_URL}${BASE_REPORT_URL}:id/executive_summary`,
    module: ReportOverview,
    access: { sidebar: false }
  },
  reportSites: {
    path: `${BASE_URL}${BASE_REPORT_URL}:id/site_summary`,
    module: ReportSites,
    access: { sidebar: false }
  },
  reportApps: {
    path: `${BASE_URL}${BASE_REPORT_URL}:id/application_summary`,
    module: ReportApps,
    access: { sidebar: false }
  },
  reportApp360: {
    path: `${BASE_URL}${BASE_REPORT_URL}:id/application_summary/:appName`,
    module: ReportApp360,
    access: { sidebar: false }
  },
  reportCSV: {
    path: [
      `${BASE_URL}${BASE_REPORT_URL}:id/all_sites_csv`,
      `${BASE_URL}${BASE_REPORT_URL}:id/all_apps_csv`,
      `${BASE_URL}${BASE_REPORT_URL}:id/app360_csv`
    ],
    module: ReportCSV,
    access: { sidebar: false }
  }
};

for (const key in routes) {
  const route = routes[key];
  const access = { authorization: true, admin: false, sidebar: true };
  route.access = Object.assign(access, route.access);
  route.id = key;
}

const modules = Object.values(routes).slice(1);

export default modules;
