import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { timeUnits, getTipGanttChart } from "../../utils/common";
import {
  shortDisplayDateTime,
  shortDisplayDateTimeHour,
  isDateInBetween
} from "../../utils/displayTime";
import { addScrollBar, customizeTooltip } from "../../utils/chart";
import css from "../commonMagneticStyle.less";
import { chartPrimaryColors, colors } from "../../utils/colors";

const multiLineChart = "multiLineChart";
const ganttChart = "ganttChart";

const BulletColumnAndLineChart = props => {
  const { chartsData } = props;
  const {
    title,
    zoomInDaysCount,
    rightTitleForY,
    leftTitleForY,
    chartId,
    isSeriesConnected,
    tooltipHTMLFuture,
    tooltipHTML
  } = props.chartProps;
  const chartsRef = useRef(chartsData);
  const currentChartRef = useRef();
  am4core.useTheme(am4themes_animated);

  useEffect(() => {
    chartsRef.current = props.chartsData;
  }, [props.chartsData]);

  const createBulletColumnLineChart = (id, data) => {
    let series3;

    // create chart
    const chart = am4core.create(id, am4charts.XYChart);
    chart.id = id;
    // set chart data
    chart.data = data || [];
    chart.fontWeight = 400;
    chart.fontFamily = "Inter";

    if (props.isTitleShown) {
      const topContainer = chart.chartContainer.createChild(am4core.Container);
      topContainer.layout = "relative";
      topContainer.toBack();
      topContainer.paddingBottom = 10;
      topContainer.height = am4core.percent(50);
      topContainer.fontFamily = "Sharp Sans";

      const dateTitle = topContainer.createChild(am4core.Label);
      dateTitle.text = title;
      dateTitle.fontWeight = 700;
      dateTitle.align = "left";
      dateTitle.fontSize = 18;
    }

    // set date axis as x axis
    const dateAxisX = chart.xAxes.push(new am4charts.DateAxis());
    // set grid location from middle to start of the interval
    dateAxisX.renderer.grid.template.location = 0;
    dateAxisX.renderer.labels.template.location = 0.0001;
    // set time interval for plotting graph data points
    dateAxisX.baseInterval = { count: 1, timeUnit: "hour" };

    const timeUnit = dateAxisX.baseInterval.timeUnit;
    const unitCount = dateAxisX.baseInterval.count;
    chart.intervalMiliSecs =
      timeUnits.find(n => n.name === timeUnit)["timestamp"] * unitCount;

    // zoom in when chart is ready
    if (!props.isZoomDisabled && data.length > 0) {
      chart.zoomOutButton.disabled = true;
      const maxDate = data[data.length - 1].date;
      const startDate = new Date(maxDate);
      startDate.setDate(startDate.getDate() - zoomInDaysCount);
      chart.events.on("ready", () => {
        dateAxisX.zoomToDates(startDate, new Date(maxDate));
      });
    }

    // set value axis as y axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // set y axis label
    valueAxis.title.text = rightTitleForY;
    valueAxis.title.userClassName = "y-axis-title";
    // disable tooltip of y axis data points
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.strictMinMax = true;

    // add graph line to chart
    const series1 = chart.series.push(new am4charts.LineSeries());
    // set graph line name
    series1.name = props.chartProps.legendLabels[1];
    // set x axis property name
    series1.dataFields.dateX = "date";
    // set y axis property name
    series1.dataFields.valueY = "defaultQuality";
    // customize tooltip
    customizeTooltip(series1.tooltip, true);
    series1.tooltipHTML = tooltipHTML;
    // set graph line stroke style
    series1.strokeWidth = 2;
    series1.stroke = colors.indigo60;
    series1.tensionX = 0.7;
    series1.tensionY = 1;
    series1.smoothing = "monotoneX";
    series1.connect = isSeriesConnected;

    series1.yAxis = valueAxis;
    series1.fill = colors.indigo60;

    // Prevent cross-fading of tooltips
    series1.tooltip.defaultState.transitionDuration = 0;
    series1.tooltip.hiddenState.transitionDuration = 0;

    // add graph line to chart
    const series2 = chart.series.push(new am4charts.LineSeries());
    // set graph line name
    series2.name = props.chartProps.legendLabels[0];
    // set x axis property name
    series2.dataFields.dateX = "date";
    // set y axis property name
    series2.dataFields.valueY = "recommendationQuality";
    // customize tooltip
    customizeTooltip(series2.tooltip, true);
    // set graph line stroke style
    series2.strokeWidth = 2;
    series2.strokeDasharray = "3,3";
    series2.stroke = colors.rose40;
    series2.tensionX = 0.7;
    series2.tensionY = 1;
    series2.smoothing = "monotoneX";
    series2.connect = isSeriesConnected;

    series2.yAxis = valueAxis;
    series2.fill = colors.rose40;
    series2.fixedWidthGrid = true;

    if (props.chartProps.isBandwidthForecast) {
      series2.tooltipHTML = tooltipHTMLFuture;
      series1.numberFormatter.numberFormat = "#.0a'bps'";

      // add graph line to chart
      const series3 = chart.series.push(new am4charts.LineSeries());
      // set graph line name
      series3.name = props.chartProps.legendLabels[2];
      // set x axis property name
      series3.dataFields.dateX = "date";
      // set y axis property name
      series3.dataFields.valueY = "min";
      series3.dataFields.openValueY = "max";
      // set graph line stroke style
      series3.tensionX = 0.7;
      series3.tensionY = 1;
      series3.smoothing = "monotoneX";
      series3.fill = colors.rose40;
      series3.fillOpacity = 0.15;
      series3.connect = isSeriesConnected;
      series3.sequencedInterpolation = true;
      series3.defaultState.transitionDuration = 1000;

      // add graph line to chart
      const series4 = chart.series.push(new am4charts.LineSeries());
      // set x axis property name
      series4.dataFields.dateX = "date";
      // set y axis property name
      series4.dataFields.valueY = "min";
      // set graph line stroke style
      series4.strokeWidth = 5;
      series4.stroke = colors.gray100;
      series4.tensionX = 0.7;
      series4.tensionY = 1;
      series4.smoothing = "monotoneX";
      series4.connect = isSeriesConnected;
      series4.sequencedInterpolation = true;
      series4.defaultState.transitionDuration = 1000;
    } else {
      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = `[var(--hbr-color-neutral-text-weak, #656c75)] ${leftTitleForY}`;
      valueAxis2.renderer.opposite = true;
      valueAxis2.cursorTooltipEnabled = false;
      series3 = chart.series.push(new am4charts.ColumnSeries());
      series3.dataFields.valueY = "totalNumUsers";
      series3.dataFields.dateX = "date";
      series3.yAxis = valueAxis2;
      series3.name = props.chartProps.legendLabels[2];
      series3.fontFamily = "Inter";
      series3.tensionX = 0.7;
      series3.tensionY = 1;
      series3.smoothing = "monotoneX";
      series3.fill = colors.gray80;
      series3.fillOpacity = 0.8;
      series3.strokeWidth = 0;
      series3.clustered = false;
      series3.columns.template.width = am4core.percent(70);

      series3.toBack();
    }

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.position = "absolute";
    chart.legend.contentAlign = "right";

    if (props.chartProps.isBandwidthForecast) {
      chart.legend.x = 60;
    } else {
      chart.legend.x = 10;
    }

    if (props.isTitleShown) {
      chart.legend.itemContainers.template.paddingBottom = 0;
    } else {
      chart.legend.itemContainers.template.paddingBottom = 40;
    }

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    markerTemplate.fontFamily = "Inter";

    chart.legend.labels.template.text =
      "[var(--hbr-color-neutral-text-weak, #656c75)] {name}";

    // add scroll bar
    addScrollBar(chart);
    chart.scrollbarX.series.push(series2);
    chart.scrollbarX.disabled = props.disabledScrollbar;
    if (!props.chartProps.isBandwidthForecast) {
      chart.scrollbarX.series.push(series3);
    }
    if (props.isScrollbarGripVisible) {
      chart.scrollbarX.startGrip.visible = false;
      chart.scrollbarX.endGrip.visible = false;
    }

    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    //disable the x axis cursorTooltip
    chart.cursor.xAxis = dateAxisX;
    chart.cursor.xAxis.cursorTooltipEnabled = false;
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.strokeWidth = 2;

    /* Zoom events
      with endchanged event we can dynamically scroll bar and
      manipulate other charts at the same time
      we can scroll left and right and other charts will
      zoom to new dates based on your scroll
    */
    dateAxisX.events.on("endchanged", function syncZoom(ev) {
      if (
        ev &&
        ev.target &&
        ev.target.chart &&
        ev.target.chart.xAxes &&
        props.chartsData
      ) {
        props.chartsData.forEach(item => {
          if (item.ignoreZoom) {
            item.ignoreZoom = false;
          }
          if (item)
            if (
              item.chartType === ganttChart ||
              item.chartType === multiLineChart
            ) {
              item.ignoreZoom = true;
              item?.xAxes?._values[0]?.zoomToDates(
                new Date(shortDisplayDateTimeHour(ev.target.minZoomed)),
                new Date(shortDisplayDateTimeHour(ev.target.maxZoomed)),
                false,
                true // this makes zoom instant
              );
            }
        });
      }
    });

    chart.events.on("ready", () => {
      chart.cursor.events.on("cursorpositionchanged", function (ev) {
        if (
          ev &&
          ev.target &&
          ev.target.chart &&
          ev.target.chart.xAxes &&
          props.chartsData
        ) {
          let xAxis = ev.target.chart.xAxes.getIndex(0);
          let tooltipDate = xAxis.positionToDate(
            xAxis.toAxisPosition(ev.target.xPosition)
          );
          let isLineChartShown = true;

          props.chartsData.forEach(item => {
            let tooltipHTML = "";
            if (item.data) {
              item.data.forEach(data => {
                if (data.fromDate) {
                  let isDteInBetween = isDateInBetween(
                    tooltipDate.valueOf(),
                    data.fromDate,
                    data.toDate
                  );

                  if (isDteInBetween) {
                    if ("currentPaths" in data) {
                      // check if item.data belongs to gantt chart
                      if (data.hasGanttChartData) {
                        // create a tooltip for gantt chart
                        tooltipHTML = getTipGanttChart(data);
                        isLineChartShown = true;
                      } else {
                        //dont show tooltip for gantt and bullet chart when no data
                        tooltipHTML = "";
                        isLineChartShown = false;
                      }
                    } else if (data.hasBulletColumnChartData) {
                      //show line chart tooltip if bullet chart has data
                      isLineChartShown = true;
                    }
                  }
                } else if (data.date && isLineChartShown) {
                  // check if item.data belongs to line chart
                  if (
                    shortDisplayDateTime(tooltipDate.valueOf() - 3600000) ===
                    shortDisplayDateTime(data.date)
                  ) {
                    // create tooltip for single or multi line chart
                    let title = item.yAxes.values[0].title.text;
                    let valueKeys = Object.keys(data);
                    valueKeys.splice(valueKeys.indexOf("date"), 1);

                    let value = valueKeys.map((key, i) => {
                      return `
                      <span class='dot' style='background-color:${chartPrimaryColors[i]
                        }'></span>
                      <span >${data && data[key] && parseInt(data[key])}${title.includes("Loss") ? "%" : "ms"
                        }</span><br/>`;
                    });
                    tooltipHTML = `<div>
                      <p>${value.join("")}</p></div>
                      `;
                  }
                }
              });
            }

            if (tooltipHTML) {
              item.tooltipX = ev.target.point.x;
              item.tooltipY = ev.target.point.y;
              if (item.tooltip) {
                try {
                  item.tooltip.isActive = true;
                } catch (e) {
                  // error
                }

                // disable default tooltip style and set custom tooltip style
                item.tooltip.getFillFromObject = false;
                item.tooltip.background.fill = am4core.color(colors.gray100);
                item.tooltip.label.paddingTop = 12;
                item.tooltip.label.paddingRight = 16;
                item.tooltip.label.paddingBottom = 12;
                item.tooltip.label.paddingLeft = 16;
                item.tooltip.zIndex = Infinity;

                //tooltip orientation
                item.tooltip.pointerOrientation = "left";

                // set tooltip border style
                item.tooltip.background.strokeWidth = 2;
                item.tooltip.background.stroke = am4core.color(colors.gray45);
                item.tooltip.background.cornerRadius = 6;

                // set tooltip shadow style
                const shadow = item.tooltip.background.filters.getIndex(0);
                if (shadow) {
                  shadow.dy = 4;
                  shadow.blur = 12;
                  shadow.color = am4core.color(colors.gray0);
                  shadow.opacity = 0.18;
                }
              }

              item.tooltipHTML = tooltipHTML;
            }
          });
        }
      });

      chart.events.on("out", function () {
        if (props.showSynchronization && props.chartsData) {
          props.chartsData.forEach(item => {
            if (item && item.tooltip) {
              item.tooltipHTML = "";
              item.tooltipX = "";
              item.tooltipY = "";
            }
          });
        }
      });
    });

    chart.chartType = "bulletColumnChart";
    return chart;
  };

  useEffect(() => {
    if (currentChartRef.current) {
      currentChartRef.current.dispose();
    }
    const chart2Obj = createBulletColumnLineChart(chartId, props.data);

    return () => {
      chart2Obj.dispose();
      chart2Obj.events.disable();
    };
  }, [props.data]);

  useEffect(() => {
    return () => {
      if (currentChartRef.current) {
        currentChartRef.current.dispose();
      }
      if (props.enableDisposeAllCharts) {
        am4core.disposeAllCharts();
      }
    };
  }, []);

  return (
    <div className={css["bullet-column-chart"]}>
      <div
        id={chartId}
        className="bulletColumnAndLineChart"
        style={{
          paddingTop: props.isTitleShown ? "0px" : "55px",
          ...props.chartProps.backgroundStyle
        }}
      ></div>
    </div>
  );
};

BulletColumnAndLineChart.propTypes = {
  chartsData: PropTypes.array.isRequired,
  chartProps: PropTypes.object.isRequired,
  isTitleShown: PropTypes.bool,
  callbackFromParent: PropTypes.func,
  data: PropTypes.array,
  showSynchronization: PropTypes.bool,
  isScrollbarGripVisible: PropTypes.bool,
  disabledScrollbar: PropTypes.bool,
  isZoomDisabled: PropTypes.bool,
  isLegendShown: PropTypes.bool,
  backgroundStyle: PropTypes.object,
  enableDisposeAllCharts: PropTypes.bool
};

BulletColumnAndLineChart.defaultProps = {
  enableDisposeAllCharts: true,
  callbackFromParent: () => null,
  backgroundStyle: {}
};

export default BulletColumnAndLineChart;
