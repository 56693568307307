import axios from "axios";
import * as apiConstant from "../apis/apiConstants";
import customOktaConfig from "./okta-config";
import { createGenericErrorObject } from "../utils/common";
import { getFormattedUTCDate } from "../utils/displayTime";
import { BASE_API_URL } from "../apis/apiConstants";

const ApiService = {
  getTrendCircuitsAvailability: async (payload) => {
    try {
      return await axios.post(
        apiConstant.circuitUrls.trendAvailability,
        payload
      );
    } catch (e) {
      console.log("Error while calling TREND_CIRCUITS_AVAILABILITY_URL ", e);
      return createGenericErrorObject(e);
    }
  },
  getSmartAccountData: async (csrfToken) => {
    try {
      const headers = csrfToken
        ? { headers: { "X-CSRFToken": csrfToken } }
        : {};
      return await axios.get(apiConstant.SMART_ACC_URL, headers);
    } catch (e) {
      console.log("Error while calling SMART_ACC_URL ", e);
    }
  },
  getCsrfToken: async (csrfToken) => {
    try {
      const headers =
        csrfToken ? { headers: { "X-CSRFToken": csrfToken } } : {};
      return await axios.get(apiConstant.CRSF_TOKEN_URL, headers);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },
  getSupportRoleAccounts: async () => {
    try {
      return await axios.get(apiConstant.SMART_ACC_FILTER_URL);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },
  getDomainAccountInfo: async (overlayId) => {
    try {
      return await axios.get(
        `${BASE_API_URL}metaservice/smartAccount/info?sa_domain=${overlayId}`
      );
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },
  getAggregateData: async (query, widgetKey) => {
    try {
      if (widgetKey === "app360_tunnel_list") {
        return await axios.post(`${apiConstant.APP360_APPFLOW_URL}`, query);
      } else {
        return await axios.post(
          widgetKey
            ? `${apiConstant.AGGREGATE_DATA_WITH_WIDGET_KEY_URL}${widgetKey}/aggregate`
            : apiConstant.AGGREGATE_DATA_URL,
          query
        );
      }
    } catch (e) {
      console.log("Error while calling get aggregate data ", e);
      return createGenericErrorObject(e);
    }
  },
  getTunnelData: async (query) => {
    try {
      return await axios.post(apiConstant.TUNNEL_DATA_URL, query);
    } catch (e) {
      console.log("Error while calling get tunnel", e);
    }
  },
  getSummaryData: async (filter, overlayId = null) => {
    const headers = overlayId
      ? {
        headers: {
          "SDWAN-Overlay": overlayId,
        },
      }
      : {};

    try {
      return await axios.post(apiConstant.SUMMARY_DATA_URL, filter, headers);
    } catch (e) {
      console.log("Error while calling get summary data ", e);
    }
  },
  getFilterViewApps: async (filter) => {
    try {
      return await axios.post(apiConstant.FILTERVIEW_APPS_URL, filter);
    } catch (e) {
      console.log("Error while calling get Filter View Apps ", e);
      return createGenericErrorObject(e);
    }
  },
  getTopApplicationsDonut: async (filter) => {
    try {
      return await axios.post(apiConstant.TOP_APP_DONUT_URL, filter);
    } catch (e) {
      console.log("Error while calling get top Apps Donut ", e);
      return createGenericErrorObject(e);
    }
  },
  getTopApplicationsStacked: async (filter) => {
    try {
      return await axios.post(apiConstant.TOP_APP_STACKED_URL, filter);
    } catch (e) {
      console.log("Error while calling get top Apps Stacked ", e);
      return createGenericErrorObject(e);
    }
  },
  getTopDevices: async (globalV4Payload) => {
    try {
      const payload = {
        ...globalV4Payload,
        size: 5,
        sort: { change_percentage: "desc", prev_up_percent: "asc" }
      };
      return await axios.post(apiConstant.TOP_DEVICE_URL, payload);
    } catch (e) {
      console.log("Error while calling get Top Devices ", e);
      return createGenericErrorObject(e);
    }
  },
  getTopUsers: async globalV4Payload => {
    try {
      const payload = {
        ...globalV4Payload,
        size: 5,
        sort: { usage: "desc" }
      };
      return await axios.post(apiConstant.TOP_USERS_URL, payload);
    } catch (e) {
      console.log("Error while calling get top users ", e);
      return createGenericErrorObject(e);
    }
  },
  getAltoAppClassList: async (overlayId) => {
    const headers = overlayId
      ? {
        headers: {
          "SDWAN-Overlay": overlayId,
        },
      }
      : {};
    try {
      return await axios.get(apiConstant.ALTO_CLASS_APP_LIST, headers);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoEndpoints: async (query, overlayId) => {
    try {
      return await axios({
        url: apiConstant.ALTO_ENDPOINT_SITE_ID,
        method: "get",
        params: query,
        headers: { "SDWAN-Overlay": overlayId },
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoEndpointRecommendationAction: async (query, overlayId) => {
    const headers = {
      "SDWAN-Overlay": overlayId,
    };
    try {
      return await axios({
        url: apiConstant.ALTO_ENDPOINT_ACTION,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoRecoEndpointMetrics: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        url: apiConstant.ALTO_RECO_METRICS,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoPaths: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        url: apiConstant.ALTO_RECOM_METRICS_PATHS,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoNetworkPaths: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        url: apiConstant.ALTO_NETWORK_PATHS,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoPathsDetailed: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        url: apiConstant.ALTO_PATHS_DETAILS,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAltoPathMetrics: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        url: apiConstant.ALTO_PATH_METRICS,
        method: "get",
        params: query,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAppCountByQoe: async (filter) => {
    try {
      return await axios.post(apiConstant.APP_COUNT_BY_QOE_URL, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getTableCustomList: async (filter) => {
    try {
      return await axios.post(apiConstant.TABLE_CUSTOMLIST_URL, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getApplicationTableList: async (filter) => {
    try {
      return await axios.post(apiConstant.APPLICATION_TABLE_LIST_URL, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getApp360TopUsers: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        headers: {
          "SDWAN-Overlay": overlayId,
        },
      }
      : {};

    try {
      return await axios.post(
        apiConstant.APP360_TOP_USERS_URL,
        filter,
        headers
      );
    } catch (e) {
      console.log("Error while calling app 360 top users ", e);
      return createGenericErrorObject(e);
    }
  },
  getApp360AppFlow: async (filter) => {
    try {
      return await axios.post(apiConstant.APP360_APPFLOW_URL, filter);
    } catch (e) {
      console.log("Error while calling app 360 app flow ", e);
      return createGenericErrorObject(e);
    }
  },
  getqoeDistrubution: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        method: "post",
        url: apiConstant.QOE_DISTRIBUTION_URL,
        data: filter,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getTrendApplicationByRiseOrDrop: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};
    //if api_key is vqoe then the api returns trend apps by drop
    //if api_key is usage then the api returns trend apps by rise
    const api_key = filter.api_key ? filter.api_key : "usage";
    const payload = filter.timeFilter ? filter.timeFilter : filter;

    try {
      return await axios({
        method: "post",
        url: `${apiConstant.TREND_APP_BY_RISE_OR_DROP_URL}${api_key}`,
        data: payload,
        headers: headers,
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getMapViewSitesOptimized: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        headers: {
          "SDWAN-Overlay": overlayId,
        },
      }
      : {};

    try {
      return await axios.post(apiConstant.OP_MAP_BY_SITES_URL, filter, headers);
    } catch (e) {
      console.log("Error while calling get Map View Sites ", e);
      return createGenericErrorObject(e);
    }
  },
  getMapViewSiteDistributions: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        headers: {
          "SDWAN-Overlay": overlayId,
        },
      }
      : {};

    try {
      return await axios.post(
        apiConstant.MAP_VIEW_DISTRIBUTION_URL,
        filter,
        headers
      );
    } catch (e) {
      console.log("Error while calling get Map View Sites distributions ", e);
    }
  },
  getSites: async () => {
    try {
      const date = Date.now();
      const payload = {
        entry_ts: {
          start: getFormattedUTCDate(date - (12 * 3600000)),
          end: getFormattedUTCDate(date)
        },
        sort: { site_name: "asc" }
      };
      return await axios.post(apiConstant.SITES_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getApplications: async (query) => {
    try {
      return await axios.post(apiConstant.APP_PERF_URL, query);
    } catch (e) {
      console.log("Error while calling get Applications ", e);
    }
  },
  getSitesTableTimeAvailability: async (query) => {
    try {
      return await axios({
        method: "post",
        url: apiConstant.SITES_TABLE_AVAILABILITY_TIMEFRAME,
        data: query,
      });
    } catch (e) {
      console.log("Error while calling get sites table availability ", e);
      return createGenericErrorObject(e);
    }
  },
  getReportData: async (query) => {
    try {
      return await axios.post(apiConstant.REPORT_DATA_URL, query);
    } catch (e) {
      console.log("Error while calling get report data ", e);
    }
  },
  getAllReports: async (overlayId) => {
    try {
      return await axios.get(`${apiConstant.ALL_REPORTS_URL}${overlayId}`);
    } catch (e) {
      console.log("Error while calling get all reports ", e);
    }
  },
  deleteReport: async (report_uuid) => {
    try {
      return await axios.delete(
        `${apiConstant.DELETE_OR_GET_REPORT_FROM_ID_URL}${report_uuid}`
      );
    } catch (e) {
      console.log("Error while calling delete report ", e);
    }
  },
  getReportByUUID: async (reportUUID) => {
    try {
      return await axios.get(
        `${apiConstant.DELETE_OR_GET_REPORT_FROM_ID_URL}${reportUUID}`
      );
    } catch (e) {
      console.log("Error calling get report by id ", e);
      return createGenericErrorObject(e);
    }
  },
  editReport: async (query) => {
    try {
      return await axios.put(apiConstant.GENERATE_EDIT_REPORT_URL, query);
    } catch (e) {
      console.log("Error while calling edit report ", e);
    }
  },
  getAllReportTemplates: async () => {
    try {
      return await axios.get(apiConstant.ALL_TEMPLATES_URL);
    } catch (e) {
      console.log("Error while calling get all report templates ", e);
    }
  },
  getQoEUsageAndDetails: async (filter, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        method: "post",
        url: apiConstant.QOE_USAGE_DETAILS_URL,
        data: filter,
        headers: headers,
      });
    } catch (e) {
      console.log("Error while calling get Qoe details ", e);
    }
  },
  generateReport: async (query, overlayId) => {
    const headers = overlayId
      ? {
        "SDWAN-Overlay": overlayId,
      }
      : {};

    try {
      return await axios({
        method: "post",
        url: apiConstant.GENERATE_EDIT_REPORT_URL,
        data: query,
        headers: headers,
      });
    } catch (e) {
      console.log("Error while calling generate report ", e);
    }
  },
  validateReportName: async (reportName, overlayId) => {
    try {
      return await axios({
        method: "get",
        url: apiConstant.GENERATE_EDIT_REPORT_URL,
        params: {
          overlay_id: overlayId,
          report_name: reportName,
        },
      });
    } catch (e) {
      console.log("Error while calling validate report name ", e);
    }
  },
  defineIdpOidc: async (payload) => {
    let headers = {
      Authorization: "Basic " + btoa("sdwan:vAnalyticsNg"),
      "Content-Type": "application/json",
    };
    payload.issuer = btoa(payload.issuer);
    payload.auth_endpoint = btoa(payload.auth_endpoint);
    payload.token_endpoint = btoa(payload.token_endpoint);
    payload.jwks_endpoint = btoa(payload.jwks_endpoint);
    if (payload.userinfo_endpoint) {
      payload.userinfo_endpoint = btoa(payload.userinfo_endpoint);
    }
    try {
      if (payload.idp_id) {
        return await axios.put(apiConstant.EDIT_IDP_OIDC, payload, {
          headers: headers,
        });
      } else {
        return await axios.put(apiConstant.DEFINE_IDP_OIDC, payload, {
          headers: headers,
        });
      }
    } catch (e) {
      console.log("Error while calling define Idp Oidc ", e);
      return createGenericErrorObject(e);
    }
  },

  defineIdpSaml: async (payload) => {
    let headers = {
      Authorization: "Basic " + btoa("sdwan:vAnalyticsNg"),
    };
    payload.idp_issuer_url = btoa(payload.idp_issuer_url);
    payload.idp_sso_url = btoa(payload.idp_sso_url);

    try {
      if (payload.idp_id) {
        return await axios.put(apiConstant.EDIT_IDP_SAML, payload, {
          headers: headers,
        });
      } else {
        return await axios.put(apiConstant.DEFINE_IDP_SAML, payload, {
          headers: headers,
        });
      }
    } catch (e) {
      console.log("Error while calling define Idp Saml ", e);
      return createGenericErrorObject(e);
    }
  },
  uploadSAML: async (query) => {
    let headers = {
      Authorization: "Basic " + btoa("sdwan:vAnalyticsNg"),
      "Content-Type": "application/json",
    };
    query.metadata = btoa(query.metadata);

    try {
      return await axios({
        method: "post",
        url: apiConstant.UPLOAD_SAML,
        data: query,
        headers: headers,
      });
    } catch (e) {
      console.log("Error while calling upload SAML ", e);
      return createGenericErrorObject(e);
    }
  },
  getIDP: async (query) => {
    let IDP = query.idp_type;

    try {
      if (IDP === "OIDC") {
        return await axios.post(apiConstant.VIEW_IDP_OIDC, query);
      } else if (IDP === "SAML") {
        return await axios.post(apiConstant.VIEW_IDP_SAML, query);
      }
    } catch (e) {
      console.log("Error while calling get IDP ", e);
      return createGenericErrorObject(e);
    }
  },
  deleteIdp: async (payload) => {
    let headers = {
      Authorization: "Basic " + btoa("sdwan:vAnalyticsNg"),
      "Content-Type": "application/json",
    };

    try {
      return await axios({
        method: "delete",
        url: apiConstant.DELETE_IDP,
        data: payload,
        headers: headers,
      });
    } catch (e) {
      console.log("Error while calling delete IDP ", e);
      return createGenericErrorObject(e);
    }
  },
  oktaWebFinger: async (username) => {
    let headers = {
      Accept: "application/jrd+json",
      "Content-Type": "application/json",
    };
    const webFingerPath = customOktaConfig.getRegionSpecifics(false);
    try {
      return await axios({
        method: "get",
        url:
          webFingerPath.baseUrl +
          apiConstant.OKTAWEBFINGER +
          "?resource=okta:acct:" +
          username,
        headers: headers,
      });
    } catch (e) {
      return e;
    }
  },
  logout: async () => await axios.post(apiConstant.LOGOUT),
  logoutVManage: async () => await axios.post(apiConstant.LOGOUT_vMANAGE),
  updateAccount: async (payload) => {
    let headers = {
      Authorization: "Basic " + btoa("sdwan:vAnalyticsNg"),
      "Content-Type": "application/json",
      "SDWAN-Overlay": payload.query.rules.o_id,
    };
    try {
      return await axios.put(apiConstant.UPDATE_ACCOUNT, payload, {
        headers: headers,
      });
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  getOnboardingStatus: async () => {
    try {
      return await axios.get(apiConstant.ONBOARDING_STATUS);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  getOnboardingActivate: async (email) => {
    const activatePayload = {
      email: email,
    };
    try {
      return await axios.post(apiConstant.ONBOARDING_ACTIVATE, activatePayload);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  applyRecommendationActions: async (payload) => {
    try {
      return await axios.post(apiConstant.ALTO_ACTIONS_APPLY, payload);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  getBandwidthForecast: async (query) => {
    try {
      return await axios.post(apiConstant.BANDWIDTH_FORECAST_URL, query);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },

  getOverlayInfo: async () => {
    try {
      return await axios.get(apiConstant.AVAILABLE_FEATURES);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getTableKPI: async (payload) => {

    try {
      return await axios.post(apiConstant.ANOMALY_KPI_SUMMARY, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getADChartKPI: async (payload) => {
    try {
      return await axios.post(apiConstant.ANOMALY_KPI_DISTRIBUTION, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getKPITableInfo: async (payload, key) => {
    try {
      return await axios.post(apiConstant.getKPITunnelURL(key?.toLowerCase()), payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getKPITrendData: async (payload, key) => {
    try {
      return await axios.post(apiConstant.getKPITrendURL(key[0].toLowerCase()), payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getPreLoginNotifications: async () => {
    try {
      return await axios.get(apiConstant.PRE_LOGIN_NOTIFICATION_URL);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  getPostLoginNotifications: async () => {
    try {
      return await axios.get(apiConstant.POST_LOGIN_NOTIFICATION_URL);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  updateNotifications: async (payload) => {
    try {
      return await axios.post(apiConstant.UPDATE_NOTIFICATION_URL, payload);
    } catch (err) {
      return createGenericErrorObject(err);
    }
  },

  getTalosGlobalThreats: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.talos_GlobalThreats,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security talos data API ", e);
      return createGenericErrorObject(e);
    }
  },

  getTalosAccessSummary: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.talos_AccessSummary,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security talos data API ", e);
      return createGenericErrorObject(e);
    }
  },

  getSecuritySdwanData: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.sdwan_SiteSummary,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security sdwan data API ", e);
      return createGenericErrorObject(e);
    }
  },

  getSdwanThreatOverview: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.sdwan_ThreatOverview,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security talos data API ", e);
      return createGenericErrorObject(e);
    }
  },
  getSdwanThreatTrend: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.sdwan_ThreatTrend,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security talos data API ", e);
      return createGenericErrorObject(e);
    }
  },
  getSdwanThreatDetail: async (payload) => {
    try {
      return await axios.post(
        apiConstant.securityUrls.sdwan_ThreatDetail,
        payload
      );
    } catch (e) {
      console.log("Error while calling get security talos data API ", e);
      return createGenericErrorObject(e);
    }
  },
  getNavigationPanelList: async () => {
    try {
      return await axios.get(apiConstant.NAVIGATION_PANEL_URL);
    } catch (err) {
      return createGenericErrorObject(err)
    }
  },
  getTaskSummary: async () => {
    try {
      return await axios.get(apiConstant.TASK_URL);
    } catch (err) {
      return createGenericErrorObject(err)
    }
  },
  getCompliance: async () => {
    try {
      return await axios.get(apiConstant.COMPLIANCE_URL);
    } catch (err) {
      return createGenericErrorObject(err)
    }
  },
  getWaffleMenu: async () => {
    try {
      return await axios.get(apiConstant.WAFFLE_STATE);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getNotificationVManage: async (payload) => {
    try {
      return await axios.post(apiConstant.NOTIFICATION_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAlarmCount: async (payload) => {
    try {
      return await axios.post(apiConstant.ALARM_COUNT_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getTenantInfo: async () => {
    try {
      return await axios.get(apiConstant.TENANT_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageToken: async () => {
    try {
      return await axios.get(apiConstant.V_MANAGE_TOKEN_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageFeature: async () => {
    try {
      return await axios.get(apiConstant.AI_FEATURE_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  postvManageSession: async (payload) => {
    try {
      return await axios.post(apiConstant.AI_SESSION_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageSession: async () => {
    try {
      return await axios.get(apiConstant.AI_SESSION_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageDialog: async (payload) => {
    try {
      return await axios.post(apiConstant.AI_DIALOG_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageSuggestion: async (payload) => {
    try {
      return await axios.post(apiConstant.AI_SUGGESTION_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getCloudServices: async () => {
    try {
      return await axios.get(apiConstant.CLOUD_SERVICES_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  sendFeedback: async (payload) => {
    try {
      return await axios.post(apiConstant.FEEDBACK_URL, payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getvManageVSession: async (tenantId) => {
    try {
      return await axios.post(apiConstant.getVSessionURL(tenantId));
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getAboutInfo: async () => {
    try {
      return await axios.get(apiConstant.ABOUT_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  keepSessionAlive: async () => {
    try {
      return await axios.get(apiConstant.SESSION_ALIVE_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getDeviceWithLogs: async () => {
    try {
      return await axios.get(apiConstant.GET_DEVICES_URL);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  generateTrafficLogsRequest: async (payload) => {
    try {
      return await axios.post(apiConstant.generateTrafficLogsRequest(), payload);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getInitialRequest: async () => {
    try {
      return await axios.get(apiConstant.CHECK_REQUEST_URL)

    } catch (error) {
      return createGenericErrorObject(error);
    }
  },
  exportTrafficLogsStreamRequest: async (requestId, parameters) => {
    try {
      return await axios({
        method: "get",
        url: apiConstant.getExportTrafficLogs(requestId),
        adapter: "fetch",
        responseType: 'stream',
        params: {
          ...parameters
        }
      });
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  checkTrafficLogsRequest: async (requestId, parameters) => {
    const params = {
      params: {
        ...parameters
      }
    }
    try {
      return await axios.get(apiConstant.checkTrafficLogsRequest(requestId), params);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  cancelTrafficLogs: async (requestId) => {
    try {
      return await axios.delete(apiConstant.cancelTrafficLogs(requestId));
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getCircuitsTableList: async (filter) => {
    try {
      return await axios.post(apiConstant.circuitUrls.singleCircuitsV4Table, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getBwfCircuitsTableList: async (filter) => {
    try {
      return await axios.post(apiConstant.circuitUrls.bwfCircuitsV4Table, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getSitesTableList: async (filter) => {
    try {
      return await axios.post(apiConstant.SITES_TABLE_URL, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getCircuitsTunnelList: async (filter) => {
    try {
      return await axios.post(apiConstant.c360Urls.tunnelsTable, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getWaniNotifications: async (filter) => {
    try {
      return await axios.get(apiConstant.WANI_NOTIFICATIONS_URL, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },
  getCircuitsApplicationsList: async (filter) => {
    try {
      return await axios.post(apiConstant.c360Urls.appsPerformanceTable, filter);
    } catch (e) {
      return createGenericErrorObject(e);
    }
  },

};

export default ApiService;