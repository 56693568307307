import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { am4core, am4charts } from "../../loaders/amchartsLoader";
import { customizeTooltip } from "../../utils/chart";
import { capitalizeString } from "../../utils/format";
import { generateUID } from "../../utils/common";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../chartMagneticStyle.less";
import { colors, statusColors } from "../../utils/colors";

const chartColors = {
  ...statusColors,
  empty: colors.gray90
};

const StackedBarChart = props => {
  const chartId = useRef("amchart_" + generateUID(128));

  useEffect(() => {
    am4core.options.autoDispose = true;
    const { max, data } = props;
    const chartData = { ...props.data };
    if (max) {
      const total = Object.values(data).reduce((a, b) => a + b);
      if (total < max) chartData.empty = max - total;
    }
    // create chart
    const chart = am4core.create(chartId.current, am4charts.XYChart);
    // set chart data
    chart.data = [Object.assign({ yaxis: "" }, chartData)];
    // set chart style
    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    // customize tooltip
    customizeTooltip(chart.tooltip);
    chart.tooltip.pointerOrientation = "down";
    // set chart tooltip html
    if (props.toolTip === true) {
      chart.tooltipHTML = `
        <table class="ttip-content">
        ${Object.keys(data)
          .map(item => `
            <tr>
              <td>
                <div class="flex-items">
                  <span 
                    class="square-legend" 
                    style="background-color:${chartColors[item]};"
                  ></span>
                  ${item === "gray"
              ? i18n.appQoeDashletGray
              : capitalizeString(item)}
                </div>
              </td>
              <td>${data[item]}</td>
            </tr>
            `
          )
          .join("")}
        </table>
      `;
    }

    // set value axis as x axis
    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    // disable x axis labels
    xAxis.renderer.labels.template.disabled = true;
    // disable x axis grid lines
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.baseGrid.disabled = true;
    // set start and end value of x axis
    xAxis.strictMinMax = true;
    xAxis.min = 0;
    // get max from sum of all values if not provided
    xAxis.max = max
      ? max
      : Math.ceil(Object.values(data).reduce((a, b) => a + b));

    // set category axis as y axis
    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = "yaxis";
    // disable y axis title
    yAxis.title.disabled = true;
    // disable y axis labels
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.disabled = true;

    Object.keys(chartData).forEach(field => {
      if (field !== "yaxis") createSeries(field, chart);
    });
  }, []);

  const createSeries = (field, chart) => {
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "yaxis";
    series.columns.template.fill = am4core.color(chartColors[field]);
    series.columns.template.stroke = am4core.color(colors.gray100);
    series.columns.template.strokeOpacity = 1;
    series.columns.template.strokeWidth = 2;
    series.columns.template.height = am4core.percent(100);
    series.dataFields.valueX = field;
    series.stacked = true;
    series.name = field;
  };

  return (
    <div
      id={chartId.current}
      className={`${css.chart} ${css["stacked-bar-chart"]} hbr-type-body4`}
    />
  );
};

StackedBarChart.defaultProps = {
  max: null,
  data: {
    good: 2,
    fair: 3,
    poor: 2.1
  },
  toolTip: false
};

StackedBarChart.propTypes = {
  toolTip: PropTypes.bool,
  data: PropTypes.object.isRequired,
  max: PropTypes.number
};

export default StackedBarChart;
