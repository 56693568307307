import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

//component
import AppHoc from "../../generics/AppHOC";
import SitesDropdownWidget from "../../generics/SitesDropdownWidget";
import BetaTag from "../BetaTag";

//utils
import tabConfig from "../../config/tab-config";
import { getLocalStorageFlag, isSecurityPage } from "../../utils/common";
import {
  getConvergedCookie,
  getStoredCurrentSite,
  getStoredTabFabricMode,
  isAllSitesPage,
  updateCookie,
  isTrafficLogsPage
} from "../../utils/common";
import { getCookie } from "../../serviceWorker";
import { overviewTab, lastTabs, tunnelTab, logsTab, multiCloudTab, securityVmanageTab, devicesTab } from "./vManageTabs";
import { BASE_V_MANAGE_URL, BASE_URL } from "../../apis/apiConstants";

//style
import css from "../commonMagneticStyle.less";

//i18n file
import i18n from "amdi18n-loader!../nls/i18n";

const availableFeatures = JSON.parse(getLocalStorageFlag("availableFeatures"));
const [HbrTabGroup, HbrTab] = reactWrapper(["hbr-tab-group", "hbr-tab"]);

/**
 * Tab Navigation
 * Navigation items in horizontal tab form
 * Active maps to tab label
 *
 * See defaultProps for example
 * history passed from router
 *
 * Usage:
 *      <TabNavigation tabs=[] history={} />
 */
const TabNavigation = props => {
  const { selectedSite } = props.globalFilter;
  const clVmanageURI = getCookie("cl-vManageURI");
  const uiconvergenceCookie = getCookie("cl-analytics");
  const pathname = location.pathname;
  const history = useHistory();
  const [availableFeatureFlags, setAvailableFeatureFlags] = useState();
  const [tabMenuList, setTabMenuList] = useState();
  const tabListLocalStorage = getLocalStorageFlag("cl-navigation");

  const isTabShown = (tab) => {
    // get feature flags from redux store after click on overlay from accounts page  
    if ((availableFeatureFlags && Object.keys(availableFeatureFlags).length > 0) ||
      (props.globalFilter.availableFeature &&
        Object.keys(props.globalFilter.availableFeature).length > 0)) {
      return availableFeatureFlags && availableFeatureFlags[tab] ?
        availableFeatureFlags[tab] :
        props.globalFilter?.availableFeature[tab];
    } else if (availableFeatures && Object.keys(availableFeatures).length > 0) {
      // get feature flags from local storage (In page refresh or default load)
      return availableFeatures && availableFeatures[tab];
    } else {
      return false;
    }
  }
  // show tab
  const showTab = (tab, show) => {
    tabConfig.find(v => v.label === tab).showTab = show;
    setTabMenuList(tabConfig.filter((tab) => (tab.showTab === true)));
  }

  const getTabs = () => {
    //security tab
    if (isTabShown("security")) {
      showTab(i18n.threatIntelligence, true);
    } else {
      showTab(i18n.threatIntelligence, false);
    }
    //traffic logs tab
    if (isTabShown("oda-traffic-logs")) {
      showTab(i18n.trafficLogs, true);
    } else {
      showTab(i18n.trafficLogs, false);
    }
  }

  useEffect(() => {
    if (
      props.globalFilter.availableFeature &&
      Object.keys(props.globalFilter.availableFeature).length > 0 &&
      (!(clVmanageURI && uiconvergenceCookie))
    ) {
      setAvailableFeatureFlags(props.globalFilter.availableFeature);
      getTabs(props);
    }
  }, [props.globalFilter.availableFeature]);

  useEffect(() => {
    //validate currentOverlay is selected when showing tab navigation
    if (!getLocalStorageFlag("currentOverlay") && !uiconvergenceCookie && !clVmanageURI) {
      history.push(`${BASE_URL}/accounts`);
    }

    if (!(clVmanageURI && uiconvergenceCookie)) getTabs(props);
  }, []);

  const onTabsChange = tab => {
    if ((clVmanageURI && uiconvergenceCookie)) {
      if (tab.isVA) {
        updateCookie("currentPath", tab.url.substring(1));
        if (tab.url === "/traffic-logs") {
          window.location.href = `${BASE_V_MANAGE_URL}/logs`;
        } else {
          history.push(`${BASE_URL}${tab.url}`);
        }
      } else {
        const cookie = JSON.parse(uiconvergenceCookie);

        if (cookie.tenancyMode === 'tenant' && !_.isEmpty(cookie.tenantId)) {
          const tenantUrl = BASE_V_MANAGE_URL.substring(2, BASE_V_MANAGE_URL.length);

          window.location.href = `/#/${cookie.tenantId}${tenantUrl}${tab.url}`;
        } else {
          window.location.href = `${BASE_V_MANAGE_URL}${tab.url}`;
        }
      }
    } else {
      history.push(tab.url);
    }

    localStorage.setItem("currentPath", tab.label);
  };

  useEffect(() => {
    if ((clVmanageURI && uiconvergenceCookie)) {
      const currentSite = getStoredCurrentSite();
      const mode = getConvergedCookie("mode");
      const isNonFabricMode = getStoredTabFabricMode();
      const tabs = [...overviewTab];
      const validTabs = tabListLocalStorage && JSON.parse(tabListLocalStorage) ?
        JSON.parse(tabListLocalStorage)[0].submenu.map(elem => (elem.text)) :
        null;

      if (validTabs?.includes(i18n.vManageTabs.devices))
        tabs.push(devicesTab)

      if (mode !== 'provider' && currentSite === null) {
        if (validTabs?.includes(i18n.vManageTabs.security))
          tabs.push(securityVmanageTab);

        if (validTabs?.includes(i18n.vManageTabs.multicloud))
          tabs.push(multiCloudTab);
      }

      if (mode !== 'provider' && isNonFabricMode === false)
        if (validTabs?.includes(i18n.vManageTabs.tunnels))
          tabs.push(tunnelTab)

      if (validTabs?.includes(i18n.vManageTabs.logs))
        tabs.push(logsTab);

      setTabMenuList([
        ...tabs,
        ...lastTabs
      ]);
    }
  }, [selectedSite])

  return (
    <div className={css["tab-navigation"]}>
      <div className="tabs-component">
        <div className="analytics-top-widget flex-items">
          <div className="time-filter flex-items">
            <div className="app-label hbr-type-h1">
              {(clVmanageURI && uiconvergenceCookie) ? i18n.monitorLabel : i18n.analyticsLabel}</div>
            {!isAllSitesPage() && !isSecurityPage() && !isTrafficLogsPage() && <SitesDropdownWidget {...props} />}
          </div>
        </div>
        <div className="tabs">
          <HbrTabGroup primary>
            {tabMenuList && tabMenuList.map(tab => (
              <HbrTab
                sentiment="success"
                slot="nav"
                active={(pathname === tab.url || pathname.includes(tab.url)) ? true : false}
                key={tab.label}
                panel={tab.label}
                onHbr-active={() => onTabsChange(tab)}
              >
                {tab.label}
                {tab.beta && (<BetaTag />)}
              </HbrTab>
            ))}
          </HbrTabGroup>
        </div>
      </div>
    </div>
  );
};

TabNavigation.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  dashboardConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  match: PropTypes.object.isRequired,
  tabs: PropTypes.object
};

export default AppHoc(TabNavigation);
