import React from "react";
import { PropTypes } from "prop-types";
import css from  "../commonMagneticStyle.less";
import { getColor } from "../../utils/colors";
import i18n from "amdi18n-loader!../../views/nls/i18n";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
const [
  HbrIcon,
  HbrTooltip,
  HbrTag
] = reactWrapper([
  "hbr-icon",
  "hbr-tooltip",
  "hbr-tag"
]);

const QoEScoreComponent = props => {
  const { appScoreChange, appScore, tooltipInfo, isReport=false } = props;
  return (
    <div className={`flex-items ${css["qoe-score"]}`}>
      <div className="flex-items">
        <HbrTag sentiment="info" id="negative">
          {appScore + ' ' + i18n.appExperienceOutOf10}
        </HbrTag>
        <HbrIcon
            id="trendIcon"
            name="trend-up"
            style={{ color: getColor(appScoreChange, "changeQuality") }}
          />
        <div className="hbr-type-h4">
          {appScoreChange}
        </div>
      </div>
      {!isReport && <HbrTooltip
          content={tooltipInfo}
          placement="top"
        >
        <HbrIcon
          className="flex-items"
          name="info"
          sentiment="neutral"
        />
      </HbrTooltip>}
    </div>
  );
};

QoEScoreComponent.propTypes = {
  appScoreChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  appScore: PropTypes.number.isRequired,
  tooltipInfo: PropTypes.string.isRequired,
  isReport : PropTypes.bool
};

export default QoEScoreComponent;