import pathBuilder from "./pathBuilder";

export const BASE_V_MANAGE_URL = "/#/app/monitor2";
export const BASE_TENANT_URL = "/app/monitor2";
export const SETTINGS_URL = "/#/app/administration/settings";
export const LICENSE_PAGE_URL = "/#/app/administration/licenseManager/overview";
export const PROFILE_URL = "/#/app/profile";
export const API_VERSION = "v4";
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_V4_API_URL = `${BASE_API_URL}${API_VERSION}/dataservice/`;
export const BASE_URL = process.env.PUBLIC_URL;
export const BASE_REPORT_URL = "/api/dataservice/reporting/";

const BASE_METASERVICE_URL = `${BASE_API_URL}metaservice/`;

export const CRSF_TOKEN_URL = `${BASE_API_URL}csrf`; //api to get crsf token
export const SMART_ACC_URL = `${BASE_METASERVICE_URL}smartAccount`; //api to get smart account data
export const SMART_ACC_FILTER_URL = `${BASE_METASERVICE_URL}smartAccount/filter`; // api to get support role domain accounts list
export const SMART_ACC_INFO_URL = `${BASE_METASERVICE_URL}smartAccount/info?`; // api to get domain account info
export const AVAILABLE_FEATURES = `${BASE_METASERVICE_URL}overlay/info`; // api to get available feature flags

//overview tab
export const TOP_DEVICE_URL = `${BASE_V4_API_URL}aggregate/devices`; //api to get device dashlet
export const SUMMARY_DATA_URL = pathBuilder("custom/overview/summary"); //api to get filter view widgets
export const TOP_USERS_URL = `${BASE_V4_API_URL}aggregate/clients`; //api to get users dashlet

//alto widget landing page
export const ALTO_CLASS_APP_LIST = pathBuilder("custom/alto/lists/applications"); // api get apps class list for alto widget in overview page
export const ALTO_RECO_METRICS = pathBuilder("custom/alto/recommendations/metrics/endpoints"); // api to get recommendations metrics
export const ALTO_ENDPOINT_SITE_ID = pathBuilder("custom/alto/lists/endpoints"); // api to get lists endpoints
export const ALTO_ENDPOINT_ACTION = pathBuilder("custom/alto/recommendation/actions"); // api to get list of actions
export const ALTO_RECOM_METRICS_PATHS = pathBuilder("custom/alto/recommendations/metrics/paths"); // api to get list of paths
export const ALTO_PATHS_DETAILS = pathBuilder("custom/alto/network/paths/detailed"); // api to get list of paths details
export const ALTO_PATH_METRICS = pathBuilder("custom/alto/network/metrics/path"); // api to get path metcis
export const ALTO_NETWORK_PATHS = pathBuilder("custom/alto/network/paths"); // api to get list of paths
export const ALTO_ACTIONS_APPLY = `${BASE_API_URL}alto/set_wani_actionlist`; // api to apply actions

export const FILTERVIEW_APPS_URL = pathBuilder("custom/overview/applications/summary"); //api to get filter view for apps widget

export const TOP_APP_DONUT_URL = pathBuilder("custom/overview/applications/vqoe_donut"); //api to get application donut chart
export const TOP_APP_STACKED_URL = pathBuilder("custom/overview/applications/vqoe_stacked"); //api to get application stacked chart

//application tab
export const TABLE_CUSTOMLIST_URL = `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/applications`; // api to get table custom list or get usage details for app experience
export const QOE_DISTRIBUTION_URL = pathBuilder("custom/applications/qoe_distribution/application_classes"); //api to get qoe distribution
export const TREND_APP_BY_RISE_OR_DROP_URL = pathBuilder("custom/applications/trend_applications/"); //api to get trend app by rise or drop
export const APPLICATION_TABLE_LIST_URL = `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/applications/table`;// api to get table custom list or get usage details for app experience

//app360 tab
export const APP360_TOP_USERS_URL = pathBuilder("custom/app360/top_users/default"); //api to get  app 360 app users
export const OP_MAP_BY_SITES_URL = `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/app360/sites`;//api to get map sites
export const AGGREGATE_DATA_DPI_URL = pathBuilder("generic/flows_app_usage_hourly_view/app360_averages/aggregate"); //api to get  aggregate data
export const AGGREGATE_DATA_SITE_DPI_URL = `${BASE_V4_API_URL}time_series/applications/metrics` //api to get aggregate data from site
export const VPN_USAGE_URL = pathBuilder("custom/app360/dpi_granularity/vpn_id"); //api to get VPN usage
export const TUNNEL_USAGE_DATA_URL = pathBuilder("custom/app360/dpi_granularity/tunnel_name"); //api to get tunnel usage data
export const TUNNEL_DATA_URL = pathBuilder("tunnel_performance_view/aggregate"); //api to get tunnel data
export const MAP_VIEW_DISTRIBUTION_URL = pathBuilder("custom/app360/mapview/site_distributions"); //api to get map view distribution

// Common PathAnalytics API's
export const SAAS_APP_BESTPATH_APPINFO = `${BASE_V4_API_URL}saas_app/common/app_info`;

// O365 specific PathAnalytics Tab API's
export const SAAS_APP_O365_BESTPATH_APPFILTER = `${BASE_V4_API_URL}saas_app/o365/app_filter`; // API to get O365 app_filter data
export const SAAS_APP_O365_BESTPATH_LINECHART = `${BASE_V4_API_URL}saas_app/o365/best_path_line_chart`; // API to get line chart data
export const APP365_PATH_ANALYTICS_CHART = `${BASE_V4_API_URL}saas_app/o365/partner_msft_path_selection`; // API to get app365's chart data
export const SAAS_APP_PATH_ANALYTICS_O365_USAGE = `${BASE_V4_API_URL}saas_app/o365/usage_chart`; // API to get usage chart data
export const SAAS_APP_CXP_VIEW_O365_AGGREGATE = `${BASE_V4_API_URL}saas_app/o365/cxp_apps/aggregate`; // API to get Network telemetry data

// Webex specific PathAnalytics Tab API's
export const SAAS_APP_WEBEX_BESTPATH_APPFILTER = `${BASE_V4_API_URL}saas_app/webex/app_filter` // API to get WEBEX app_filter data
export const SAAS_APP_WEBEX_BESTPATH_LINECHART = `${BASE_V4_API_URL}saas_app/webex/best_path_line_chart`; // API to get line chart data
export const WEBEX_PATH_ANALYTICS_CHART = `${BASE_V4_API_URL}saas_app/webex/partner_webex_path_selection`; // API to get Webex's chart data
export const SAAS_APP_PATH_ANALYTICS_WEBEX_USAGE = `${BASE_V4_API_URL}saas_app/webex/usage_chart`; // API to get usage chart data
export const SAAS_APP_CXP_VIEW_WEBEX_AGGREGATE = `${BASE_V4_API_URL}saas_app/webex/cxp_apps/aggregate`; // API to get Network telemetry data

export const CIRCUIT_DATA_SITE_URL = `${BASE_V4_API_URL}time_series/applications/usage_availability_by_circuits`; //api to get cirucits widget data from site
export const PATH_TRACE_CHART_URL = pathBuilder("custom/umts/tunnelpath/chart"); //api to get UMTS chart widget data from application at single site
export const PATH_TRACE_GRAPH_URL = pathBuilder("custom/umts/tunnelpath/graph"); //api to get UMTS path trace  widget data from application at single site

//sidebar trend analysis
export const APP_COUNT_BY_QOE_URL = `${BASE_V4_API_URL}time_series/applications/app_count_by_qoe`;  //api to get app count by qoe in sidebar trend analysis
export const APP_BY_BANDWIDTH_URL = `${BASE_V4_API_URL}time_series/applications/usage_summary`; //api to get total app by bandwidth in sidebar trend analysis
export const TOP_APPS_BY_BANDWIDTH_URL = `${BASE_V4_API_URL}time_series/applications/bandwidth`; //api to get app usage data in sidebar trend analysis
export const AGGREGATE_DATA_URL = `${BASE_V4_API_URL}time_series/applications/qoe`; //api to get aggregate data
export const AGGREGATE_DATA_WITH_WIDGET_KEY_URL = pathBuilder("generic/application_performance_view/"); //api to get aggregate data with widget key
export const APP360_APPFLOW_URL = pathBuilder("custom/app360/appflow/tunnel_list"); //Custom API to get Appflow tunnel_list data
export const SANKEY_DATA_SITE_URL = pathBuilder("custom/app360/flow/default"); //api to get Sankey chart widget data from site

//site dropdown widget
export const SITES_URL = `${BASE_V4_API_URL}site_info`; //api to get sites
export const APP_PERF_URL = `${BASE_V4_API_URL}aggregate/applications`;

//sites tab
export const SITES_TABLE_AVAILABILITY_TIMEFRAME = `${BASE_V4_API_URL}aggregate/sites`; //api to get sites in timeframe
export const SITES_TABLE_URL = `${BASE_V4_API_URL}aggregate/sites/table`;

//report feature, all applications headless browser
export const QOE_USAGE_DETAILS_URL = pathBuilder("custom/applications/accordion/qoe_usage_summary"); //api to get qoe usage in report and details for app experience
export const GENERATE_EDIT_REPORT_URL = `${BASE_METASERVICE_URL}report`; //api to generate and edit the report
export const ALL_TEMPLATES_URL = `${BASE_METASERVICE_URL}report_template`; //api to get all templates for different types of reports
export const DELETE_OR_GET_REPORT_FROM_ID_URL = `${BASE_METASERVICE_URL}report/report_uuid=`; //api to get either delete or get the report info based on uuid
export const ALL_REPORTS_URL = `${BASE_METASERVICE_URL}report/overlay_id=`; //api to get all reports that were generated
export const REPORT_DATA_URL = pathBuilder("report/:id"); //api to get report data for all applications headless browser
export const REPORT_URL = `${BASE_URL}/report/`;
export const REPORTS_URL = `${BASE_URL}/reports`;
export const REPORTS_URL_HOME = `${BASE_URL}/reports#generated-reports`;
export const REPORT_SCOPE_PAGE_URL = `${REPORT_URL}setup-report-scope`;
export const REPORT_FILETYPE_PAGE_URL = `${REPORT_URL}setup-filetype`;

//pdf paths
export const REPORT_EXECUTIVE = `${BASE_REPORT_URL}:id/executive_summary`;
export const REPORT_SITES = `${BASE_REPORT_URL}:id/site_summary`;
export const REPORT_APPS = `${BASE_REPORT_URL}:id/application_summary`;
export const REPORT_APP360 = `${BASE_REPORT_URL}:id/application_summary/:appName`;

//csv paths
export const getCSVReportURL = (key) => `${BASE_REPORT_URL}:id/${key}`;

// Define/Edit/View IDP Endpoints
export const DEFINE_IDP_OIDC = `${BASE_METASERVICE_URL}defineIdp/oidc`;
export const EDIT_IDP_OIDC = `${BASE_METASERVICE_URL}editIdp/oidc`;
export const DEFINE_IDP_SAML = `${BASE_METASERVICE_URL}defineIdp/saml`;
export const EDIT_IDP_SAML = `${BASE_METASERVICE_URL}editIdp/saml`;
export const UPLOAD_SAML = `${BASE_METASERVICE_URL}idpMetadata/saml`;
export const VIEW_IDP_OIDC = `${BASE_METASERVICE_URL}viewIdp/oidc`;
export const VIEW_IDP_SAML = `${BASE_METASERVICE_URL}viewIdp/saml`;
export const DELETE_IDP = `${BASE_METASERVICE_URL}deleteIdp`;
export const UPDATE_ACCOUNT = `${BASE_METASERVICE_URL}overlay_rbac`;

// Logout
export const LOGOUT = `${BASE_API_URL}logout`;

// Okta webfinger
export const OKTAWEBFINGER = "/.well-known/webfinger";

//WANI Onboarding
export const ONBOARDING_STATUS = `${BASE_API_URL}wan-insights-service/onboarding/status`;
export const ONBOARDING_ACTIVATE = `${BASE_API_URL}wan-insights-service/onboarding/activate`;

// circuits
export const circuitUrls = {
  singleTable: `${BASE_V4_API_URL}aggregate/circuits`,
  trendAvailability:
    pathBuilder("generic/nw_availability_view/availabilty_circuit_hourly/aggregate"),
  diaVsTunnelUsage: `${BASE_V4_API_URL}time_series/circuits/usage_by_flow_type`,
  colorUsage: `${BASE_V4_API_URL}time_series/circuits/usage_by_color`,
  bandwidthAvg: `${BASE_V4_API_URL}time_series/circuits/bandwidth`,
  bandwidthAvgEltDaily: pathBuilder("v_circuit_usage_daily_all/aggregate"),
  fabricCircuits: `${BASE_METASERVICE_URL}overlay_entity_map/entity_type=circuit_name`,
  singleCircuitsV4Table: `${BASE_V4_API_URL}aggregate/circuits/table`,
  bwfCircuitsV4Table: `${BASE_V4_API_URL}aggregate/bwf/circuits_table`
};

// circuit 360
export const c360Urls = {
  bandwidthAvgElt: `${BASE_V4_API_URL}time_series/circuits/bandwidth`,
  topAppsUsage: `${BASE_V4_API_URL}time_series/circuits/usage_by_applications`,
  topTunnelsUsage: `${BASE_V4_API_URL}time_series/circuits/usage_by_tunnel`,
  diaVsTunnelUsage: `${BASE_V4_API_URL}time_series/circuits/usage_by_flow_type`,
  appsPerformanceTable: `${BASE_V4_API_URL}aggregate/circuits/applications_table`,
  appsUsageTable: pathBuilder("custom/circuits/applications/usage"),
  tunnelsTable: `${BASE_V4_API_URL}aggregate/circuits/tunnels_table`,
  flowsTable: pathBuilder("custom/circuits/top_flows/usage")
};

// Bandwidth forecast widget
export const BANDWIDTH_FORECAST_URL = pathBuilder("bandwidth_forecast"); //api to get bandwidth forecast

//Anomaly detection chart widget
export const ANOMALY_CHART_LOSS = pathBuilder("sagemaker.ad_prediction_loss/aggregate"); // get loss for anomaly detection
export const ANOMALY_CHART_LATENCY = pathBuilder("sagemaker.ad_prediction_latency/aggregate"); // get latency anomaly detection
export const ANOMALY_CHART_JITTER = pathBuilder("sagemaker.ad_prediction_jitter/aggregate"); // get jitter anomaly detection

//Anomaly detection chart widget
export const ANOMALY_CHART_LOSS_ELT = pathBuilder("ad_prediction_loss_view/aggregate"); // get loss for anomaly detection
export const ANOMALY_CHART_LATENCY_ELT = pathBuilder("ad_prediction_latency_view/aggregate"); // get latency anomaly detection
export const ANOMALY_CHART_JITTER_ELT = pathBuilder("ad_prediction_jitter_view/aggregate"); // get jitter anomaly detection
export const ANOMALY_KPI_DISTRIBUTION = `${BASE_API_URL}${API_VERSION}/dataservice/time_series/ad/kpi_distribution`; // api to get KPI's for anomaly detection

//Anomaly detection table widget
export const ANOMALY_TABLE_LOSS = pathBuilder("custom/ad/loss_info/summary"); // get loss for anomaly detection
export const ANOMALY_TABLE_LATENCY = pathBuilder("custom/ad/latency_info/summary"); // get latency for anomaly detection
export const ANOMALY_TABLE_JITTER = pathBuilder("custom/ad/jitter_info/summary"); // get latency for anomaly detection
export const ANOMALY_KPI_SUMMARY = `${BASE_API_URL}${API_VERSION}/dataservice/time_series/ad/kpi_summary`; // api to get KPI's Summary for anomaly detection

export const getKPITunnelURL = (key) => `${BASE_API_URL}${API_VERSION}/dataservice/time_series/ad/${key}_tunnel_summary`;
export const getKPITrendURL = (key) => `${BASE_API_URL}${API_VERSION}/dataservice/time_series/ad/${key}_trend`;

//Banner Notification
export const PRE_LOGIN_NOTIFICATION_URL = `${BASE_API_URL}notificationservice/system`; //api to get banner notification for pre-login
export const POST_LOGIN_NOTIFICATION_URL = `${BASE_API_URL}notificationservice/tenant`; // api to get post-login notification
export const UPDATE_NOTIFICATION_URL = `${BASE_API_URL}notificationservice/state`; // api to update post-login notification

// security
export const securityUrls = {
  sdwan_SiteSummary: `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/security/sdwan/site_summary`,
  sdwan_ThreatOverview: `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/security/sdwan/site_threat_overview`,
  sdwan_ThreatTrend: `${BASE_API_URL}${API_VERSION}/dataservice/time_series/security/sdwan/site_threat_trend`,
  sdwan_ThreatDetail: `${BASE_API_URL}${API_VERSION}/dataservice/time_series/security/sdwan/site_threat_detail`,
  talos_GlobalThreats: `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/security/threats/global`,
  talos_AccessSummary: `${BASE_API_URL}${API_VERSION}/dataservice/aggregate/security/sdwan/access_summary`,
}

//vManage apis
export const WAFFLE_STATE = "/dataservice/settings/configuration/waffle";// api to get waffle state in the top right header
export const NAVIGATION_PANEL_URL = "/dataservice/client/navigation"; // api to get side panel tabs list
export const TASK_URL = "/dataservice/client/activity/summary";
export const COMPLIANCE_URL = "/dataservice/msla/licenses/compliance";
export const NOTIFICATION_URL = "/dataservice/alarms/topn";
export const ALARM_COUNT_URL = "/dataservice/alarms/count";
export const LOGOUT_vMANAGE = `/logout?nocache=${Date.now()}&reason=logout`;
export const TENANT_URL = "/dataservice/tenant";
export const V_MANAGE_TOKEN_URL = "/dataservice/client/token";
export const AI_FEATURE_URL = "/dataservice/aiassistant/feature";
export const AI_SESSION_URL = "/dataservice/aiassistant/dsm/session";
export const AI_SUGGESTION_URL = "/dataservice/aiassistant/dsm/suggestion";
export const AI_DIALOG_URL = "/dataservice/aiassistant/dsm/dialog";
export const FEEDBACK_URL = "/dataservice/aiassistant/dsm/feedback";
export const ABOUT_URL = "/dataservice/client/about";
export const SESSION_ALIVE_URL = "/dataservice/client/event/poll/events";
export const CLOUD_SERVICES_URL = "/dataservice/settings/configuration/cloudservices";
export const getVSessionURL = (key) => `/dataservice/tenant/${key}/vsessionid`;
export const WANI_NOTIFICATIONS_URL = `/dataservice/wani/recommendations`;

//traffic logs apis
export const generateTrafficLogsRequest = () => `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/request`;//get traffic logs
export const CHECK_REQUEST_URL = `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/request/list`;//initial check
export const GET_DEVICES_URL = `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/devices`;
export const checkTrafficLogsRequest = (requestId) => `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/request/${requestId}`;
export const cancelTrafficLogs = (requestId) => `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/request/${requestId}`;
export const getExportTrafficLogs = (requestId) => `${BASE_API_URL}${API_VERSION}/analytics-service/on-demand/traffic-logs/request/${requestId}/export`;