import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { DnxBanner } from "./loaders/DNXLoader";
import Spinner from "./common/Spinner";
import OktaSignInWidget from "./login/OktaSignInWidget";
import AppHeader from "./generics/AppHeader";
import PageNotFound from "./common/PageNotFound";
import _ from "lodash";
import { Provider } from 'react-redux';
import store from './views/logs/store';
import {
  isDefaultHeader,
  isReport,
  getEditPath,
  removeLocalStorageFlag,
  setLocalStorageFlag,
  getConvergedCookie
} from "../src/utils/common";
import { removeRumUser } from "../src/monitoring";
import { getCookie, deleteCookies } from "./serviceWorker";
import { oktanonIdpConfig, oktaIdpConfig } from "./login/okta";
import modules, { routes } from "./config/routeConfig";
import apiService from "./config/api-config";
import i18n from "amdi18n-loader!./common/nls/i18n";
import css from "./AppMagnetic.less";
import { BASE_URL, BASE_V_MANAGE_URL } from "./apis/apiConstants";
import { useMount } from "./utils/genericCommon";

const isReportGenerationUI =
  process.env.REACT_APP_SKIP_RBAC_DISABLE_RBAC === "FALSE" ? false : true;
const HomeSpinner = () => (
  <div className="home-spinner">
    <Spinner />
  </div>
);

const App = () => {
  const [token, setToken] = useState(null);
  const [loginState, setLoginState] = useState(null);
  const [promptLoginState, setPromptLoginState] = useState(null);
  const [sessionLogout, setSessionLogout] = useState(null);
  const [notification, setNotification] = useState([]);
  const [about, setAbout] = useState();
  const isUIconvergence = getCookie("cl-analytics");
  const mount = useMount();

  useEffect(() => {
    if (isUIconvergence)
      //call periodically event api to keep the session alive
      setInterval(apiService.keepSessionAlive, 570000);//9 minutes and 30 seconds
    removeLocalStorageFlag("isTunnelTabVisible");
    // Adding window event listener for tracking active history entry changes while the user navigates the session history.
    window.addEventListener("popstate", historyChanage, false);
    return () => removeEventListener("popstate", historyChanage, false);
  }, []);

  useEffect(() => isReportGenerationUI === false && getState(), []);

  useEffect(async () => {
    if (isUIconvergence && mount.initMount) {
      mount.initMount = false;

      const xsrfToken = await apiService.getvManageToken();
      const aboutInfoResponse = await apiService.getAboutInfo();
      const tenantId = getConvergedCookie("tenantId");

      if (!_.isEmpty(tenantId)) {
        const vsessionToken = await apiService.getvManageVSession(tenantId);
        // vmanage session
        if ((vsessionToken.errorObject instanceof Object) === false)
          setLocalStorageFlag("Vsessionid", vsessionToken.data.VSessionId);
      }

      // vmanage xsrf token
      if ((xsrfToken.errorObject instanceof Object) === false)
        setLocalStorageFlag("xsrfToken", xsrfToken.data);

      // vmanage about
      if ((aboutInfoResponse.errorObject instanceof Object) === false)
        setAbout(aboutInfoResponse.data.data);
    }
  }, [token]);

  useEffect(() => {
    if (sessionLogout !== null && loginState === false)
      window.location.href = sessionLogout;
  }, [sessionLogout, loginState]);

  // get csrf Token
  const getState = useCallback(async () => {
    const promptLogin = getCookie("prompt-login");
    const sessionLg = sessionStorage.getItem("cl-logout");
    const authState = getCookie("okta-oauth-state");
    removeLocalStorageFlag("Vsessionid");
    removeLocalStorageFlag("xsrfToken");

    const res = await apiService.getCsrfToken(authState);
    if (
      res.errorObject instanceof Object === false
      && res.data instanceof Object
    ) {
      const data = res.data;
      oktanonIdpConfig.authParams.state = data["X-CSRFToken"];
      oktaIdpConfig.authParams.state = data["X-CSRFToken"];
      /* empty prompt-login cookie returns undefined in Mac OS and sometimes
       returns null or empty string in Windows OS.
       This condition checks if prompt-login cookie value is falsy.*/
      if (data.loginState === false && !promptLogin) {
        let redirectURL = "";
        //check if url is for converged ui and if so redirect to vManage because 
        //you should not access vA url directly, but tru vManage
        if (isUIconvergence) redirectURL = `${BASE_V_MANAGE_URL}/overview`;
        else
          redirectURL =
            oktaIdpConfig.authParams.issuer +
            "/v1/authorize?client_id=" +
            oktaIdpConfig.clientId +
            "&response_type=code&scope=" +
            oktaIdpConfig.authParams.scopes.join("%20") +
            "&prompt=none&redirect_uri=" +
            oktaIdpConfig.redirectUri
              .replaceAll("/", "%2F")
              .replaceAll(":", "%3A") +
            "&state=" +
            data["X-CSRFToken"] +
            "&nonce=" +
            oktaIdpConfig.authParams.nonce;
        window.location.href = redirectURL; // redirect
      } else {
        setToken(data["X-CSRFToken"]);
        setLoginState(data.loginState);
        setPromptLoginState(promptLogin);
        setSessionLogout(sessionLg);
        if (data.loginState === false) {
          //get pre-login notification
          const res = await apiService.getPreLoginNotifications();
          if (
            res.errorObject instanceof Object === false
            && res.data instanceof Object
            && Array.isArray(res.data.data)
          ) setNotification(res.data.data);
        }
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {loginState === true || isReportGenerationUI === true ? (
          <div className={css.vanalyticsApp}>
            {(!isDefaultHeader(location) && !isReport(location)) ||
              getEditPath(location) ? (
              <div className="headerApp">
                <AppHeader urlPrefix={BASE_URL} token={token} about={about} />
              </div>
            ) : null}
            <div id="full-screen-content" className={css.vanalyticsPageContent}>
              <Switch>
                {modules.map(item => (
                  <Route key={item.id} exact path={item.path}>
                    <item.module />
                  </Route>
                ))}
                {!localStorage.getItem("currentOverlay") && (
                  <Route exact path={["/", "/analytics", `${BASE_URL}`]}>
                    <HomeSpinner />
                  </Route>
                )}
                {sessionStorage.getItem("overlays") ? (
                  <Route path="*" component={PageNotFound} />
                ) : (
                  <Route path="*" component={HomeSpinner} />
                )}
              </Switch>
            </div>
          </div>
        ) : promptLoginState === "true" && !sessionLogout ? (
          <Switch>
            <Route
              exact
              path={routes.login.path}
              render={() => (
                <div>
                  {token && (
                    <>
                      {notification.length > 0 && (
                        <DnxBanner
                          className={css["dnx-banner-custom"]}
                          hideRemoveButton
                          name={notification[0].notification_criticality}
                          config={{
                            message: notification[0].notification_msg,
                            type:
                              notification[0].notification_criticality === "info"
                                ? i18n.information
                                : i18n.warning
                          }}
                        />
                      )}
                      <OktaSignInWidget
                        oktaIdpConfig={oktaIdpConfig}
                        oktanonIdpConfig={oktanonIdpConfig}
                        token={token}
                      />
                    </>
                  )}
                </div>
              )}
            />
            <Route path={["/", "*"]}>
              <Redirect to={routes.login.path} />
            </Route>
          </Switch>
        ) : (<HomeSpinner />)}
      </Router>
    </Provider>
  );
};

const historyChanage = async event => {
  event.preventDefault();
  const clVmanageURI = getCookie("cl-vManageURI");
  if (
    document.referrer !== "" &&
    clVmanageURI !== undefined &&
    !window.location.pathname.includes(`${BASE_URL}/`)
  ) {
    const isUIconvergence = getCookie("cl-analytics");
    if (isUIconvergence) {
      //below block is for UI convergence
      window.location.href = clVmanageURI;
    } else {
      //below block is for cross launch
      try {
        const res = await apiService.logout(); //logout from vAnalytics
        localStorage.removeItem("currentOverlay");
        localStorage.removeItem("currentOverlayName");
        localStorage.removeItem("okta-shared-transaction-storage");
        sessionStorage.removeItem("overlays");
        removeRumUser();
        deleteCookies();
        // After logout from vAnalytics redirect user to vManageURI
        if (res) window.location.href = clVmanageURI;
      } catch (err) {
        console.log(
          "Error while logging out and cleaning up localstorage and cookies",
          err
        );
      }
    }
  }
};

export default App;
