import { PropTypes } from "prop-types";

import QoeListComponent from "../../../QoeListComponent";
import i18n from "amdi18n-loader!../../../nls/i18n";
import { COLOR } from "../../../../utils/enums";

const SummaryDetails = ({ summaryData, isQoEMetric }) => {
  const availabilityLabels = isQoEMetric ?
    [
      i18n.appDashboardQOEGoodLabel,
      i18n.appDashboardQOEFairLabel,
      i18n.appDashboardQOEPoorLabel,
      i18n.appDashboardQOEUnknownLabel
    ] :
    [
      i18n.sitesHigh,
      i18n.sitesFair,
      i18n.sitesLow,
      i18n.sitesUnknown
    ]
  const { high, fair, low, gray } = summaryData;
  return (
    <div className="map-summary">
      <div className="map-summary-qoes">
        <QoeListComponent
          iconType={[COLOR.GOOD, COLOR.FAIR, COLOR.POOR, COLOR.GRAY]}
          qoeScore={[high, fair, low, gray]}
          qoeLabels={[...availabilityLabels]}
        />
      </div>
    </div>
  );
};

SummaryDetails.propTypes = {
  summaryData: PropTypes.object.isRequired,
  isQoEMetric: PropTypes.bool
};

export default SummaryDetails;
