import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import {
  setLocalStorageFlag,
  getTextValueTopSites,
  updateCookie
} from "../../../utils/common";
import {
  formatNumberNDecimalPlaces,
  formatBytes,
  formatString
} from "../../../utils/format";
import { getColor } from "../../../utils/colors";
import i18n from "amdi18n-loader!../../nls/i18n";
import "../../commonMagneticStyle.less";
import Spinner from "../../../common/Spinner";
import NoDataAvailable from "../../../common/NoDataAvailable";
import { BASE_URL } from "../../../apis/apiConstants";
import { getCookie } from "../../../serviceWorker";
import { BASE_V_MANAGE_URL } from "../../../apis/apiConstants";

const [HbrTable] = reactWrapper(["hbr-table"]);

const TopSitesComponent = props => {
  const { sites, sitesView } = props;
  const textVaue = getTextValueTopSites(sitesView);

  const getSiteDetails = siteId => {
    const isUIconvergence = getCookie("cl-analytics");

    if (isUIconvergence) {
      updateCookie("currentSite", siteId);
      window.location.href = `${BASE_V_MANAGE_URL}/overview`;
    } else {
      const path = `${BASE_URL}/overview`;
      setLocalStorageFlag("currentSite", siteId);
      props.setSelectedSite(siteId);
      props.history.push(path);
    }
  };

  const score = props.isReporting ?
    {
      prop: "vqoe_score",
      name: i18n.allSiteQoELabel,
      size: 100,
      cellTemplate: (createElement, props) => {
        const div = createElement("div", {
          style: {
            color: getColor(props.model.vqoe_score, "qoe")
          },
          innerHTML: props.model.vqoe_score
        });
        return [div];
      }
    } :
    {
      prop: "availability",
      name: textVaue.secondColHeader,
      size: 100,
      cellTemplate: (createElement, props) => {
        const div = createElement("div", {
          style: {
            color: getColor(props.model.availability, "availability")
          },
          innerHTML: props.model.availability
        });
        return [div];
      }
    };

  const columns = [
    {
      prop: "index",
      name: "#",
      size: 50
    },
    {
      prop: "site_id",
      name: i18n.allSiteSiteLabel,
      size: 120,
      cellTemplate: (createElement, props) => {
        const getHTML = () =>
          `<hbr-link class="application-trending-apps-qoe-widget-link" id="sites-top-site-map-${props.model.site_name}">${props.model.siteNameDisplay}</hbr-link>
                <hbr-portal>
                  <hbr-tooltip anchor="#sites-top-site-map-${props.model.site_name}">
                    <div slot="content">
                        <strong>${props.model.site_name}</strong>  
                    </div>
                  </hbr-tooltip>
                </hbr-portal>`;
        const div = createElement("div", {
          content: `<div>${props.model.site_name}</div>`,
          innerHTML: getHTML(),
          onClick: e => {
            if (e && e.target) {
              getSiteDetails(props.model.site_id);
            }
          }
        });
        return [div];
      }
    },
    {
      prop: "city",
      name: i18n.allSiteLocationLabel,
      size: 120
    },
    {
      ...score
    },
    {
      prop: "usageFormatted",
      name: textVaue.thirdColHeader,
      size: 80
    }
  ];
  sites && sites.length > 0 && sites.map((site, index) => {
    site.index = index + 1;
    site.usageFormatted = formatBytes(site.usage);
    site.availability = parseFloat(
      formatNumberNDecimalPlaces(site.availability, 1)
    );
    site.siteNameDisplay = formatString(site.site_name,12);
  });
  const source = sites;

  return (
    <>
      <div className="top-sites-heading hbr-type-body3">{textVaue.viewBy}</div>
      {source.length === 0 ? (
        <Suspense fallback={<Spinner />}>
          <div className="top-sites-no-data">
            <NoDataAvailable />
          </div>
        </Suspense>
      ) : (
          <HbrTable
            className="site-table"
            readonly
            canFocus={false}
            columns={columns}
            source={source}
          />
        )}
    </>
  );
};

TopSitesComponent.propTypes = {
  sites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setSelectedSite: PropTypes.func,
  globalFilter: PropTypes.object,
  history: PropTypes.object,
  model: PropTypes.object,
  sitesView: PropTypes.string.isRequired,
  isReporting: PropTypes.bool
};

export default TopSitesComponent;
