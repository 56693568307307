import { formatBytes, formatNumberNDecimalPlaces } from "../../utils/format";
import { compareAndMatchELT, getIsHourlyData, getTimeInterval } from "../../utils/common";
import {
  displayDateTime,
  getTimestampFromUTCString,
} from "../../utils/displayTime";
import { defaultTextValue } from "../../utils/enums";

export const parseAppCount = (resData, filter, globalV4PayloadSidebar) => {
  for (const item of resData) {
    const date = getTimestampFromUTCString(item.entry_ts);
    item['date'] = date;
    item['Good'] = item.good_count;
    item['Fair'] = item.fair_count;
    item['Poor'] = item.bad_count;
   item['tooltipData'] = {
                date: displayDateTime(date),
                Good: Number.isFinite(item.good_count)
                  ?item.good_count
                  : defaultTextValue,
                Fair: Number.isFinite(item.fair_count)
                  ? item.fair_count
                  : defaultTextValue,
                Poor: Number.isFinite(item.bad_count)
                  ? item.bad_count
                  : defaultTextValue,
              };

    delete item.entry_ts;
    delete item.good_count;
    delete item.fair_count;
    delete item.bad_count;
  }

  if (getIsHourlyData(globalV4PayloadSidebar.time_frame)) {
    resData = compareAndMatchELT(resData, filter, "date");
  }
  return resData;
};

export const updateCustomList = data => {
  let maxvalue = Math.max(...data.map(record => record.total_sites));
  return data.map(record => {
    record["max"] = maxvalue;
    return record;
  });
};
export const parseTotalBandwidth = (resData, timeFilter, globalV4PayloadSidebar) => {

   let bandwidthData = resData.map(({ entry_ts, usage }) => ({
    date: getTimestampFromUTCString(entry_ts),
    usage: usage
   }));

  if (getIsHourlyData(globalV4PayloadSidebar.time_frame)) {
    bandwidthData = compareAndMatchELT(bandwidthData, timeFilter, "date");
  }
  return bandwidthData;
};

export const getSelectedAppChartData = (
  data,
  timeFilter,
  isCustom = false,
  isQoE = true,
  globalV4PayloadSidebar
) => {
  const entryData = {};
  const visibleApps = {};
  // create entry object from response data

  const timeInterval = getTimeInterval(globalV4PayloadSidebar.time_frame);
  if (isQoE === true)
    for (const { application, entry_ts, vqoe_score } of data) {
      const date = getTimestampFromUTCString(entry_ts);
      if (Number.isFinite(vqoe_score)) {
        visibleApps[application] = true;
        if (entryData[date] instanceof Object)
          entryData[date][application] = parseFloat(formatNumberNDecimalPlaces(vqoe_score, 1));
        else entryData[date] = { date, [application]: parseFloat(formatNumberNDecimalPlaces(vqoe_score, 1)) };
      }
    }
  else
  for (const { application, entry_ts, total_octets, bandwidth_rank } of data) {
    const date = getTimestampFromUTCString(entry_ts);
     if (Number.isFinite(total_octets)) {
      visibleApps[application] = bandwidth_rank;
       if (entryData[date] instanceof Object) {
         entryData[date][application] = total_octets;
         entryData[date]["tooltipData"][application] = formatBytes(total_octets);
       }
      else entryData[date] = {
        date, [application]: total_octets,
        tooltipData: {
          date: displayDateTime(date),
          [application]: formatBytes(total_octets),
        }

      };
    }
  }
  // sort applications when top applications radio button is selected
  let applications = Object.keys(visibleApps);
  if (isCustom === false)
    applications = applications.sort((item1, item2) => {
      return visibleApps[item1] < visibleApps[item2] ? -1 : 1
    });
  // generate graph data from entry array
  let chartData = Object.values(entryData);
  const newEntries = [];
  const lastIndex = chartData.length - 1;
  chartData.forEach((entry1, dataIndex) => {
    if (dataIndex < lastIndex) {
      const entry2 = chartData[dataIndex + 1];
      /* add an entry to extend lines till current entry's end range if
      next entry is after an hour */
      if (entry2.date > entry1.date + timeInterval) {
        newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
      } else {
        const newEntry = {
          ...entry1,
          date: entry1.date + (timeInterval -1)
        };
        const missedApps = applications.filter(item => {
          if (Number.isFinite(entry1[item])) {
            if (Number.isFinite(entry2[item]) === false) return true;
            else newEntry[item] = entry2[item];
          } else newEntry[item] = null;
          return false;
        });
        /* add an entry in current entry's end range if any application
        in current entry is missing in next entry */
        if (missedApps.length > 0) newEntries.push(newEntry);
      }
    }
  });
  // add new records to graph data
  chartData.push(...newEntries);
  // sort graph data based on entry time
  chartData.sort((item1, item2) => item1.date > item2.date ? 1 : -1);

  if (getIsHourlyData(globalV4PayloadSidebar.time_frame)) {
    chartData = compareAndMatchELT(chartData, timeFilter);
  }
  // get graph data
  for (const item of chartData) {
    for (const application of applications) {
      if (Number.isFinite(item[application]) === false)
        item[application] = null;
    }
  }
  return { data: chartData, applications };
};
export const getGraphData = (data, applications) => {
  const chartData = [];
  for (const item of data) {
    const bandwidthUsage = [];
    for (const application of applications)
      bandwidthUsage.push(item[application]);
    chartData.push({ date: item.date, values: bandwidthUsage });
  }
  while (
    chartData.length > 0 && chartData[0].values.every(item => item === null)
  ) chartData.shift();
  while (
    chartData.length > 0
    && chartData[chartData.length - 1].values.every(item => item === null)
  ) chartData.pop();
  return chartData;
};

export const parseSelections = (data, filter) => {
  return filter && filter.selections ? filter.selections : data;
};

export const parseAppClassDistr = () => { };

export const parseAppByDropQoe = data => {
  let result = data.map(d => {
    let trend = [];
    d.trend.forEach(dt => {
      const { entry_time, vqoe_score } = dt;
      trend.push({
        date: entry_time,
        value: parseFloat(formatNumberNDecimalPlaces(vqoe_score, 1))
      });
    });
    d.trend = trend;
    return d;
  });
  return result;
};
export const parseAppByRiseUsage = (data, filter) => {
  let result = data.map(d => {
    let trend = [];
    d.trend.forEach(dt => {
      const { entry_time } = dt;
      trend.push({
        date: entry_time,
        value:
          filter === "loss"
            ? formatNumberNDecimalPlaces(dt["loss_percentage"], 1)
            : filter === "latency"
              ? formatNumberNDecimalPlaces(dt[filter], 1)
              : dt[filter]
      });
    });
    d.trend = trend;
    return d;
  });
  return result;
};
export const parseQoeDistrubution = data => {
  let result = data.map(qoeMap => {
    const { application_class, good, fair, bad, unknown } = qoeMap;
    return { date: application_class, values: [good, fair, bad, unknown] };
  });
  return result;
};
